<template>
  <div class="project-dashboard-wrap">
    <div class="container-fluid">
      <div class="project-dashboard-head mt-3">
        <h3>My Projects</h3>
        <v-btn color="#1c7cff" class="common-submit-blue-btn">Start New</v-btn>
      </div>
      <div class="folder-area mt-5">
        <h4>Folder</h4>
      </div>
      <div class="folder-listing mt-5">
        <div class="single-folder">
          <img src="../assets/Images/aipostgenerator/folder-ico2.png" />
          <p>WS Campaign</p>
        </div>
        <div class="single-folder">
          <img src="../assets/Images/aipostgenerator/folder-ico2.png" />
          <p>E2M Case Studies in other Game</p>
        </div>
        <div class="single-folder">
          <img src="../assets/Images/aipostgenerator/folder-ico2.png" />
          <p>Chatbot Dec</p>
        </div>
      </div>
      <div
        class="folder-area mt-5 d-flex justify-content-between align-items-center"
      >
        <h4>Projects</h4>
        <div class="project-filter">
          <ul>
            <li class="active">All</li>
            <li>Drafts</li>
            <li>Scheduled</li>
          </ul>
        </div>
      </div>

      <div class="ai-project-listing">
        <div class="row">
          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-4 col-12">
            <div class="single-ai-project">
              <div class="project-image-hld">
                <img src="../assets/Images/aipostgenerator/project-image.png" />
              </div>
              <div class="project-shrt-details">
                <h4>Pressure Exchanger Technology</h4>
                <p class="linkedin-post">Linked In post</p>
                <p class="date-calendar">May 29, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
