// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
    getAuth, 
    createUserWithEmailAndPassword, 
    signOut, 
    fetchSignInMethodsForEmail,
    signInWithEmailAndPassword, 
    updateProfile, 
    signInWithPopup, 
    GoogleAuthProvider,
    getAdditionalUserInfo,
    OAuthProvider,
    sendPasswordResetEmail,
    sendEmailVerification
} from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFOlESFo-tW3Jd3IbIAKU3Dcn4ueo7Gi8",
  authDomain: "copilot.gecko.ai",
  projectId: "geckoai2023",
  storageBucket: "geckoai2023.appspot.com",
  messagingSenderId: "369352387216",
  appId: "1:369352387216:web:8df0599f634b06da55c615",
  measurementId: "G-YFXTHZRDQM"
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const microsoftAuthProvider = new OAuthProvider('microsoft.com');
microsoftAuthProvider.setCustomParameters({
  prompt: "login",
  tenant: "common",
})
const googleAuthClinetId = "369352387216-4eq32v0u316m7lmvpinesolalum4bc4r.apps.googleusercontent.com"

export { 
    getAuth,
    auth,
    signOut,
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    fetchSignInMethodsForEmail,
    updateProfile,
    signInWithPopup, 
    googleAuthProvider,
    googleAuthClinetId,
    getAdditionalUserInfo,
    microsoftAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    sendEmailVerification
}