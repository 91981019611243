import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueBus from 'vue-bus'
// axios
import axios from './axios.js'
import {auth, signOut} from "@/firebase/firebaseConfig";
import APIs from "@/components/APIInit";
import VueConfetti from 'vue-confetti';
import VeeValidate from 'vee-validate';

// Add a request interceptor
/*axios.interceptors.request.use(
  config => {
    auth.currentUser.getIdToken(true).then(idToken => {
      var decodedToken = jwt_decode(idToken);
      ls.setSessionUserInfo({ Session_AccessToken: idToken, idTExp: decodedToken.exp });
      config.headers['Authorization'] = 'Bearer ' + idToken;
      return config;
    })
  return config;
},
error => {
  Promise.reject(error)
});*/

//Add a response interceptor

const resInterceptor = axios.interceptors.response.use(async(response) => {  
  return response
}, async function (error) {
  //console.log(error)
  //console.log("axios error", error)
  let response = error.response
  let originalRequest = response.config;  
  //console.log(originalRequest)
  let user = auth.currentUser;
  if (response.data.status === -1 && response.data.error == "Invalid Bearer token" && !originalRequest._retry && user) {
    originalRequest._retry = true;    
    let headers = {
      "content-type": "application/json",
      "Authorization": `Bearer ${user.accessToken}`,
    }
    await axios.post(APIs.getAuthToken, {}, {headers: headers})
    .then((response) => {
      // Handle the response data
      localStorage.setItem("accessToken", response.data.gecko_token);
      //originalRequest.headers['Authorization'] = 'Bearer ' + response.data.gecko_token;
      //return axios(originalRequest);      
      router.go();
    }).catch(async(error) => {
      // Handle the error
      await signOut(auth).then(()=>{
        localStorage.clear();
        router.push('/')
      })
    });
    
    
  }else{
    return Promise.reject(error);
  }
  
});




Vue.config.productionTip = false
// export const eventBus = new Vue();
Vue.use(VueBus)
Vue.use(VueConfetti);
Vue.use(VeeValidate, {
  events: 'change|blur|xxx'
});
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
