<template>
  <div class="post-generate-wrap">
    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>

      <!-- <hr /> -->
      <!-- <h3 class="mt-0 mt-md-3 mb-0">Category</h3>
      <div class="mt-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedCategory" :items="categoryList" label="Select" outlined item-text="categoryName"
            item-value="categoryId" color="#dde1e8"></v-select>
        </div>
      </div> -->
      <ul class="add-assets-button">
        <li @click="$router.push('/upload-file')">
          <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
          Asset
        </li>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>


      <v-treeview v-if="treeAsset && filteredAssetList" selectable :items="treeAsset" item-text='assetName'
        item-key='assetId' open-all></v-treeview>
      <!-- <ul class="asset-list">
        <li v-for="(asset, index) in filteredAssetList" :key="index"
          :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-checkbox disabled class="select-asset" v-model="asset.IsChecked"></v-checkbox>
              </span>
            </template>
            <span>To edit this selection, click on the top right Back button</span>
          </v-tooltip>

          <div class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>
            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" @click="fetchAssetContent(asset.assetId, index)" />
          </div>
          <v-btn fab x-small elevation="0" class="mr-2" @click="fetchAssetContent(asset.assetId, index)"><v-icon
              size="22px" color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn fab x-small @click="editAssets(index, asset)" elevation="0"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>
        </li>
      </ul> -->

      <v-btn @click="viewMobile = false" width="100%" color="#1eb375" class="apply-asset-btn">Apply</v-btn>
    </div>
    <div class="post-generate-inner">
      <div class="file-upload-section-header" v-if="twitterPostBox && twitterPost.length > 0">

        <div class="post-switch mr-2">
          <h3 class="">Generate Content For</h3>
          <v-select :items="channelList" label="" v-model="selectedItem" @change="switchChannels(selectedItem)" outlined
            height="40">
            <template v-slot:item="{ item }">
              <!-- <font-awesome-icon :icon="item.icon" :style="{ color: item.iconColor }" /> -->
              <i v-if="item.text == 'X (Twitter)'" class="fa-brands fa-x-twitter"></i>

              <v-icon v-else :style="{ color: item.iconColor }">{{
                item.icon
              }}</v-icon>{{ item.text }}
            </template>
          </v-select>
        </div>
        <div class="">
          <label v-if="selectAssetName">Selected Assets:</label>
          <div class="asset-name-header" v-if="selectAssetName">
            <p class="shrink-asset-name" style="width: 600px !important">{{ selectAssetName }}</p>
          </div>
        </div>
      </div>

      <!-- <div :class="twitterPostBox ? 'blog-blank-screen' : ''" v-if="twitterPost.length == 0">
        <img v-if="twitterPostBox" src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
        <h3 class="mt-3">
          <span>{{ randomText }}</span>
        </h3>
      </div> -->
      <div class="linkedin-tab-post" v-if="twitterPost && twitterPost.length > 0">
        <div class="post-filter-switch">
          <div class="post-filter">
            <v-select :items="tones" label="Tone of Voice" outlined v-model="selectedTone"></v-select>
            <v-select :items="blogLength" label="Post Length (Words)" outlined v-model="selectedLength"></v-select>
            <!-- <v-select @change="onChangeRegenerateTwitter()" :items="languageList" label="Language" outlined
              v-model="selectedLanguage"></v-select> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-select outlined v-model="selectedStrategy" :items="strategyList" item-value="value" item-text="key"
                  :menu-props="{ maxHeight: '400' }" label="Select a Strategy" v-bind="attrs" v-on="on"
                  class="select-strategy-drop"></v-select>

              </template>
              <span>{{ selectedStrategy }}</span><br />
            </v-tooltip>
            <div class="d-flex justify-content-start align-items-center no-posts" style="width: 24%;">
              <p class="mb-0 mr-3">No. of Posts</p>
              <select v-model="selectedNoOfPost">
                <option v-for="(post, index) in noOfPost" :key="index">
                  {{ post }}
                </option>
              </select>
            </div>
            <!-- <v-btn color="#1eb375" rounded class="apply-btn-width" @click="onChangeRegenerateTwitter()">Apply</v-btn> -->
            <div class="loading-more-post" @click="generateMoreTwitterPost()">
              Generate
              <div class="loading-dots ml-2">
                <v-icon color="#fff" size="24px" class="bounce-right">mdi-arrow-right</v-icon>
              </div>
            </div>
          </div>
          <div class="leftpannel-mobile">
            <div class="d-flex d-md-none align-items-center">
              <v-btn @click="OpenLeft()" text small><v-icon color="#333" size="25px">mdi-menu</v-icon> Category &amp;
                assets</v-btn>
            </div>
          </div>

          <!-- <div class="post-switch">
            <v-select :items="channelList" label="Select Channel" v-model="selectedItem"
              @change="switchChannels(selectedItem)" outlined>
              <template v-slot:item="{ item }">
                <v-icon :style="{ color: item.iconColor }">{{
                  item.icon
                }}</v-icon>{{ item.text }}</template>
            </v-select>
          </div> -->
        </div>
        <div class="post-list">
          <div v-for="(post, index) in twitterPost" :key="post.Message">
            <div v-if="post.IsEdit == false" class="single-linkedin-post flex-column">
              <div class="singlepost-hld">
                <v-btn text small fab @click="changeBookmark(index)"><v-icon size="30px" :color="post.iconColor">{{
                  post.icon
                }}</v-icon></v-btn>
                <div class="linkedin-post-details">
                  <div class="linkedin-postcheck">
                    <!-- <v-checkbox></v-checkbox> -->
                    <div class="post-tag-twitter">
                      <p>Twitter</p>
                    </div>
                    <div class="post-tag-twitter strategy" v-if="post.postStrategy">
                      <p>{{ post.postStrategy }}</p>
                    </div>
                    <!-- <div class="post-tag-twitter strategy" v-if="post.postLanguage">
                      <small>{{ post.postLanguage }}</small>
                    </div> -->
                  </div>
                  <p class="mt-4" v-if="post.Message && !post.IsRegenerateSkeletonMessageLoading
                    " v-html="post.Message"></p>
                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" v-bind="attrs"
                    type="article"></v-skeleton-loader>
                </div>

                <div @click="openBigTwitterImage(post.Image)" class="linkedin-post-image d-none" v-if="post.Image">
                  <img v-if="!post.IsRegenerateSkeletonImageLoading" :src="post.Image" style="max-width: 100%" />
                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonImageLoading" v-bind="attrs"
                    type="image"></v-skeleton-loader>
                  <div class="hover-expand">
                    <v-btn fab text class="expand-btn"><v-icon color="#fff"
                        size="25px">mdi-arrow-expand-all</v-icon></v-btn>
                    <p>Click here to expand</p>
                  </div>
                </div>
              </div>
              <div class="post-schedule-button">
                <div class="">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text @click="regeneratePost(post.postId, index)" v-bind="attrs" v-on="on"><img
                          src="../assets/Images/aipostgenerator/regenerate-ico-dark.png"
                          class="mr-2" />Re-Generate</v-btn>
                    </template>
                    <span>Click to Regenerate Text</span>
                  </v-tooltip>
                  <!-- <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        @click="regeneratePostImage(index, post.Message)"
                        v-bind="attrs"
                        v-on="on"
                        ><img
                          src="../assets/Images/aipostgenerator/regenerate-ico-dark.png"
                          class="mr-2"
                        />Regenerate</v-btn
                      >
                    </template>
                    <span>Click to Regenerate Image</span>
                  </v-tooltip> -->

                  <v-btn @click="
                    OpenEditArea(post.RawMessage, post.Image);
                  post.IsEdit = true;
                  " text><img src="../assets/Images/aipostgenerator/edit_blog_ico.png" class="mr-2" />Edit</v-btn>
                </div>
                <div class="d-none">
                  <v-btn disabled dark color="#000" class="mr-2 save-draft" @click="redrictToDashboard()">Save
                    Draft</v-btn>
                  <!-- <v-btn color="#1eb375" class="common-submit-blue-btn"
                  >Schedule</v-btn
                > -->
                </div>
                <div class="post-bottom-right">
                  <div class="copy-post">
                    <p style="color: #333">Copy</p>
                    <v-btn fab text x-small color="#eee" @click="copyTextMessage(post.RawMessage, index)"><v-icon
                        color="#333" size="20px">mdi-content-copy</v-icon></v-btn>
                    <p class="copied-msg" v-if="post.IsCopied">Text Copied!</p>
                  </div>

                  <div class="rate-this-image">
                    <p style="color: #333">Rate</p>
                    <v-btn fab text x-small class="mr-2 like-this"><v-icon color="#333">mdi-thumb-up</v-icon></v-btn>
                    <v-btn fab text x-small class="mr-4"><v-icon color="#333">mdi-thumb-down</v-icon></v-btn>
                  </div>
                </div>

                <!-- <v-btn
                  fab
                  x-small
                  color="#eee"
                  @click="copyTextMessage(post.RawMessage, index)"
                  ><v-icon color="#333" size="20px"
                    >mdi-content-copy</v-icon
                  ></v-btn
                >
                <p class="copied-msg" v-if="post.IsCopied">Text Copied!</p>


                <div class="rate-this-image">
                    <p style="color:#333;">Rate this post</p>
                    <v-btn fab text x-small class="mr-2 like-this"><v-icon color="#333">mdi-thumb-up</v-icon></v-btn>
                    <v-btn fab text x-small class="mr-4"><v-icon color="#333">mdi-thumb-down</v-icon></v-btn>
                  </div> -->
              </div>
            </div>
            <!-- ---{{ post }} -->

            <div v-if="post.IsEdit" class="single-linkedin-post flex-column edit-post-area">
              <div class="singlepost-hld">
                <div class="linkedin-post-details">
                  <v-textarea name="input-7-1" class="edit-text-area" v-model="tempTwitterMsg">
                  </v-textarea>
                </div>

                <div class="linkedin-post-image ml-2 d-none" v-if="tempTwitterimage">
                  <img :src="tempTwitterimage" style="max-width: 100%" />
                  <div class="edit_image_linkedin">
                    <v-icon size="25px" color="#fff" @click="refreshTwitterImage(tempTwitterMsg)"
                      v-if="!tempImgChangeLoader">mdi-refresh</v-icon>

                    <v-progress-circular v-if="tempImgChangeLoader" indeterminate color="#fff"
                      size="25"></v-progress-circular>
                  </div>
                </div>
              </div>
              <div class="post-schedule-button justify-end">
                <div class="post-btn-wrap">
                  <v-btn dark color="#343d4c" elevation="0"
                    class="mr-2 save-draft common-submit-blue-btn discard-post-save" @click="post.IsEdit = false">Save
                    Draft</v-btn>

                  <v-btn color="#1eb375" class="common-submit-blue-btn" @click="saveEditedPost(index)">Save for
                    Review</v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- <div @click="viewRightPanel" class="right-pannel-open-button">
            <img src="../assets/Images/aipostgenerator/filter.svg" alt="" />
          </div> -->
          <div v-if="openLeftPanel" class="right-panel">
            <div class="right-panel-header">
              <h3>Advanced Options</h3>
              <v-btn fab text x-small @click="openLeftPanel = false"><v-icon color="#333"
                  size="25px">mdi-close</v-icon></v-btn>
            </div>
            <div class="right-panel-form">
              <div class="mobile-filter">
                <label>Tone of Voice</label>
                <v-select :items="items2" label="Tone of Voice" outlined></v-select>
                <label class="mt-4">Post Length</label>
                <v-select :items="items3" label="Post Length" outlined></v-select>
                <label class="mt-4">Language</label>
                <v-select :items="items4" label="Language" outlined></v-select>
                <div class="d-flex justify-content-between align-items-center no-posts mt-4" style="width: 100%;">
                  <p class="mb-0 mr-3">No. of Posts</p>
                  <select>
                    <option selected>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                  </select>
                </div>
              </div>
              <label>Aditional guideline to produce post</label>
              <textarea cols="12" row="12" value="" placeholder="Add additional Guidelines"></textarea>
              <label class="pt-5" style="border-top: 1px solid #c8c8c8;">Target Audience</label>
              <textarea cols="12" row="12" value="" placeholder="Add target audience"></textarea>
              <div class="hashtag-gen">
                <label>Generate Hashtags</label>
                <v-radio-group v-model="radios" mandatory>
                  <v-radio label="Yes" value="Yes" color="#1eb375" class="mr-2"></v-radio>
                  <v-radio label="No" value="No" color="#1eb375"></v-radio>
                </v-radio-group>
              </div>
              <label class="pt-5">Hashtags Instructions</label>
              <textarea cols="12" row="12" value="" placeholder="Add instructions"></textarea>
              <div class="apply-btn-right">
                <v-btn width="100%" color="#1eb375" class="apply-button">Apply</v-btn>
              </div>
            </div>
          </div>
          <!-- <div class="loading-more-post" @click="generateMoreTwitterPost()">
            Generate More
            <div class="loading-dots ml-2">
              <v-icon color="#fff" size="24px" class="bounce-down">mdi-arrow-down</v-icon>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <v-dialog v-model="fullTwitterImage" max-width="500px">
      <v-card class="card-image-hld">
        <div class="linkedin-full-image" style="border: none">
          <img :src="tempImg" style="max-width: 100%" />
          <div class="button-set-hld">
            <!-- <v-btn text class="button-set-single mr-3"
              ><v-icon color="#fff" size="25px" class="regen mr-2"
                >mdi-refresh</v-icon
              >
              Regenerate</v-btn
            > -->
            <v-btn text class="button-set-single" :href="tempImg" target="_blank"><v-icon color="#fff" size="25px"
                class="regen mr-2">mdi-tray-arrow-down</v-icon>
              Download</v-btn>
          </div>
        </div>

        <v-btn @click="fullTwitterImage = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileEditNameDialog" max-width="500px">
      <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <h4 class="edit_asset_name">Edit Asset Name</h4>
        <v-text-field v-model="tempFileName" id="temp-file-edit" placeholder="Enter your file name" height="45"
          outlined></v-text-field>
        <span class="validation-msg" v-if="assetErrorFlg">File Name cannot be blank</span>
        <div class="d-flex justify-content-center align-items-center mt-2">
          <v-btn elevation="0" large dense color="#dfdfdf"
            class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
            @click="cancelFileNameChange()">Cancel</v-btn>
          <v-btn large elevation="0" @click="saveFileNameChange()" color="#1eb375"
            class="common-submit-blue-btn mr-0 mb-md-0">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileViewAssetDialog" max-width="500px">
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <div class="">
            <div>
              <h4>View Asset</h4>
            </div>
            <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          </div>
          <div v-html="formattedViewContent"></div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
Vue.use(VueBus);
export default {
  props: {},
  data() {
    return {
      items: [
        {
          id: 1,
          name: 'Applications :',
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' },
          ],
        },
        {
          id: 5,
          name: 'Documents :',
          children: [
            {
              id: 6,
              name: 'vuetify :',
              children: [
                {
                  id: 7,
                  name: 'src :',
                  children: [
                    { id: 8, name: 'index : ts' },
                    { id: 9, name: 'bootstrap : ts' },
                  ],
                },
              ],
            },
            {
              id: 10,
              name: 'material2 :',
              children: [
                {
                  id: 11,
                  name: 'src :',
                  children: [
                    { id: 12, name: 'v-btn : ts' },
                    { id: 13, name: 'v-card : ts' },
                    { id: 14, name: 'v-window : ts' },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 15,
          name: 'Downloads :',
          children: [
            { id: 16, name: 'October : pdf' },
            { id: 17, name: 'November : pdf' },
            { id: 18, name: 'Tutorial : html' },
          ],
        },
        {
          id: 19,
          name: 'Videos :',
          children: [
            {
              id: 20,
              name: 'Tutorials :',
              children: [
                { id: 21, name: 'Basic layouts : mp4' },
                { id: 22, name: 'Advanced techniques : mp4' },
                { id: 23, name: 'All about app : dir' },
              ],
            },
            { id: 24, name: 'Intro : mov' },
            { id: 25, name: 'Conference introduction : avi' },
          ],
        },
      ],
      selectAssetName: '',
      treeAsset: [
        {
          id: 1,
          assetName: 'Default :',
          children: [
          ],
        }],
      fileViewAssetDialog: false,
      viewAssetContent: '',
      activeIndex: 0,
      tones: ["Formal", "Humor", "Compassion"],
      blogLength: [
        { text: "Short (50)", value: "50" },
        { text: "Medium (150)", value: "150" },
        { text: "Long (250)", value: "250" },
      ],
      selectedTone: "Formal",
      selectedLength: { text: "Short (50)", value: "50" },
      strategyList: [
        {
          key: 'Engage and educate',
          value: 'ENGAGE_AND_EDUCATE'
        },
        {
          key: 'Tell a story',
          value: 'TELL_A_STORY'
        },
        {
          key: 'Ask question',
          value: 'ASK_QUESTIONS'
        },
        {
          key: 'Share tips and tricks',
          value: 'SHARE_TIPS_AND_TRICKS'
        },
        {
          key: 'Announce contest or giveaways',
          value: 'ANNOUNCE_CONTESTS_OR_GIVEAWAYS'
        },
        {
          key: 'Share user generated content',
          value: 'SHARE_USER_GENERATED_CONTENT'
        },
        {
          key: 'Behind the scenes',
          value: 'BEHIND_THE_SCENES'
        },
        {
          key: 'Promote blog post or articles',
          value: 'PROMOTE_BLOG_POSTS_OR_ARTICLES'
        },
        {
          key: 'Inspirational quotes',
          value: 'INSPIRATIONAL_QUOTES'
        },
        {
          key: 'Post polls',
          value: 'POST_POLLS'
        },
        {
          key: 'Surveys',
          value: 'POST_SURVEYS'
        }],
      languageList: [
        "English",
        "Mandarin",
        "Spanish",
        "French",
        "Japanese",
        "Hindi",
        "Arabic",
        "German",
        "Russian",
      ],
      noOfPost: ["1", "2", "3", "5"],
      selectedNoOfPost: "2",
      selectedLanguage: "English",
      selectedStrategy: 'SHARE_USER_GENERATED_CONTENT',
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      EmailId: null,
      userAssetList: [],
      selectedItem: "X (Twitter)",
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "X (Twitter)",
          icon: "mdi-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        }
      ],
      searchTerm: "",
      productList: [],
      twitterPost: [],
      blogPost: [],
      twitterMessage: null,
      twitterimage: null,
      EditPostBox: false,
      twitterPostBox: true,
      fullTwitterImage: false,
      isTwitterLoading: false,
      tempTwitterMsg: null,
      tempTwitterimage: null,
      tempImgChangeLoader: false,
      companyName: null,
      tempImg: null,
      showMorePostLoader: false,
      contentType: null,
      contentName: null,
      selectedProduct: null,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      items2: ["Happy", "Casual", "Cheerfull", "Sad"],
      items3: ["Long", "Very Long", "Short", "Summary"],
      openLeftPanel: false,
      viewMobile: true,
      categoryList: [],
      assetList: [],
      selectedCategory: null,
    };
  },
  computed: {
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    filteredAssetList() {
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(this.searchTerm ? this.searchTerm.toLowerCase() : '')
      ).sort((a, b) => b.assetId - a.assetId);
    },
  },
  created() {
    localStorage.removeItem("twitterPostList");
    this.$bus.on("blogData", (data) => {
      console.log("blogData==in bus==", data);
      this.twitterMessage = data.message;
      this.twitterimage = data.image;
    });
    this.$bus.on("linkedinData", (data) => {
      console.log("linkedinData==in bus==", data);
    });
  },
  mounted() {

    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
      //this.generateAllTwitterInV2(localStorage.getItem("latestAssetId"));
      this.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
    }
    if (localStorage.getItem("latestAssetName")) {
      this.selectAssetName = localStorage.getItem("latestAssetName");
    }

    this.getCategories();
    this.companyName = localStorage.getItem("CompanyName");
    this.contentType = localStorage.getItem("selectedFileType");
    this.contentName = localStorage.getItem("selectedFileName");
    // this.selectedProduct = [];

    // this.selectedProduct.push(localStorage.getItem("selectedProductName"));
    this.productName = localStorage.getItem("selectedProductName");
  },
  methods: {
    updateTreeAsset(filteredAssets) {
      this.treeAsset[0].children = filteredAssets;
    },
    generateMoreTwitterPost() {
      //this.twitterPost = [];
      this.isTwitterLoading = true;
      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.twitterPost.push({
          Image: "",
          Message: '',
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: '',
          postLanguage: '',
          postId: '',
        });
      }

      this.twitterPost.reverse();
      //this.generateRandomText();

      //setInterval(this.generateRandomText, 6000);


      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(localStorage.getItem("latestAssetId")));

      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "twitter",
        countOfPosts: this.selectedNoOfPost,
        contentStrategy: tempStrategy
      };
      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
          }
        });
    },
    fetchPreviousGeneratedPostByAssetId(assetId) {
      // this.generateRandomText();

      // setInterval(this.generateRandomText, 6000);
      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.twitterPost.push({
          Image: "",
          Message: '',
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: '',
          postLanguage: '',
          postId: '',
        });
      }

      this.twitterPost.reverse();

      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));

      const requestData1 = {
        "channel": "twitter",
        assetIds: tempArr,
      };
      axios
        .post(APIs.fetchPastGeneratedPostByAssetId, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0 && response1.data.data.message && response1.data.data.message.length > 0) {
            this.twitterPost = [];
            for (const obj of response1.data.data.message) {
              //  parsedDataArray.push(obj);
              if (this.selectedLanguage == 'English') {
                this.twitterPost.push({
                  Image: "",
                  Message: this.convertHashtagsToLinks(obj.postBody),
                  RawMessage: obj.postBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsRegenerateSkeletonImageLoading: false,
                  IsCopied: false,
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postStrategy: obj.postStrategy,
                  postLanguage: obj.input.language,
                  postId: obj.postId,
                });
              } else {
                this.twitterPost.push({
                  Image: "",
                  Message: this.convertHashtagsToLinks(obj.translatedBody),
                  RawMessage: obj.translatedBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsRegenerateSkeletonImageLoading: false,
                  IsCopied: false,
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postStrategy: obj.postStrategy,
                  postLanguage: obj.input.language,
                  postId: obj.postId,
                });
              }
            }
          } else {
            this.generateAllTwitterInV2(localStorage.getItem("latestAssetId"));
          }
        });
    },

    async fetchAssetContent(assetId, index) {
      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetIds: [parseInt(assetId)]
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          this.viewAssetContent = response.data.data.assetContents;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getCategories() {
      axios
        .get(APIs.getCategoriesAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.categories && response.data.categories.length > 0) {
            this.categoryList = response.data.categories;
            this.selectedCategory = response.data.categories[0].categoryId;
          }
          this.getAssetList();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAssetList() {
      axios
        .get(APIs.getAssetAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("response==asset====", response.data);
          if (response.data && response.data.status == 0) {
            this.assetList = response.data.assets;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    redrictToChat() {
      this.$router.push("/geckochat");
    },
    viewRightPanel() {
      this.openLeftPanel = true;
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    onChangeRegenerateTwitter() {
      if (localStorage.getItem("validURL")) {
        this.twitterPost = [];
        this.generateAllTwitterV2ByUrl(localStorage.getItem("validURL"));
      }
      else if (localStorage.getItem("latestAssetId")) {
        this.twitterPost = [];
        this.generateAllTwitterInV2(localStorage.getItem("latestAssetId"));
      }

    },
    generateAllTwitterV2ByUrl(url) {
      this.isTwitterLoading = true;
      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);

      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      // var tempArr = [];
      // tempArr.push(assetId);

      const requestData1 = {
        userId: 1,
        url: url,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "twitter",
        countOfPosts: this.selectedNoOfPost,
        contentStrategy: this.selectedStrategy
      };

      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };
      axios
        .post(APIs.generateURLContentAPI, requestData1, { headers: header })
        .then((response1) => {
          console.log("response1=======", response1.data.message);

          if (response1.data.data.code == 1) {
            //console.log("response1==message=====", response1.data.data.message);
            // const jsonDataString = response1.data.data.message;

            // const jsonDataArray = JSON.parse(jsonDataString);

            // const parsedDataArray = [];

            for (const obj of response1.data.data.message) {
              //  parsedDataArray.push(obj);

              this.twitterPost.push({
                Image: "",
                Message: this.convertHashtagsToLinks(obj.translatedBody ? obj.translatedBody : obj.postBody),
                RawMessage: obj.translatedBody ? obj.translatedBody : obj.postBody,
                IsEdit: false,
                IsRegenerateSkeletonMessageLoading: false,
                IsRegenerateSkeletonImageLoading: false,
                IsCopied: false,
                icon: "mdi-bookmark-outline", // Initial icon
                iconColor: "#333",
              });

              // this.blogPost.push({
              //   TargetAudience: obj.TargetAudience,
              //   postBody: this.convertHashtagsToLinks(obj.translatedBody),
              //   postHashtags: obj.postHashtags,
              //   postStrategy: obj.postStrategy,
              //   postTone: obj.postTone,
              //   Image: "",
              //   Message: "",
              //   RawMessage: obj.translatedBody,
              //   IsEdit: false,
              //   IsRegenerateSkeletonMessageLoading: false,
              //   IsRegenerateSkeletonImageLoading: false,
              //   IsCopied: false,
              //   Title: "",
              //   Body: "",
              //   Conclusion: "",
              //   Description: "",
              //   Keywords: "",
              //   icon: "mdi-bookmark-outline", // Initial icon
              //   iconColor: "#333",
              // });
            }

            //console.log(parsedDataArray);
          }
        });
    },
    generateAllTwitterInV2(assetId) {
      this.isTwitterLoading = true;
      // this.generateRandomText();

      // setInterval(this.generateRandomText, 6000);

      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.twitterPost.push({
          Image: "",
          Message: '',
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: '',
          postLanguage: '',
          postId: '',
        });
      }

      this.twitterPost.reverse();

      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));

      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "twitter",
        countOfPosts: this.selectedNoOfPost,
        contentStrategy: tempStrategy
      };

      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };
      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(assetId);
          }
        });
    },
    selectNewProduct() {
      this.$router.push({ name: 'SummaryGenerated2', params: { id: this.selectedProduct } });
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .put(
            APIs.renameAssetAPI,
            {
              "key": {
                "assetId": this.tempAssetEditId
              },
              "data": {
                "assetName": this.tempFileName
              }
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.status == 0) {
              this.fileEditNameDialog = false;

              this.getAssetList();

              // asset.IsEdit = false;
              // this.activeIndex = index;
              // //this.generatedMessage = asset.summarygenerated;
              // this.fileName = asset.filename;
              // this.fileSize = asset.fileSize;
              // console.log("sf=========", asset);

              // localStorage.setItem("selectedFileName", asset.filename);
              // localStorage.setItem("selectedFileType", asset.filetype);
              // this.backEdit(asset);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    createProduct() {
      this.$router.push({
        path: "/assets",
        query: { param1: "pd" },
      });
    },
    openAssetPopup() {
      //open asset popup
      this.$router.push({
        path: "/assets",
        query: { param1: "a" },
      });
    },
    switchChannels(type) {
      console.log("type===", type);
      if (type == "Blog") {
        this.redrictToBlog();
      } else if (type == "LinkedIn") {
        this.redrictToLinkedIn();
      } else if (type == "Chat with Data") {
        this.redrictToChat();
      }
    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];

          if (response.data.length > 0) {
            if (this.selectedIndex) {
              this.activeIndex = this.selectedIndex;
            } else {
              this.activeIndex = response.data.length - 2;
            }
            //this.activeIndex = response.data.length - 2;
            this.userAssetList = response.data;
            this.userAssetList.sort((a, b) => b.id - a.id);

            this.userAssetList.map((element) => {
              if (localStorage.getItem("multiAssetIds")) {
                if (localStorage.getItem("multiAssetIds").includes(element.id)) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }

              } else {
                element.IsChecked = false;
              }

            });

            //this.activeIndex = 0;
            //this.generatedMessage = response.data[0].summarygenerated;
            this.fileName = response.data[0].filename;
            this.fileSize = response.data[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType", response.data[0].filetype);
          } else {
            // this.openAssetPopup();
            // this.generatedMessage = null;
          }
        })
        .catch((error) => {
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    editAssets(index, asset) {
      console.log("asset====", asset);
      console.log("asset====", asset);
      this.tempFileName = asset.assetName;
      this.tempAssetEditId = asset.assetId;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();

      // this.makeEdit = false;
      // const element = document.getElementById("MakeFocus" + index);

      // if (element) {
      //   setTimeout(() => {
      //     element.focus();
      //     asset.IsEdit = true;
      //   }, 500);
      // }
    },
    changeBookmark(index) {
      // Toggle the icon on each click
      this.twitterPost[index].icon =
        this.twitterPost[index].icon === "mdi-bookmark-outline"
          ? "mdi-bookmark"
          : "mdi-bookmark-outline";
      this.twitterPost[index].iconColor =
        this.twitterPost[index].iconColor === "#333" ? "#1eb375" : "#333"; // Toggle color
    },
    redrictToLinkedIn() {
      this.$router.push("/content-v2/linkedin");
    },
    copyTextMessage(text, index) {
      this.twitterPost[index].IsCopied = true;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard");

          setTimeout((this.twitterPost[index].IsCopied = false), 3000);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
    downloadImage(url) {
      const imageName = "myImage.jpg";

      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.download = imageName;

      // Trigger the download
      link.click();
    },
    redrictToSummary() {
      this.$router.push("/assets-v2");
    },
    async regeneratePost(postId, index) {
      console.log("index===", postId);
      this.twitterPost[index].IsRegenerateSkeletonMessageLoading = true;
      //var msg;
      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      const requestData1 = {
        "postId": postId
      };


      console.log("requestData1===", requestData1);
      axios
        .post(APIs.regeneratePostAPI, requestData1, { headers: header })
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          if (response1.data.status == 0) {
            this.twitterPost[index].Message = this.convertHashtagsToLinks(response1.data.data.postBody);
            this.twitterPost[index].RawMessage = response1.data.data.postBody;
            this.twitterPost[index].postStrategy = response1.data.data.postStrategy;
            this.twitterPost[index].postLanguage = response1.data.data.input.language;
            this.twitterPost[index].IsRegenerateSkeletonMessageLoading = false;
          }

        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    async regeneratePostImage(index, msg) {
      this.twitterPost[index].IsRegenerateSkeletonImageLoading = true;
      const formData = new FormData();

      formData.append("text", "put the image in a white background");
      formData.append("productid", localStorage.getItem("selectedProductId"));
      formData.append("emailid", localStorage.getItem("emailId"));
      try {
        const response = await axios.post(APIs.createImageAPI, formData);
        console.log("msg------", msg);

        console.log("sentt------", response.data.message);
        if (response.data.code == 1) {
          this.twitterPost[index].IsRegenerateSkeletonImageLoading = false;
          this.twitterPost[index].Image = response.data.image.error
            ? ""
            : response.data.image.data[0].url;
        } else {
          this.twitterPost[index].IsRegenerateSkeletonImageLoading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // async regenerateBtnClickPostTwitter(index) {
    //   console.log("index===", index);
    //   this.twitterPost[index].IsRegenerateSkeletonLoading = true;
    //   var msg;
    //   const header = {
    //     "Content-Type": "application/json",
    //   };

    //   const requestData1 = {
    //     text: localStorage.getItem("generatedSummary"),
    //     company: this.companyName ? this.companyName : "",
    //   };
    //   var requestData2 = {
    //     /* Request payload for API 2 */
    //   };
    //   axios
    //     .post(
    //       "https://catapultems.webspiders.com/energyapi/linkedinapiv3.php",
    //       requestData1,
    //       header
    //     )
    //     .then((response1) => {
    //       // Handle the response of the first API call
    //       console.log("Response from API 1:", response1.data);
    //       requestData2 = { text: response1.data.message };
    //       msg = response1.data.message;
    //       // Make the second API call
    //       return axios.post(
    //         "https://catapultems.webspiders.com/energyapi/imgcreate.php",
    //         requestData2,
    //         header
    //       );
    //     })
    //     .then((response2) => {
    //       // Handle the response of the second API call
    //       console.log("Response from API 2:", response2.data);
    //       if (response2.data.code == 1) {
    //         console.log("response====", response2.data);

    //         if (response2.data.code == 1 && msg) {
    //           if (msg) {
    //             this.twitterPost[index].Image = response2.data.image.error
    //               ? ""
    //               : response2.data.image.data[0].url;
    //             this.twitterPost[index].Message =
    //               this.convertHashtagsToLinks(msg);
    //             this.twitterPost[index].IsEdit = false;
    //             this.twitterPost[index].IsRegenerateSkeletonLoading = false;
    //           }
    //         } else {
    //           this.twitterPost[index].IsRegenerateSkeletonLoading = false;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       // Handle any errors that occurred during the API calls
    //       console.error("Error:", error);
    //     });
    // },
    redrictToTwitter() {
      this.$router.push("/content/twitter");
    },
    async regeneratePostTwitter(index) {
      console.log("index===", index);

      var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };

      axios
        .post(APIs.createTwitterAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          // requestData2 = { text: response1.data.message };
          msg = response1.data.message;

          if (msg) {
            this.twitterMessage = this.convertHashtagsToLinks(msg);
            localStorage.setItem("twittermessage", msg);

            this.twitterPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(msg),
              RawMessage: msg,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            if (index == 2) {
              localStorage.setItem(
                "twitterPostList",
                JSON.stringify(this.twitterPost)
              );
              this.showMorePostLoader = false;
            }
          }
          // Make the second API call
          // const formData = new FormData();

          // formData.append("text", "put the image in a white background");
          // formData.append(
          //   "productid",
          //   localStorage.getItem("selectedProductId")
          // );
          // formData.append("emailid", localStorage.getItem("emailId"));

          // return axios.post(
          //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
          //   formData
          // );
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          // if (response2.data.code == 1 && msg) {
          //   if (msg) {
          //     this.twitterMessage = this.convertHashtagsToLinks(msg);
          //     localStorage.setItem("twittermessage", msg);
          //   }

          //   this.twitterPost.push({
          //     Image: response2.data.image.error
          //       ? ""
          //       : response2.data.image.data[0].url,
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   if (index == 2) {
          //     localStorage.setItem(
          //       "twitterPostList",
          //       JSON.stringify(this.twitterPost)
          //     );
          //     this.showMorePostLoader = false;
          //   }
          // } else {
          //   console.log("failed");
          // }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    async refreshTwitterImage(msg) {
      this.tempImgChangeLoader = true;
      const formData = new FormData();

      formData.append("text", "put the image in a white background");
      formData.append("productid", localStorage.getItem("selectedProductId"));
      formData.append("emailid", localStorage.getItem("emailId"));
      if (msg) {
        try {
          const response = await axios.post(APIs.createImageAPI, formData);

          console.log("sentt------", response.data.message);
          if (response.data.code == 1) {
            this.tempImgChangeLoader = false;
            this.tempTwitterimage = response.data.image.error
              ? ""
              : response.data.image.data[0].url;
          } else {
            this.tempImgChangeLoader = false;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    saveEditedPost(index) {
      // this.twitterMessage = this.tempTwitterMsg;
      // this.twitterimage = this.tempTwitterimage;

      this.twitterPost[index].Image = this.tempTwitterimage;
      this.twitterPost[index].Message = this.convertHashtagsToLinks(
        this.tempTwitterMsg
      );
      this.twitterPost[index].RawMessage = this.tempTwitterMsg;
      this.twitterPost[index].IsEdit = false;

      // localStorage.setItem("twittermessage", this.linkedInMessage);
      // localStorage.setItem("twitterimage", this.twitterimage);
      localStorage.setItem("twitterPostList", JSON.stringify(this.twitterPost));

      this.EditPostBox = false;
      this.twitterPostBox = true;
    },
    generateRandomText() {
      const texts = [
        "AI working. Grab a coffee!",
        "Magic happening. Thanks for patience.",
        "AI magic. Content coming soon.",
        "In progress. Won't be long.",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    async generateAllTwitterPost() {
      this.isTwitterLoading = true;

      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);

      var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };

      axios
        .post(APIs.createTwitterAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          // requestData2 = { text: response1.data.message };
          msg = response1.data.message;
          this.isTwitterLoading = false;

          if (msg) {
            this.twitterMessage = this.convertHashtagsToLinks(msg);
            localStorage.setItem("twittermessage", msg);

            this.twitterPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(msg),
              RawMessage: msg,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            setTimeout(this.regeneratePostTwitter(1), 5000);
            setTimeout(this.regeneratePostTwitter(2), 8000);
          }
          // Make the second API call
          // const formData = new FormData();

          // formData.append("text", "put the image in a white background");
          // formData.append(
          //   "productid",
          //   localStorage.getItem("selectedProductId")
          // );
          // formData.append("emailid", localStorage.getItem("emailId"));

          // return axios.post(
          //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
          //   formData
          // );
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          // this.isTwitterLoading = false;

          // if (response2.data.code == 1 && msg) {
          //   if (msg) {
          //     this.twitterMessage = this.convertHashtagsToLinks(msg);
          //     localStorage.setItem("twittermessage", msg);
          //   }

          //   this.twitterPost.push({
          //     Image: response2.data.image.error
          //       ? ""
          //       : response2.data.image.data[0].url,
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   setTimeout(this.regeneratePostTwitter(1), 5000);
          //   setTimeout(this.regeneratePostTwitter(2), 8000);
          // } else {
          //   console.log("failed");
          // }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    redrictToBlog() {
      this.$router.push("/content-v2/blog");
    },
    redrictToDashboard() {
      this.$router.push("/dashboard");
    },
    convertHashtagsToLinks(paragraph) {
      const hashtagRegex = /#(\w+)/g;
      const hyperlinkTemplate = '<a href="javascript:void(0)">#$1</a>';
      console.log(
        "generated txt===",
        paragraph.replace(hashtagRegex, hyperlinkTemplate)
      );

      return paragraph.replace(hashtagRegex, hyperlinkTemplate);
    },
    OpenEditArea(message, imgUrl) {
      console.log("click===", message);
      console.log("click==2=", imgUrl);

      this.tempTwitterMsg = message;
      this.tempTwitterimage = imgUrl;
      // this.EditPostBox = true;
      // this.twitterPostBox = false;
    },
    BackOldPost() {
      this.EditPostBox = false;
      this.twitterPostBox = true;
    },
    openBigTwitterImage(url) {
      this.tempImg = url;
      this.fullTwitterImage = true;
    },
  },
  watch: {
    selectedProduct() {
      console.log("watch callled========");
      localStorage.setItem("selectedProductId", this.selectedProduct);
      localStorage.setItem("tempSelectedProductId", this.selectedProduct);
      console.log("in watch callled=====ss product===", this.selectedProduct);
      this.getAssetListByProduct(this.selectedProduct);
    },
    filteredAssetList: {
      handler(filteredAssets) {
        this.updateTreeAsset(filteredAssets);
      },
      immediate: true, // Call the handler immediately on component mount
    },
  },
};
</script>
<style></style>
