<template>
  <div class="post-generate-wrap">
    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>
      <!-- <div class="post-switch">
        <h3 class="mt-0 mt-md-4 mb-3">Select Channel</h3>
        <v-select :items="channelList" label="" v-model="selectedItem" @change="switchChannels(selectedItem)" outlined>
          <template v-slot:item="{ item }">
            <i v-if="item.text == 'X (Twitter)'" class="fa-brands fa-x-twitter"></i>
            <v-icon v-else :style="{ color: item.iconColor }">{{
              item.icon
            }}</v-icon>{{ item.text }}
          </template>
        </v-select>

      </div>
      <hr /> -->
      <!-- <h3 class="mt-0 mt-md-3 mb-0">Category</h3>

      <div class="mt-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedCategory" :items="categoryList" label="Select" outlined item-text="categoryName"
            item-value="categoryId" color="#dde1e8"></v-select>
        </div>
      </div> -->
      <ul class="add-assets-button">
        <li @click="$router.push('/upload-file')">
          <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
          Asset
        </li>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>


      <v-treeview v-model="treeViewModel" open-all selectable :items="treeAsset" item-text='assetName'
        item-key='assetId'></v-treeview>

      <!-- 
      <ul class="asset-list">
        <li v-for="(asset, index) in filteredAssetList" :key="index"
          :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-checkbox disabled class="select-asset" v-model="asset.IsChecked"></v-checkbox>
              </span>
            </template>
            <span>To edit this selection, click on the top right Back button</span>
          </v-tooltip>

          <div class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>
            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" @click="fetchAssetContent(asset.assetId, index)" />
          </div>
          <v-btn fab x-small elevation="0" class="mr-2" @click="fetchAssetContent(asset.assetId, index)"><v-icon
              size="22px" color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn fab x-small @click="editAssets(index, asset)" elevation="0"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>
        </li>
      </ul> -->

      <!-- <div
        class="channel-blog"
        @click="redrictToBlog()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/blog-icon.png" />
        <h4>Blog</h4>
      </div>
      <div class="channel-linkedin active" style="cursor: pointer">
        <img src="../assets/Images/aipostgenerator/linkedin-ico.png" />
        <h4>LinkedIn</h4>
      </div>
      <div
        class="channel-linkedin"
        @click="redrictToTwitter()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div
        class="channel-linkedin"
        style="cursor: pointer"
        @click="redrictToSummary()"
      >
        <v-icon color="#333" size="20px" class="mr-0">mdi-arrow-left</v-icon>
        <h4 class="ml-2">Back to Assets</h4>
      </div> -->
      <!-- <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/add-new-channel-ico.png" />
        <h4>Add New Channel</h4>
      </div> -->
      <v-btn @click="viewMobile = false" width="100%" color="#1eb375" class="apply-asset-btn">Apply</v-btn>
    </div>
    <div class="post-generate-inner">
      <div class="file-upload-section-header" v-if="LinkedinPostBox && linkedInPost.length > 0">
        <div class="post-switch mr-2">
          <h3 class="">Generate Content For</h3>
          <v-select :items="channelList" label="" v-model="selectedItem" @change="switchChannels(selectedItem)" outlined
            height="40">
            <template v-slot:item="{ item }">
              <!-- <font-awesome-icon :icon="item.icon" :style="{ color: item.iconColor }" /> -->
              <i v-if="item.text == 'X (Twitter)'" class="fa-brands fa-x-twitter"></i>

              <v-icon v-else :style="{ color: item.iconColor }">{{
                item.icon
              }}</v-icon>{{ item.text }}
            </template>
          </v-select>
        </div>

        <div class="">
          <label v-if="selectAssetName">Selected Assets:</label>
          <div class="asset-name-header" v-if="selectAssetName">
            <p class="shrink-asset-name" style="width: 600px !important">{{ selectAssetName }}</p>
          </div>
        </div>
        <!-- <div>
          <h4 v-if="contentType == 'paste'">
            Showing results based on your pasted content
          </h4>
          <h4 v-if="contentType == 'txt'">
            Showing results based on your uploaded document
          </h4>
        </div>
        <v-btn color="#eaebed" @click="redrictToSummary()"
          ><v-icon size="22px" color="#000" class="mr-2"
            >mdi-arrow-left-top</v-icon
          >
          Back to Preview/Transcript</v-btn
        > -->
        <!-- <div class="post-gen-input-field">
          <input type="text" name="" id="" value=""
            placeholder="Ignore GeckoAI system defaults, and specify your content requirement" class="child" />
          <v-btn rounded large disabled color="#25b478">Generate</v-btn>
        </div> -->
      </div>

      <!-- <div :class="!EditPostBox ? 'blog-blank-screen' : ''" v-if="linkedInPost.length == 0">
        <img v-if="!EditPostBox" src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
        <h3 class="mt-3">
          <span>{{ randomText }}</span>
        </h3>
   
      </div> -->
      <div class="linkedin-tab-post" v-if="LinkedinPostBox && linkedInPost && linkedInPost.length > 0">
        <div class="post-filter-switch">
          <div class="post-filter">
            <v-select :items="tones" label="Tone of Voice" outlined v-model="selectedTone"></v-select>
            <v-select :items="blogLength" label="Post Length (Words)" outlined v-model="selectedLength"></v-select>
            <!-- <v-select @change="onChangeRegenerateLinkedIn()" :items="languageList" label="Language" outlined
              v-model="selectedLanguage"></v-select> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-select outlined v-model="selectedStrategy" :items="strategyList" item-value="value" item-text="key"
                  :menu-props="{ maxHeight: '400' }" label="Select a Strategy"  v-bind="attrs" v-on="on"
                  class="select-strategy-drop"></v-select>

              </template>
              <span>{{ selectedStrategy }}</span><br />
            </v-tooltip>
            <div class="d-flex justify-content-start align-items-center no-posts" style="width: 24%;">
              <p class="mb-0 mr-3">No. of Posts</p>
              <select v-model="selectedNoOfPost">
                <option v-for="(post, index) in noOfPost" :key="index">
                  {{ post }}
                </option>
              </select>
            </div>


            <!-- <v-btn color="#1eb375" rounded class="apply-btn-width" @click="generateMoreLinkedInPost()">Generate</v-btn> -->
            <div class="loading-more-post" @click="generateMoreLinkedInPost()">
              Generate
              <div class="loading-dots ml-2">
                <v-icon color="#fff" size="24px" class="bounce-right">mdi-arrow-right</v-icon>
              </div>
            </div>

          </div>
          <div class="leftpannel-mobile">
            <div class="d-flex d-md-none align-items-center">
              <v-btn @click="OpenLeft()" text small><v-icon color="#333" size="25px">mdi-menu</v-icon> Category &amp;
                assets</v-btn>
            </div>
          </div>
        </div>
        <div v-for="(post, index) in linkedInPost" :key="post.Message">
          <div v-if="post.IsEdit == false" class="single-linkedin-post flex-column">
            <div class="singlepost-hld">
              <v-btn text small fab @click="changeBookmark(index)"><v-icon size="30px" :color="post.iconColor">{{
                post.icon
              }}</v-icon></v-btn>
              <div class="linkedin-post-details">
                <div class="linkedin-postcheck">
                  <!-- <v-checkbox></v-checkbox> -->

                  <div class="post-tag">
                    <p>LinkedIn</p>
                  </div>
                  <div class="post-tag startegy" v-if="post.postStrategy">
                    <p>{{ post.postStrategy }}</p>
                  </div>
                  <!-- <div class="post-tag startegy" v-if="post.postLanguage">
                    <small>{{ post.postLanguage }}</small>
                  </div> -->
                </div>
                <p class="mt-4" v-if="post.Message && !post.IsRegenerateSkeletonMessageLoading
                  " v-html="post.Message"></p>
                <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" v-bind="attrs"
                  type="article"></v-skeleton-loader>
              </div>

              <div @click="openBigLinkedinImage(post.Image)" class="linkedin-post-image d-none" v-if="post.Image">
                <img v-if="!post.IsRegenerateSkeletonImageLoading" :src="post.Image" style="max-width: 100%" />
                <v-skeleton-loader v-if="post.IsRegenerateSkeletonImageLoading" v-bind="attrs"
                  type="image"></v-skeleton-loader>
                <div class="hover-expand">
                  <v-btn fab text class="expand-btn"><v-icon color="#fff"
                      size="25px">mdi-arrow-expand-all</v-icon></v-btn>
                  <p>Click here to expand</p>
                </div>
              </div>
            </div>
            <div class="post-schedule-button">
              <div class="post-btn-wrap">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text @click="regeneratePost(post.postId, index)" v-bind="attrs" v-on="on"><img
                        src="../assets/Images/aipostgenerator/regenerate-ico-dark.png" class="mr-2" />Re-Generate</v-btn>
                  </template>
                  <span>Click to Regenerate Text</span>
                </v-tooltip>
                <!-- <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      @click="regeneratePostImage(index, post.Message)"
                      v-bind="attrs"
                      v-on="on"
                      ><img
                        src="../assets/Images/aipostgenerator/regenerate-ico-dark.png"
                        class="mr-2"
                      />Image</v-btn
                    >
                  </template>
                  <span>Click to Regenerate Image</span>
                </v-tooltip> -->

                <v-btn @click="
                  OpenEditArea(post.RawMessage, post.Image);
                post.IsEdit = true;
                " text><img src="../assets/Images/aipostgenerator/edit_blog_ico.png" class="mr-2" />Edit</v-btn>
              </div>
              <div class="d-none">
                <v-btn disabled dark color="#000" class="mr-2 save-draft" @click="redrictToDashboard()">Save Draft</v-btn>
                <!-- <v-btn color="#1eb375" class="common-submit-blue-btn"
                >Schedule</v-btn
              > -->
              </div>
              <div class="post-bottom-right">
                <div class="copy-post">
                  <p style="color: #333">Copy</p>
                  <v-btn fab text x-small color="#eee" @click="copyTextMessage(post.RawMessage, index)"><v-icon
                      color="#333" size="20px">mdi-content-copy</v-icon></v-btn>
                  <p class="copied-msg" v-if="post.IsCopied">Text Copied!</p>
                </div>

                <div class="rate-this-image">
                  <p style="color: #333">Rate</p>
                  <v-btn fab text x-small class="mr-2 like-this"><v-icon color="#333">mdi-thumb-up</v-icon></v-btn>
                  <v-btn fab text x-small class="mr-4"><v-icon color="#333">mdi-thumb-down</v-icon></v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- ---{{ post }} -->

          <div v-if="post.IsEdit" class="single-linkedin-post flex-column edit-post-area">
            <div class="singlepost-hld">
              <div class="linkedin-post-details">
                <v-textarea name="input-7-1" class="edit-text-area" v-model="tempLinkedInMsg">
                </v-textarea>
              </div>

              <div class="linkedin-post-image ml-2 d-none" v-if="tempLinkedInimage">
                <img :src="tempLinkedInimage" style="max-width: 100%" />
                <div class="edit_image_linkedin">
                  <v-icon size="25px" color="#fff" @click="refreshLinkedInImage(tempLinkedInMsg)"
                    v-if="!tempImgChangeLoader">mdi-refresh</v-icon>

                  <v-progress-circular v-if="tempImgChangeLoader" indeterminate color="#fff"
                    size="25"></v-progress-circular>
                </div>
              </div>
            </div>
            <div class="post-schedule-button justify-end">
              <div>
                <v-btn dark small color="#d9dadb" elevation="0"
                  class="mr-2 common-submit-blue-btn save-draft discard-post-save"
                  @click="post.IsEdit = false">Discard</v-btn>

                <v-btn color="#1eb375" class="common-submit-blue-btn" @click="saveEditedPost(index)">Save</v-btn>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="loading-more-post" @click="generateMoreLinkedInPost()">
          Generate More
          <div class="loading-dots ml-2">
            <v-icon color="#fff" size="24px" class="bounce-down">mdi-arrow-down</v-icon>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <div @click="viewRightPanel" class="right-pannel-open-button">
      <img src="../assets/Images/aipostgenerator/filter.svg" alt="" />
    </div> -->
    <div v-if="openLeftPanel" class="right-panel">
      <div class="right-panel-header">
        <h3>Advanced Options</h3>
        <v-btn fab text x-small @click="openLeftPanel = false"><v-icon color="#333" size="25px">mdi-close</v-icon></v-btn>
      </div>
      <div class="right-panel-form">
        <div class="mobile-filter">
          <label>Tone of Voice</label>
          <v-select :items="items2" label="Tone of Voice" outlined></v-select>
          <label class="mt-4">Post Length</label>
          <v-select :items="items3" label="Post Length" outlined></v-select>
          <label class="mt-4">Language</label>
          <v-select :items="items4" label="Language" outlined></v-select>
          <div class="d-flex justify-content-between align-items-center no-posts mt-4" style="width: 100%;">
            <p class="mb-0 mr-3">No. of Posts</p>
            <select>
              <option selected>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
            </select>
          </div>
        </div>
        <label>Aditional guideline to produce post</label>
        <textarea cols="12" row="12" value="" placeholder="Add additional Guidelines"></textarea>
        <label class="pt-5" style="border-top: 1px solid #c8c8c8;">Target Audience</label>
        <textarea cols="12" row="12" value="" placeholder="Add target audience"></textarea>
        <div class="hashtag-gen">
          <label>Generate Hashtags</label>
          <v-radio-group v-model="radios" mandatory>
            <v-radio label="Yes" value="Yes" color="#1eb375" class="mr-2"></v-radio>
            <v-radio label="No" value="No" color="#1eb375"></v-radio>
          </v-radio-group>
        </div>
        <label class="pt-5">Hashtags Instructions</label>
        <textarea cols="12" row="12" value="" placeholder="Add instructions"></textarea>
        <div class="apply-btn-right">
          <v-btn width="100%" color="#1eb375" class="apply-button">Apply</v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="fullLinkedinImage" max-width="500px">
      <v-card class="card-image-hld">
        <div class="linkedin-full-image" style="border: none">
          <img :src="tempImg" style="max-width: 100%" />
          <div class="button-set-hld">
            <!-- <v-btn text class="button-set-single mr-3"
              ><v-icon color="#fff" size="25px" class="regen mr-2"
                >mdi-refresh</v-icon
              >
              Regenerate</v-btn
            > -->
            <v-btn text class="button-set-single" :href="tempImg" target="_blank"><v-icon color="#fff" size="25px"
                class="regen mr-2">mdi-tray-arrow-down</v-icon>
              Download</v-btn>
            <div class="rate-this-image">
              <p>Rate this image</p>
              <v-btn fab text class="mr-2 like-this"><v-icon color="#fff">mdi-thumb-up</v-icon></v-btn>
              <v-btn fab text><v-icon color="#fff">mdi-thumb-down</v-icon></v-btn>
            </div>
          </div>
        </div>

        <v-btn @click="fullLinkedinImage = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileEditNameDialog" max-width="500px">
      <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <h4 class="edit_asset_name">Edit Asset Name</h4>
        <v-text-field v-model="tempFileName" id="temp-file-edit" placeholder="Enter your file name" height="45"
          outlined></v-text-field>

        <!-- <input
          type="text"
          v-model="tempFileName"
          id="temp-file-edit"
          placeholder="Enter your file name"
        /> -->
        <span class="validation-msg" v-if="assetErrorFlg">File Name cannot be blank</span>
        <div class="d-flex justify-content-center align-items-center mt-2">
          <v-btn elevation="0" large dense color="#dfdfdf"
            class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
            @click="cancelFileNameChange()">Cancel</v-btn>
          <v-btn large elevation="0" @click="saveFileNameChange()" color="#1eb375"
            class="common-submit-blue-btn mr-0 mb-md-0">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileViewAssetDialog" max-width="500px">
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <div class="">
            <div>
              <h4>View Asset</h4>
            </div>
            <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          </div>
          <div v-html="formattedViewContent"></div>
        </v-card>
      </template>
      <!-- <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <div>
          <v-btn @click="fileViewAssetDialog = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
        </div>
        
      <div v-html="formattedViewContent"></div>
      
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

Vue.use(VueBus);
export default {
  props: {},
  data() {
    return {
      selectAssetName: '',
      treeAsset: [
        {
          id: 1,
          assetName: 'Default :',
          children: [
          ],
        }],
      fileViewAssetDialog: false,
      viewAssetContent: '',
      activeIndex: 0,
      tones: ["Formal", "Humor", "Compassion"],
      blogLength: [
        { text: "Short (50)", value: "50" },
        { text: "Medium (100)", value: "100" },
        { text: "Long (200)", value: "200" },
      ],
      selectedTone: "Formal",
      selectedLength: { text: "Medium", value: "100" },
      strategyList: [
        {
          key: 'Engage and educate',
          value: 'ENGAGE_AND_EDUCATE'
        },
        {
          key: 'Tell a story',
          value: 'TELL_A_STORY'
        },
        {
          key: 'Ask question',
          value: 'ASK_QUESTIONS'
        },
        {
          key: 'Share tips and tricks',
          value: 'SHARE_TIPS_AND_TRICKS'
        },
        {
          key: 'Announce contest or giveaways',
          value: 'ANNOUNCE_CONTESTS_OR_GIVEAWAYS'
        },
        {
          key: 'Share user generated content',
          value: 'SHARE_USER_GENERATED_CONTENT'
        },
        {
          key: 'Behind the scenes',
          value: 'BEHIND_THE_SCENES'
        },
        {
          key: 'Promote blog post or articles',
          value: 'PROMOTE_BLOG_POSTS_OR_ARTICLES'
        },
        {
          key: 'Inspirational quotes',
          value: 'INSPIRATIONAL_QUOTES'
        },
        {
          key: 'Post polls',
          value: 'POST_POLLS'
        },
        {
          key: 'Surveys',
          value: 'POST_SURVEYS'
        }],
      languageList: [
        "English",
        "Mandarin",
        "Spanish",
        "French",
        "Japanese",
        "Hindi",
        "Arabic",
        "German",
        "Russian",
      ],
      noOfPost: ["1", "2", "3", "5"],
      selectedNoOfPost: "2",
      selectedLanguage: "English",
      selectedStrategy: 'SHARE_USER_GENERATED_CONTENT',
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      selectedItem: "LinkedIn",
      radios: null,
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "X (Twitter)",
          icon: "mdi-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        }
      ],
      searchTerm: "",
      productList: [],
      linkedInPost: [],
      linkedInMessage: null,
      linkedInimage: null,
      EditPostBox: false,
      LinkedinPostBox: true,
      fullLinkedinImage: false,
      isLinkedInLoading: false,
      tempLinkedInMsg: null,
      tempLinkedInimage: null,
      tempImgChangeLoader: false,
      companyName: null,
      tempImg: null,
      showMorePostLoader: false,
      contentType: null,
      contentName: null,
      selectedProduct: null,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      EmailId: null,
      userAssetList: [],
      openLeftPanel: false,
      viewMobile: true,
      categoryList: [],
      assetList: [],
      selectedCategory: null,
    };
  },
  computed: {
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    filteredAssetList() {
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(this.searchTerm ? this.searchTerm.toLowerCase() : '')
      ).sort((a, b) => b.assetId - a.assetId);
    },
  },
  created() {
  },
  mounted() {
    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
      this.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
      //this.generateAllLinkedInV2(localStorage.getItem("latestAssetId"));
    }
    if (localStorage.getItem("latestAssetName")) {
      this.selectAssetName = localStorage.getItem("latestAssetName");
    }
    this.getCategories();
  },
  methods: {

    updateTreeAsset(filteredAssets) {
      this.treeAsset[0].children = filteredAssets;
    },
    async regeneratePost(postId, index) {
      console.log("index===", postId);
      this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = true;
      //var msg;
      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      const requestData1 = {
        "postId": postId
      };


      console.log("requestData1===", requestData1);
      axios
        .post(APIs.regeneratePostAPI, requestData1, { headers: header })
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          if (response1.data.status == 0) {
            this.linkedInPost[index].Message = this.convertHashtagsToLinks(response1.data.data.postBody);
            this.linkedInPost[index].RawMessage = response1.data.data.postBody;
            this.linkedInPost[index].postStrategy = response1.data.data.postStrategy;
            this.linkedInPost[index].postLanguage = response1.data.data.input.language;
            this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = false;
          }

        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },

    generateMoreLinkedInPost() {
      //this.linkedInPost = [];
      this.isTwitterLoading = true;
      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.linkedInPost.push({
          Image: "",
          Message: "",
          RawMessage: "",
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: "",
          postLanguage: "",
          postId: "",
        });
      }


      this.linkedInPost.reverse();
      //this.generateRandomText();

      //setInterval(this.generateRandomText, 6000);

      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(localStorage.getItem("latestAssetId")));

      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "linkedIn",
        countOfPosts: "1",
        contentStrategy: tempStrategy
      };
      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
          }
        });
    },
    fetchPreviousGeneratedPostByAssetId(assetId) {
      // this.generateRandomText();


      // setInterval(this.generateRandomText, 6000);


      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.linkedInPost.push({
          Image: "",
          Message: "",
          RawMessage: "",
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: "",
          postLanguage: "",
          postId: "",
        });
      }

      this.linkedInPost.reverse();
      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));

      const requestData1 = {
        "channel": "linkedIn",
        assetIds: tempArr,
      };
      axios
        .post(APIs.fetchPastGeneratedPostByAssetId, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0 && response1.data.data.message && response1.data.data.message.length > 0) {
            this.linkedInPost = [];
            for (const obj of response1.data.data.message) {
              if (this.selectedLanguage == 'English') {
                this.linkedInPost.push({
                  Image: "",
                  Message: this.convertHashtagsToLinks(obj.postBody),
                  RawMessage: obj.postBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsRegenerateSkeletonImageLoading: false,
                  IsCopied: false,
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postStrategy: obj.postStrategy,
                  postLanguage: obj.input.language,
                  postId: obj.postId,
                });
              } else {
                this.linkedInPost.push({
                  Image: "",
                  Message: this.convertHashtagsToLinks(obj.translatedBody),
                  RawMessage: obj.translatedBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsRegenerateSkeletonImageLoading: false,
                  IsCopied: false,
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postStrategy: obj.postStrategy,
                  postLanguage: obj.input.language,
                  postId: obj.postId,
                });
              }
            }
          } else {
            this.generateAllLinkedInV2(localStorage.getItem("latestAssetId"));
          }
        });
    },
    async fetchAssetContent(assetId, index) {
      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetIds: [parseInt(assetId)]
        };

        const response = await axios.post(apiUrl, data, { headers });
        if (response.data.status == 0) {
          this.viewAssetContent = response.data.data.assetContents;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getCategories() {
      axios
        .get(APIs.getCategoriesAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.categories && response.data.categories.length > 0) {
            this.categoryList = response.data.categories;
            this.selectedCategory = response.data.categories[0].categoryId;
          }
          this.getAssetList();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAssetList() {
      axios
        .get(APIs.getAssetAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.status == 0) {
            this.assetList = response.data.assets;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onChangeRegenerateLinkedIn() {
      if (localStorage.getItem("latestAssetId")) {
        this.linkedInPost = [];
        this.generateAllLinkedInV2(localStorage.getItem("latestAssetId"));
      }
    },
    generateAllLinkedInV2(assetId) {
      this.isTwitterLoading = true;
      // this.generateRandomText();

      // setInterval(this.generateRandomText, 6000);

      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.linkedInPost.push({
          Image: "",
          Message: "",
          RawMessage: "",
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsCopied: false,
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postStrategy: "",
          postLanguage: "",
          postId: "",
        });
      }

      this.linkedInPost.reverse();

      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));

      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "linkedIn",
        countOfPosts: this.selectedNoOfPost,
        contentStrategy: tempStrategy
      };

      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(assetId);
          }
        });
    },
    redrictToChat() {
      this.$router.push("/geckochat");
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .put(
            APIs.renameAssetAPI,
            {
              "key": {
                "assetId": this.tempAssetEditId
              },
              "data": {
                "assetName": this.tempFileName
              }
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the response data
            if (response.data.status == 0) {
              this.fileEditNameDialog = false;
              this.getAssetList();
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    switchChannels(type) {
      if (type == "Blog") {
        this.redrictToBlog();
      } else if (type == "X (Twitter)") {
        this.redrictToTwitter();
      } else if (type == "Chat with Data") {
        this.redrictToChat();
      }
    },
    editAssets(index, asset) {
      this.tempFileName = asset.assetName;
      this.tempAssetEditId = asset.assetId;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();
    },
    openAssetPopup() {
      //open asset popup
      this.$router.push({
        path: "/assets",
        query: { param1: "a" },
      });
    },
    changeBookmark(index) {
      // Toggle the icon on each click
      this.linkedInPost[index].icon =
        this.linkedInPost[index].icon === "mdi-bookmark-outline"
          ? "mdi-bookmark"
          : "mdi-bookmark-outline";
      this.linkedInPost[index].iconColor =
        this.linkedInPost[index].iconColor === "#333" ? "#1eb375" : "#333"; // Toggle color
    },
    copyTextMessage(text, index) {
      this.linkedInPost[index].IsCopied = true;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard");

          setTimeout((this.linkedInPost[index].IsCopied = false), 3000);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
    async regeneratePostText(index) {
      this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = true;
      //var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };
      axios
        .post(APIs.createLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          //requestData2 = { text: response1.data.message };
          // msg = response1.data.message;
          // this.linkedInPost[index].Image = response2.data.image.error
          //         ? ""
          //         : response2.data.image.data[0].url;
          this.linkedInPost[index].RawMessage = response1.data.message;
          this.linkedInPost[index].Message = this.convertHashtagsToLinks(
            response1.data.message
          );
          this.linkedInPost[index].IsEdit = false;
          this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = false;
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    redrictToTwitter() {
      this.$router.push("/content-v2/twitter");
    },
    async refreshLinkedInImage(msg) {
      this.tempImgChangeLoader = true;
      if (msg) {
        try {
          const formData = new FormData();

          formData.append("text", "put the image in a white background");
          formData.append(
            "productid",
            localStorage.getItem("selectedProductId")
          );
          formData.append("emailid", localStorage.getItem("emailId"));

          const response = await axios.post(APIs.createImageAPI, formData);

          console.log("sentt------", response.data.message);
          if (response.data.code == 1) {
            this.tempImgChangeLoader = false;
            this.tempLinkedInimage = response.data.image.error
              ? ""
              : response.data.image.data[0].url;
          } else {
            this.tempImgChangeLoader = false;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    saveEditedPost(index) {
      // this.linkedInMessage = this.tempLinkedInMsg;
      // this.linkedInimage = this.tempLinkedInimage;

      this.linkedInPost[index].Image = this.tempLinkedInimage;
      this.linkedInPost[index].Message = this.convertHashtagsToLinks(
        this.tempLinkedInMsg
      );
      this.linkedInPost[index].RawMessage = this.tempLinkedInMsg;

      this.linkedInPost[index].IsEdit = false;

      // localStorage.setItem("linkedInmessage", this.linkedInMessage);
      // localStorage.setItem("linkedInimage", this.linkedInimage);
      localStorage.setItem(
        "linkedInPostList",
        JSON.stringify(this.linkedInPost)
      );

      this.EditPostBox = false;
      this.LinkedinPostBox = true;
    },
    generateRandomText() {
      const texts = [
        "AI working. Grab a coffee!",
        "Magic happening. Thanks for patience.",
        "AI magic. Content coming soon.",
        "In progress. Won't be long.",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    redrictToBlog() {
      this.$router.push("/content-v2/blog");
    },
    redrictToDashboard() {
      this.$router.push("/dashboard");
    },
    convertHashtagsToLinks(paragraph) {
      const hashtagRegex = /#(\w+)/g;
      const hyperlinkTemplate = '<a href="javascript:void(0)">#$1</a>';
      console.log(
        "generated txt===",
        paragraph.replace(hashtagRegex, hyperlinkTemplate)
      );

      return paragraph.replace(hashtagRegex, hyperlinkTemplate);
    },
    OpenEditArea(message, imgUrl) {
      console.log("click===", message);
      console.log("click==2=", imgUrl);

      this.tempLinkedInMsg = message;
      this.tempLinkedInimage = imgUrl;
      // this.EditPostBox = true;
      // this.LinkedinPostBox = false;
    },
    BackOldPost() {
      this.EditPostBox = false;
      this.LinkedinPostBox = true;
    },
    openBigLinkedinImage(url) {
      this.tempImg = url;
      this.fullLinkedinImage = true;
    },
    viewRightPanel() {
      this.openLeftPanel = true;
    }
  },
  watch: {
    filteredAssetList: {
      handler(filteredAssets) {
        this.updateTreeAsset(filteredAssets);
      },
      immediate: true, // Call the handler immediately on component mount
    },
  },
};
</script>
<style></style>
