import { render, staticRenderFns } from "./BotUI.vue?vue&type=template&id=471ade0b&lang=pug&"
import script from "./BotUI.vue?vue&type=script&lang=js&"
export * from "./BotUI.vue?vue&type=script&lang=js&"
import style0 from "../assets/scss/_app.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports