<template>
  <div class="file-upload-section-hld" style="width: 100%">
    <div class="overlay-loader" v-if="showOverAllLoader">
      <span class="loader5"></span>
      <p>Loading ...</p>
    </div>
    <!-- <div class="file-upload-section-header">
      <h4>Here's what we understood</h4>
      <div class="file-area">
        <div
          class="file-details"
          style="
            background: url(../assets/Images/aipostgenerator/file-icon.png)
              no-repeat left 10px center / auto;
          "
        >
          <h5 class="file-name">ws-brochure-2023.docx</h5>
          <p>462 kb</p>
        </div>
        <span class="close"><v-icon size="16px" color="#fff">mdi-close</v-icon></span>
      </div>
    </div> -->
    <div class="file-upload-inner">
      <div class="top-asset-part">
        <div class="left-panel-tab-hld" v-if="EmailId">
          <h3>
            <!-- <v-btn text fab x-small elevation="0" class="mr-3"
            ><v-icon size="25" color="#000">mdi-arrow-left</v-icon></v-btn
          > -->
            Category
          </h3>
          <div class="my-3 product-create">
            <div class="product-create-dropdown">
              <v-select
                v-model="selectedProduct"
                :items="productList"
                label="Select Category"
                outlined
                item-text="name"
                item-value="id"
                color="#dde1e8"
              ></v-select>
            </div>
            <v-btn
              color="#1eb375"
              class="add-product-btn"
              @click="createProduct()"
              ><v-icon color="#fff" size="25px">mdi-plus</v-icon></v-btn
            >
          </div>
          <ul class="add-assets-button">
            <li @click="openAssetPopup()">
              <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add
              New Asset
            </li>
          </ul>
          <div class="search-assets">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Assets..."
              v-model="searchTerm"
            />
          </div>

          <ul class="asset-list">
            <li
              v-for="(asset, index) in filteredAssetList"
              :key="index"
              :class="{ 'active-tab': activeIndex === index }"
              class="d-flex justify-content-between align-items-center"
            >
              <div
                class="left-asset-name"
                @click="changeActiveTab(index, asset)"
              >
                <v-icon size="22px" color="#b1bac8" class="mr-1"
                  >mdi-file-document-outline</v-icon
                >
                <input
                  type="text"
                  v-model="asset.filename"
                  :disabled="makeEdit"
                  v-if="asset.filename"
                  :id="'MakeFocus' + index"
                />
              </div>
              <v-btn fab x-small @click="editAssets(index, asset)" elevation="0"
                ><v-icon size="22px" color="#333">mdi-pencil</v-icon></v-btn
              >
            </li>
          </ul>

          <!-- <ul>
          <li class="active-tab">Product One</li>
          <li>Product Two</li>
          <li>Product Three</li>
          <li>
            <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
            Product
          </li>
        </ul> -->
        </div>

        <div class="file-inner-details">
          <!-- <div class="file-area" v-if="fileName">
            <div
              class="file-details"
              style="
                background: url(../assets/Images/aipostgenerator/file-icon.png)
                  no-repeat left 10px center / auto;
              "
            >
              <h5 class="file-name">
                {{ fileName }}

                <span class="d-inline-block ml-3" v-if="fileSize > 0"
                  >{{ fileSize }} kb</span
                >
              </h5>
            </div>
            <span class="close" @click="resetUploadedFile()"
              ><v-icon color="#fff" size="15px">mdi-close</v-icon></span
            >
          </div> -->
          <div class="right-area-header-panel">
            <div class="product-page-breadcrumb">
              <p v-if="selectedTab == 'Transcript'">
                Assets &nbsp;>&nbsp; Preview with Transcript
              </p>
              <p v-else>Assets &nbsp;>&nbsp; Preview with File Content</p>
            </div>
            <div class="preview-with-transcript">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    @click="selectedTab = 'Transcript'"
                    class="nav-link active"
                    id="profile-tab"
                    data-toggle="tab"
                    data-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <v-icon color="#333" size="25px" class="mr-2"
                      >mdi-text-box-outline</v-icon
                    >
                    Transcript
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    @click="selectedTab = 'Preview'"
                    class="nav-link"
                    id="home-tab"
                    data-toggle="tab"
                    data-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <v-icon color="#333" size="25px" class="mr-2"
                      >mdi-eye-outline</v-icon
                    >
                    Preview
                  </button>
                </li>
              </ul>
            </div>
            <div class="post-generate-option">
              <v-btn
                large
                color="#1eb375"
                @click="OpenPostOption()"
                class="cretepost-option-btn"
                >Create
                <v-icon size="15px" color="#fff"
                  >mdi-chevron-down</v-icon
                ></v-btn
              >
            </div>
          </div>
          <div class="textpreview-section">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <p v-if="generatedMessage">{{ generatedMessage }}</p>
                <p v-if="msg && !generatedMessage">{{ msg }}</p>
              </div>
              <div
                class="tab-pane fade"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
                v-if="fileContent"
                v-html="fileContent"
                style="white-space: pre-line"
              ></div>
            </div>
          </div>
          <div class="create-blogpost-dropdown">
            <!-- Example single danger button -->
            <div class="btn-group">
              <!-- <button
                type="button"
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Create
              </button> -->
              <!-- <div class="dropdown-menu">
                <div class="dropdown-item">
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      width="290px"
                      :color="generateBtnClicked ? '#808080' : '#1eb375'"
                      class="common-submit-blue-btn summary-btn mr-0 my-2"
                      @click="
                        generateBtnClicked
                          ? ''
                          : createPost('Blog', msg, generatedMessage)
                      "
                      :loading="isBlogLoading"
                      x-large
                      ><v-icon color="#ffffff" class="mr-2 btn-icon-class"
                        >mdi-pencil-box-outline</v-icon
                      >
                      Blog Post</v-btn
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#fff"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <div class="dropdown-item" href="#">
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      width="290px"
                      :loading="isLinkedInLoading"
                      :color="generateBtnClicked ? '#808080' : '#0077B5'"
                      class="common-submit-blue-btn summary-btn summary-btnsummary-btn mr-0 my-2"
                      @click="
                        generateBtnClicked
                          ? ''
                          : createPost('Linkedin', msg, generatedMessage)
                      "
                      x-large
                      ><v-icon color="#ffffff" class="mr-2 btn-icon-class"
                        >mdi-linkedin</v-icon
                      >
                      Linkedin Post</v-btn
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#fff"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <div class="dropdown-item" href="#">
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      class="common-submit-blue-btn summary-btn mr-0 my-2"
                      width="290px"
                      :loading="isTwitterLoading"
                      :color="generateBtnClicked ? '#808080' : '#1DA1F2'"
                      @click="
                        generateBtnClicked
                          ? ''
                          : createPost('Twitter', msg, generatedMessage)
                      "
                      x-large
                      ><img
                        src="../assets/Images/aipostgenerator/twitter-white.png"
                        class="mr-2 btn-icon-class"
                      />
                      Twitter Post</v-btn
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#fff"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                
              </div> -->
            </div>
          </div>

          <!-- <div class="content-txt">
            <h4 v-if="msg || generatedMessage">
              Here's what Gecko AI understood
            </h4>

            <p v-if="generatedMessage" class="mt-4">{{ generatedMessage }}</p>
            <p v-if="msg && !generatedMessage" class="mt-4">{{ msg }}</p>
          </div> -->
        </div>
      </div>
    </div>

    <v-dialog
      v-model="OpenProductcreateDialog"
      transition="dialog-bottom-transition"
      max-width="790"
    >
      <template>
        <v-card>
          <div class="create-new-project">
            <div class="new-project-card-hld">
              <div class="create-project-header">
                <div>
                  <h3>Create new Product / Service Category</h3>

                  <p class="mb-0">
                    We collect this information to improve your experience and
                    provide you with personalized content.
                  </p>
                </div>

                <v-btn @click="closePopup()" fab small elevation="0"
                  ><v-icon size="24px" color="#5a646e">mdi-close</v-icon></v-btn
                >
              </div>
              <div class="text-center">
                <!-- <v-form @submit.prevent> -->

                <div class="about_company_form">
                  <v-text-field
                    label="Product Name*"
                    v-model="productName"
                    @change="validateProductName()"
                  ></v-text-field>

                  <span class="validation-msg" v-if="productNameValidation"
                    >You must enter a product name.</span
                  >

                  <v-textarea
                    v-model="productDescription"
                    auto-grow
                    label="About the Product*"
                    rows="3"
                    row-height="20"
                    @change="validateProductDescription()"
                  ></v-textarea>

                  <span
                    class="validation-msg"
                    v-if="productDescriptionValidation"
                    >You must enter a product description.</span
                  >
                  <div class="product-image-class flex-column d-none">
                    <div
                      class="d-flex justify-content-between align-items-center flex-md-row flex-column"
                    >
                      <v-icon class="mr-2">mdi-tray-arrow-up</v-icon>
                      <h4>Upload Image*</h4>
                    </div>
                    <p>
                      Accepted file format(s): .png,.jpeg <br />
                      Max file size: 4 MB
                    </p>

                    <input
                      type="file"
                      class="product-upload"
                      @change="handleFileUpload"
                      ref="fileInput1"
                    />
                  </div>
                  <P
                    v-if="selectedFile && selectedFile.name"
                    class="file-list"
                    style="
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    <img
                      src="../assets/Images/aipostgenerator/ico-img.png"
                      height="25px"
                      width="25px"
                      class="mr-2"
                    /><span class="d-inline-block">{{
                      selectedFile.name
                    }}</span></P
                  >
                  <span class="validation-msg" v-if="productFileValidation"
                    >You must upload a product image.</span
                  >

                  <div class="company-mood d-none">
                    <h4 style="text-align: left" class="mb-3">
                      Choose the tone and style of your content
                    </h4>

                    <div class="tabs">
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Formal"
                          v-model="productMood"
                        />
                        <div class="tab-box">Formal</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Bold"
                          v-model="productMood"
                        />
                        <div class="tab-box">Bold</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Cheeky"
                          v-model="productMood"
                        />
                        <div class="tab-box">Cheeky</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Happy"
                          v-model="productMood"
                        />
                        <div class="tab-box">Happy</div>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  class="butn-tooltip-group d-flex justify-center align-items-center flex-row flex-md-row"
                >
                  <v-btn
                    large
                    dense
                    color="#dfdfdf"
                    class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
                    @click="closePopup()"
                    >Cancel
                  </v-btn>

                  <v-btn
                    large
                    elevation="0"
                    :loading="createProductLoader"
                    color="#1eb375"
                    class="common-submit-blue-btn mr-0 mb-md-0"
                    @click="saveProduct()"
                    >Save
                  </v-btn>
                  <!-- </v-form> -->
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-content-start align-items-center"
                        style="visibility: hidden"
                        ><v-icon size="25px" color="#333"
                          >mdi-refresh</v-icon
                        ></span
                      >
                    </template>
                    <span
                      >Look's like our AI is busy<br />with other request,
                      please<br />click here to retry</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      v-model="newAssetDialog"
      transition="dialog-bottom-transition"
      max-width="790"
    >
      <template>
        <v-card>
          <div class="create-new-project">
            <div class="new-project-card-hld">
              <div class="create-project-header">
                <div>
                  <h3>Upload Asset / Paste text to Ingest</h3>
                </div>

                <v-btn @click="closeAssetPopup()" fab small elevation="0"
                  ><v-icon size="24px" color="#5a646e">mdi-close</v-icon></v-btn
                >
              </div>

              <div class="open-project-tabs" v-if="!toggleDesignUploads">
                <div class="open-project-single-tabs">
                  <img
                    src="../assets/Images/aipostgenerator/scanwebsite-url.png"
                  />
                  <p>Scan a website URL</p>
                  <v-chip class="upcoming-badge" color="#1eb375"
                    >Upcoming</v-chip
                  >
                </div>
                <div
                  class="open-project-single-tabs"
                  @click="
                    toggleDesignUploads = true;
                    tabs = 1;
                  "
                >
                  <img
                    src="../assets/Images/aipostgenerator/upload_a_doc.png"
                  />
                  <p>Upload a document<br />(.txt)</p>
                </div>
                <div
                  class="open-project-single-tabs"
                  @click="
                    toggleDesignUploads = true;
                    tabs = 2;
                  "
                >
                  <img
                    src="../assets/Images/aipostgenerator/paste_content.png"
                  />
                  <p>Paste your content</p>
                </div>
              </div>
              <div class="scan_upload" v-if="toggleDesignUploads">
                <v-tabs
                  v-model="tabs"
                  centered
                  next-icon="mdi-arrow-right"
                  prev-icon="mdi-arrow-left"
                  show-arrows
                >
                  <v-tab class="mr-2" disabled> Enter URL </v-tab>

                  <v-tab class="mr-2"> Upload Document </v-tab>
                  <v-tab> Paste Your Content</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                  <v-tab-item>
                    <v-card flat>
                      <div class="scan_url-hld">
                        <input
                          type="url"
                          name=""
                          id=""
                          value=""
                          placeholder="Enter URL Here"
                        />
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn
                            disabled
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('url')"
                            >Save &amp; Continue</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <div class="upload_document_area">
                        <!-- <div class="upload_document_space">
                          <img
                            src="../assets/Images/aipostgenerator/upload_document.png"
                          />
                          <h3>
                            Drag and drop your file here! or
                            <a href="">browse</a>
                          </h3>
                          <p>Accepted file formats: .txt Max file size 5 MB</p>
                        </div> -->

                        <div
                          class="upload_document_space"
                          @dragover="handleDragOver"
                          @dragleave="handleDragLeave"
                          @drop="handleDrop"
                          @click="openFileExplorer"
                        >
                          <img
                            src="../assets/Images/aipostgenerator/upload_document.png"
                          />

                          <h3 v-if="!isDragging">
                            <a href="javascript:void(0)">Browse</a> file to
                            upload
                          </h3>
                          <p v-else>Drop files here</p>

                          <h4>
                            Accepted file format(s): .txt <br />
                            Max file size: 5 MB <br />
                            Coming Soon: .pdf .doc .docx
                          </h4>
                          <input
                            ref="fileInput"
                            type="file"
                            style="display: none"
                            @change="handleFileSelection"
                            accept=".txt"
                            id="fileInput1"
                          />
                        </div>

                        <P v-if="tempfiles && tempfiles.length > 0"
                          ><img
                            src="../assets/Images/aipostgenerator/textIcon.svg"
                            height="25px"
                            width="25px"
                            class="mr-2"
                          /><span class="d-inline-block">{{
                            tempfiles[0].name
                          }}</span></P
                        >
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn
                            v-if="tempfiles && tempfiles.length > 0"
                            :disabled="
                              tempfiles && tempfiles.length > 0 ? false : true
                            "
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('file')"
                            >Save &amp; Continue</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <div class="upload_document_area">
                        <div class="post-content-txt-area">
                          <textarea
                            cols="12"
                            rows="12"
                            placeholder="Paste your content here..."
                            v-model="contentPasted"
                          ></textarea>
                        </div>
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn
                            v-if="contentPasted"
                            :disabled="contentPasted ? false : true"
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('paste')"
                            >Save &amp; Continue</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI system is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="PostOptionOpen" persistent max-width="640px">
      <v-card class="post-create-choose-optionpop" style="padding: 20px">
        <div class="select-post-btn">
          <v-btn fab text x-small @click="PostOptionOpen = false"
            ><v-icon size="22px" color="#333">mdi-close</v-icon></v-btn
          >
          <h4>Select a channel to get started!</h4>
          <ul>
            <li @click="goToBlog(msg, generatedMessage)">
              <a href="javascript:void(0);"
                ><img
                  src="../assets/Images/aipostgenerator/blog.png"
                  alt="Blog"
              /></a>
              Blog
            </li>
            <li @click="goToLinkedIn(msg, generatedMessage)">
              <a href="javascript:void(0);"
                ><img
                  src="../assets/Images/aipostgenerator/linkedin1.png"
                  alt="LinkedIn"
              /></a>
              Linkedin
            </li>
            <li @click="goToTwitter(msg, generatedMessage)">
              <a href="javascript:void(0);"
                ><img
                  src="../assets/Images/aipostgenerator/twitter1.png"
                  alt="Twitter"
              /></a>
              Twitter
            </li>
          </ul>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileEditNameDialog" max-width="500px">
      <v-card
        class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column"
      >
        <h4 class="edit_asset_name">Edit Asset Name</h4>
        <v-text-field
          v-model="tempFileName"
          id="temp-file-edit"
          placeholder="Enter your file name"
          height="45"
          outlined
        ></v-text-field>

        <!-- <input
          type="text"
          v-model="tempFileName"
          id="temp-file-edit"
          placeholder="Enter your file name"
        /> -->
        <span class="validation-msg" v-if="assetErrorFlg"
          >File Name cannot be blank</span
        >
        <div class="d-flex justify-content-center align-items-center mt-2">
          <v-btn
            elevation="0"
            large
            dense
            color="#dfdfdf"
            class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
            @click="cancelFileNameChange()"
            >Cancel</v-btn
          >
          <v-btn
            large
            elevation="0"
            @click="saveFileNameChange()"
            color="#1eb375"
            class="common-submit-blue-btn mr-0 mb-md-0"
            >Save</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
// import {eventBus} from '@/main'
Vue.use(VueBus);
Vue.use(VueToast);
var vm = this;
export default {
  components: {},
  props: {
    msg: String,
    // msg:{
    //     required:true,
    //     type: "string"
    // }
  },
  data() {
    return {
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      selectedTab: "Transcript",
      searchTerm: "",
      tempFileContent: null,
      toggleDesignUploads: false,
      tabs: null,
      userAssetList: [],
      generatedMessage: null,
      isBlogLoading: false,
      isLinkedInLoading: false,
      fileName: null,
      fileSize: null,
      generateBtnClicked: false,
      companyName: null,
      isTwitterLoading: false,
      EmailId: null,
      activeIndex: 0,
      OpenProductcreateDialog: false,
      productMood: "Formal",
      productDescription: "",
      productName: "",
      selectedFile: null,
      productList: [],
      selectedProduct: null,
      createProductLoader: false,
      productNameValidation: false,
      productDescriptionValidation: false,
      productFileValidation: false,
      showOverAllLoader: false,
      newAssetDialog: false,
      isDragging: false,
      tempfiles: [],
      contentPasted: null,
      makeEdit: true,
      PostOptionOpen: false,
      fileContent: null,
    };
  },
  watch: {
    selectedProduct() {
      console.log("watch productList callled========", this.productList);
      console.log(
        "watch selectedProduct callled========",
        this.selectedProduct
      );

      localStorage.setItem("selectedProductId", this.selectedProduct);
      localStorage.setItem("tempSelectedProductId", this.selectedProduct);
      const product = this.productList.find(
        (item) => item.id === this.selectedProduct
      );
      this.showOverAllLoader = true;
      if (product) {
        localStorage.setItem("selectedProductName", product.name);
        this.getAssetListByProduct(this.selectedProduct);
      }
    },
  },
  computed: {
    filteredAssetList() {
      return this.userAssetList.filter((asset) =>
        asset.filename.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: this.tempAssetEditId,
              filename: this.tempFileName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              this.fileEditNameDialog = false;
              this.getAssetListByProduct(
                localStorage.getItem("selectedProductId")
              );

              // asset.IsEdit = false;
              // this.activeIndex = index;
              // //this.generatedMessage = asset.summarygenerated;
              // this.fileName = asset.filename;
              // this.fileSize = asset.fileSize;
              // console.log("sf=========", asset);

              // localStorage.setItem("selectedFileName", asset.filename);
              // localStorage.setItem("selectedFileType", asset.filetype);
              // this.backEdit(asset);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    goToBlog(data, data2) {
      localStorage.removeItem("blogPostList");
      localStorage.removeItem("blogmessage");
      localStorage.removeItem("blogtitle");
      localStorage.removeItem("blogimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content/blog");
    },
    goToLinkedIn(data, data2) {
      localStorage.removeItem("linkedInPostList");
      localStorage.removeItem("linkedInmessage");
      localStorage.removeItem("linkedInimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content/linkedin");
    },
    goToTwitter(data, data2) {
      localStorage.removeItem("twitterPostList");
      localStorage.removeItem("twittermessage");
      localStorage.removeItem("twitterimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content/twitter");
    },
    OpenPostOption() {
      this.PostOptionOpen = true;
    },

    editAssets(index, asset) {
      console.log("asset====", asset);
      console.log("asset====", asset);
      console.log("asset====", asset);
      this.tempFileName = asset.filename;
      this.tempAssetEditId = asset.id;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();

      // this.makeEdit = false;
      // const element = document.getElementById("MakeFocus" + index);
      // if (element) {
      //   setTimeout(() => {
      //     element.focus();
      //     asset.IsEdit = true;
      //   }, 500);
      // }
    },
    async postData(data, type, fileName) {
      this.showOverAllLoader = true;
      vm.showLoaderPage = true;
      vm.newAssetDialog = false;
      vm.storeDataForRetry = data;

      this.intervalId = setInterval(this.incrementCounter, 1000);
      var payload;
      if (vm.showRetryBtn) {
        vm.retryBtnLoader = true;
        payload = { text: vm.storeDataForRetry, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      } else {
        payload = { text: data, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      }

      try {
        const response = await axios.post(APIs.postSummaryAPI, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("summary reeived------", response.data);

        if (response.data.code == 1 && response.data.message) {
          if (localStorage.getItem("emailId")) {
            var payload2 = {
              emailid: localStorage.getItem("emailId"),
              filename: fileName ? fileName : response.data.filename,
              summarygenerated: response.data.message,
              fileSize: localStorage.getItem("uploadedFileSize")
                ? localStorage.getItem("uploadedFileSize")
                : 0,
              filetype: type == "paste" ? "paste" : "txt",
              productid: localStorage.getItem("selectedProductId"),
              transcript: data,
            };
            const response2 = await axios.post(APIs.createAssetAPI, payload2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response2.data.code == 1) {
              console.log("response2=====", response2);
            }
          }
          this.contentPasted = null;
          this.tempfiles = [];
          this.newAssetDialog = false;
          this.showOverAllLoader = true;

          this.getAssetListByProduct(localStorage.getItem("selectedProductId"));
        } else {
          vm.showRetryBtn = true;
        }
      } catch (error) {
        vm.showRetryBtn = true;
        console.error(error);
      }
    },
    async postDataUsingTurbo(data, type, fileName) {
      this.showOverAllLoader = true;
      vm.showLoaderPage = true;
      vm.newAssetDialog = false;
      vm.storeDataForRetry = data;

      this.intervalId = setInterval(this.incrementCounter, 1000);
      var payload;
      if (vm.showRetryBtn) {
        vm.retryBtnLoader = true;
        payload = { text: vm.storeDataForRetry, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      } else {
        payload = { text: data, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      }

      try {
        const response = await axios.post(APIs.createSummaryTurboAPI, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("summary reeived------", response.data);

        if (response.data.code == 1 && response.data.message) {
          if (localStorage.getItem("emailId")) {
            var payload2 = {
              emailid: localStorage.getItem("emailId"),
              filename: fileName ? fileName : response.data.filename,
              summarygenerated: response.data.message,
              fileSize: localStorage.getItem("uploadedFileSize")
                ? localStorage.getItem("uploadedFileSize")
                : 0,
              filetype: type == "paste" ? "paste" : "txt",
              productid: localStorage.getItem("selectedProductId"),
              transcript: data,
            };
            const response2 = await axios.post(APIs.createAssetAPI, payload2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response2.data.code == 1) {
              console.log("response2=====", response2);
            }
          }
          this.contentPasted = null;
          this.tempfiles = [];
          this.newAssetDialog = false;
          this.showOverAllLoader = true;

          this.getAssetListByProduct(localStorage.getItem("selectedProductId"));
        } else {
          vm.showRetryBtn = true;
        }
      } catch (error) {
        vm.showRetryBtn = true;
        console.error(error);
      }
    },
    handleFileSelection(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.tempfiles.push(file);
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const allowedExtensions = ["txt"];
      const fileList = event.dataTransfer.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          const file = fileList[i];
          this.tempfiles.push(file);
        }
      }
      console.log("files to upload====", this.tempfiles);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    sendForSummaryGeneration(type) {
      if (type == "file") {
        this.tempFileContent = null;
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
        console.log("file====", file);
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));

        if (file) {
          const reader = new FileReader();

          reader.onload = function (e) {
            const content = e.target.result;
            console.log("this content====", content); // Output the content of the file
            console.log("chracters count=====", content.length);
            if (content.length > 7900) {
              vm.postDataUsingTurbo(content, "file", file.name);
            } else {
              vm.postData(content, "file", file.name);
            }
          };

          reader.readAsText(file);
        }
      } else if (type == "paste") {
        console.log("chracters count======", vm.contentPasted.length);
        if (vm.contentPasted.length > 7900) {
          vm.postDataUsingTurbo(vm.contentPasted, "paste");
        } else {
          vm.postData(vm.contentPasted, "paste");
        }
      }
    },
    closeAssetPopup() {
      this.toggleDesignUploads = false;
      this.tempfiles = []
      this.contentPasted = null;
      this.newAssetDialog = false;
    },
    openAssetPopup() {
      this.newAssetDialog = true;
    },
    validateProductName() {
      if (this.productName.length > 0) {
        this.productNameValidation = false;
      } else {
        this.productNameValidation = true;
      }
    },
    validateProductDescription() {
      if (this.productDescription.length > 0) {
        this.productDescriptionValidation = false;
      } else {
        this.productDescriptionValidation = true;
      }
    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];
          this.showOverAllLoader = false;
          if (response.data.length > 0) {
            //       this.activeIndex = response.data.length - 2;

            response.data.map((element) => {
              element.IsEditEnabled = false;
              this.userAssetList.push(element);
            });

            // console.log("modifiedData list======", modifiedData);

            // this.userAssetList = modifiedData;

            this.userAssetList.sort((a, b) => b.id - a.id);
            this.activeIndex = 0;
            this.fileContent =  this.userAssetList[0].transcript;
            this.generatedMessage =  this.userAssetList[0].summarygenerated;
            this.fileName =  this.userAssetList[0].filename;
            this.fileSize =  this.userAssetList[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType",  this.userAssetList[0].filetype);
          } else {
            this.openAssetPopup();
            this.generatedMessage = null;
          }
        })
        .catch((error) => {
          this.showOverAllLoader = false;
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    getProductDetails(emailId) {
      const apiUrl = APIs.getProductsAPI;
      const email = emailId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
          },
        })
        .then((response) => {
          this.productList = [];
          console.log("product list======", response.data);

          if (response.data.length > 0) {
            if (response.data.length === 1) {
              this.selectedProduct = response.data[0].id;
            } else {
              this.selectedProduct = response.data[response.data.length - 1].id;
            }
            // response.data.map((element, index) => {
            //   console.log("product index=====", index);

            //   if (index == 0 && response.data.length == 1) {
            //     this.selectedProduct = element.id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   } else {
            //     this.selectedProduct =
            //       response.data[response.data.length - 1].id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   }
            // });

            this.productList = response.data;
            if(this.$route.params.id){
              this.selectedProduct = this.$route.params.id;
              this.getAssetListByProduct(this.$route.params.id);
            }else{
              this.getAssetListByProduct(this.selectedProduct);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle any errors that occurred during the API call
        });
    },
    closePopup() {
      this.productName = null;
      this.productDescription = null;
      this.selectedFile = null;
      this.productMood = "Formal";
      this.OpenProductcreateDialog = false;
    },
    handleFileUpload(event) {
      console.log("---", event);

      const file = event.target.files[0];
      const maxSize = 4 * 1024 * 1024;

      // Check file type
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg")
      ) {
        alert("Please select a PNG or JPEG image file.");
        return;
      } else if (file.size > maxSize) {
        alert("File size exceeds the maximum limit of 4 MB.");
        return;
      } else {
        this.productFileValidation = false;
        console.log("====file=====", file);

        this.selectedFile = file;
      }
    },
    async saveProduct() {
      if (!this.productName) {
        this.productNameValidation = true;
      } else if (!this.productDescription) {
        this.productDescriptionValidation = true;
      }
      // else if (!this.selectedFile) {
      //   this.productFileValidation = true;
      // }
      else {
        this.productNameValidation = false;
        this.productDescriptionValidation = false;
        this.productFileValidation = false;
        this.createProductLoader = true;
        console.log("file===", this.selectedFile);

        const formData = new FormData();
        formData.append("name", this.productName);
        formData.append("description", this.productDescription);
        formData.append("mood", this.productMood);
        formData.append("image", this.selectedFile);
        formData.append("emailid", localStorage.getItem("emailId"));

        try {
          const response = await axios.post(APIs.createProductV2API, formData);
          console.log("product create=====", response.data);
          if (response.data.code == 1) {
            Vue.$toast.success("Product Created Succesfully..", {
              position: "top",
            });
            this.productName = null;
            this.productDescription = null;
            this.selectedFile = null;
            this.productMood = "Formal";
            this.getProductDetails(vm.EmailId);
            this.createProductLoader = false;
            this.OpenProductcreateDialog = false;
          }

          // Handle the response data here
        } catch (error) {
          console.error(error);
          // Handle any errors that occurred during the request
        }
      }
    },
    createProduct() {
      this.OpenProductcreateDialog = true;
    },
    changeActiveTab(index, asset) {
      if (asset.IsEdit && asset.filename) {
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: asset.id,
              filename: asset.filename,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              asset.IsEdit = false;
              this.activeIndex = index;
              localStorage.setItem("selectedAssetIndex", this.activeIndex);
              this.generatedMessage = asset.summarygenerated;
              this.fileName = asset.filename;
              this.fileSize = asset.fileSize;
              console.log("sf=========", asset);

              localStorage.setItem("selectedFileName", asset.filename);
              localStorage.setItem("selectedFileType", asset.filetype);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.activeIndex = index;
        localStorage.setItem("selectedAssetIndex", this.activeIndex);
        this.generatedMessage = asset.summarygenerated;
        this.fileName = asset.filename;
        this.fileSize = asset.fileSize;
        console.log("sf=========", asset);

        localStorage.setItem("selectedFileName", asset.filename);
        localStorage.setItem("selectedFileType", asset.filetype);
      }
    },
    // getAssetListByEmail(emailId) {
    //   const apiUrl =
    //     "https://catapultems.webspiders.com/energyapi/getassets.php";
    //   const email = emailId;

    //   axios
    //     .get(apiUrl, {
    //       params: {
    //         email: email,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("asset list", response.data);
    //       this.userAssetList = response.data;
    //       this.userAssetList.sort((a, b) => b.id - a.id);
    //       this.activeIndex = response.data.length - 2;
    //       this.generatedMessage =
    //         response.data[response.data.length - 1].summarygenerated;

    //       // Handle the API response data here
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       // Handle any errors that occurred during the API call
    //     });
    // },
    resetUploadedFile() {
      console.log("call");
      vm.fileName = null;
      vm.fileSize = null;
      localStorage.setItem("cancelFile", true);
      vm.$router.push("/asset");
    },
    async createPost(type, data, data2) {
      this.generateBtnClicked = true;

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);

      if (type == "Blog") {
        this.isBlogLoading = true;

        var msgBlog;
        var blogTitle;
        const header = {
          "Content-Type": "application/json",
        };

        const requestDataBlog1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestDataBlog2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createBlogAPI, requestDataBlog1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestDataBlog2 = { text: response1.data.message };
            msgBlog = response1.data.message;
            blogTitle = response1.data.title;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));
            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isBlogLoading = false;
              this.generateBtnClicked = false;

              console.log("response====", response2.data);
              var obj2 = {
                message: msgBlog,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };

              localStorage.setItem("blogmessage", msgBlog);
              localStorage.setItem("blogtitle", blogTitle);
              localStorage.setItem(
                "blogimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );
              localStorage.removeItem("linkedInmessage");
              localStorage.removeItem("linkedInimage");
              localStorage.removeItem("twittermessage");
              localStorage.removeItem("twitterimage");
              // setTimeout(() => {
              vm.$bus.emit("blogData", obj2);

              vm.$router.push("/content/blog");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      } else if (type == "Twitter") {
        this.isTwitterLoading = true;
        var msgTwitter;
        const header = {
          "Content-Type": "application/json",
        };

        const requestDataTwitter1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestDataTwitter2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createTwitterAPI, requestDataTwitter1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestDataTwitter2 = { text: response1.data.message };
            msgTwitter = response1.data.message;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));

            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isTwitterLoading = false;
              this.generateBtnClicked = false;

              console.log("response====", response2.data);
              var obj2 = {
                message: msgTwitter,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };
              localStorage.setItem("twittermessage", msgTwitter);
              localStorage.setItem(
                "twitterimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );
              localStorage.removeItem("blogmessage");
              localStorage.removeItem("blogtitle");
              localStorage.removeItem("blogimage");
              localStorage.removeItem("linkedInmessage");
              localStorage.removeItem("linkedInimage");
              // setTimeout(() => {
              vm.$bus.emit("twitterData", obj2);

              vm.$router.push("/content/twitter");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      } else {
        this.isLinkedInLoading = true;
        var msg;
        const header = {
          "Content-Type": "application/json",
        };

        const requestData1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestData2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createLinkedInAPI, requestData1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestData2 = { text: response1.data.message };
            msg = response1.data.message;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));

            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isBlogLoading = false;
              this.isLinkedInLoading = false;
              console.log("response====", response2.data);
              var obj1 = {
                message: msg,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };
              localStorage.setItem("linkedInmessage", msg);
              localStorage.setItem(
                "linkedInimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );

              localStorage.removeItem("linkedInPostList");
              localStorage.removeItem("blogmessage");
              localStorage.removeItem("blogtitle");
              localStorage.removeItem("blogimage");

              this.$bus.emit("linkedinData", obj1);
              vm.$router.push("/content/linkedin");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      }
    },
  },

  created() {
    vm = this;
    // this.$bus.once("generate-summary", (text) => console.log("text====", text));
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      var dropdownItems = document.getElementsByClassName("dropdown-item");

      for (var i = 0; i < dropdownItems.length; i++) {
        dropdownItems[i].addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      }
    });
    console.log("refresh====", this.$route);

    vm.companyName = localStorage.getItem("CompanyName");
    vm.EmailId = localStorage.getItem("emailId");

    if (vm.EmailId) {
      this.showOverAllLoader = true;
      this.getProductDetails(vm.EmailId);
      //this.getAssetListByEmail(vm.EmailId);
    }

    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/assets",
        query: {},
      });
      location.reload();
    }
    if (this.$route.query.param1 == "p") {
      this.$router.push({
        path: "/assets",
        query: {},
      });
      location.reload();
    }
    if (this.$route.query.param1 == "a") {
      this.$router.push({
        path: "/assets",
        query: {},
      });
      vm.openAssetPopup();
      // location.reload();
    }
    if (this.$route.query.param1 == "pd") {
      this.$router.push({
        path: "/assets",
        query: {},
      });
      vm.createProduct();
      // location.reload();
    }

    // if (this.$route.params.id) {
    //   this.selectedProduct = this.$route.params.id;
    //   this.getAssetListByProduct(this.$route.params.id);
    //   // this.$router.push({
    //   //   path: "/assets",
    //   //   params: {},
    //   // });
    // }
  },
};
</script>
<style>
.product-image-class {
  width: 100%;
  height: 125px;
  border: 2px dashed #e3e3e3;
  border-radius: 6px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.product-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 99;
}

p.flie-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
