import Vue from 'vue'
import VueRouter from 'vue-router'
import CoPilotWelcome from '../views/CoPilotWelcome.vue'
import LoadingPage from '../views/LoadingPage.vue'
import SummaryGenerated from '../views/SummaryGenerated.vue'
import SummaryGenerated2 from '../views/SummaryGenerated2.vue'
import LinkedInPostGenerate2 from '../views/LinkedInPostGenerate2.vue'
import Dashboard from '../views/Dashboard.vue'
import BlogPostGenerate2 from '../views/BlogPostGenerate2.vue'
import TwitterPost2 from '../views/TwitterPost2.vue'
import Login from '../views/Login.vue'
import GeckoChat from '../views/GeckoChat.vue'
import CreateProduct from '../views/CreateProduct.vue'
import UploadFile from '../views/UploadFile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/welcome',
    name: 'CoPilotWelcome',
    component: CoPilotWelcome,
    meta: { requiresAuth: true }
  },
  {
    path: '/loading',
    name: 'LoadingPage',
    component: LoadingPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/assets/:id?',
    name: 'SummaryGenerated',
    component: SummaryGenerated,
    meta: { requiresAuth: true }
  },
  {
		path: "/create-product",
		name: "CreateProduct",
		component: CreateProduct,
    meta: { requiresAuth: true }
	},  {
		path: "/upload-file",
		name: "UploadFile",
		component: UploadFile,
    meta: { requiresAuth: true }
	},
  {
    path: '/assets-v2',
    name: 'SummaryGenerated2',
    component: SummaryGenerated2,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/content/linkedin',
  //   name: 'PostGenerate',
  //   component: LinkedInPostGenerate,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/content-v2/linkedin',
    name: 'PostGenerate2',
    component: LinkedInPostGenerate2,
    meta: { requiresAuth: true }
  },
  {
    path: '/geckochat',
    name: 'GeckoChat',
    component: GeckoChat,
    meta: { requiresAuth: true }
  },
  {
		path: "/dashboard",
		name: "ProjectDashboard",
		component: Dashboard,
    meta: { requiresAuth: true }
	},
  {
		path: "/content-v2/blog",
		name: "BlogPostGenerate2",
		component: BlogPostGenerate2,
    meta: { requiresAuth: true }
	},
  {
    path: '/content-v2/twitter',
    name: 'TwitterPost2',
    component: TwitterPost2,
    meta: { requiresAuth: true }
  }
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn"); // Implement your own login check logic
  console.log("router======",isLoggedIn);

  //Check if the route requires authentication and the user is not logged in

  if(to.name == 'Login' && isLoggedIn){
    if (localStorage.getItem("validURL") || localStorage.getItem("latestAssetId")) {
      next("/content-v2/blog");
    } else{
      next("/upload-file");
    }
  }  
  else if (to.meta.requiresAuth && (!isLoggedIn || isLoggedIn == "false")) {
    console.log("1======");
    next('/'); // Redirect the user to the login page

  } else {
    console.log("2======");

    next(); // Proceed with the navigation
  }
});



export default router
