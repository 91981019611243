<template>
  <div class="post-generate-wrap">
    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>
      <h3 class="mt-0 mt-md-4 mb-0">Category</h3>

      <div class="mt-5 mb-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedCategory" :items="categoryList" label="Select" outlined item-text="categoryName"
            item-value="categoryId" color="#dde1e8"></v-select>
        </div>
        <!-- <v-btn color="#1eb375" class="add-product-btn" @click="createProduct()"><v-icon color="#fff"
            size="25px">mdi-plus</v-icon></v-btn> -->
      </div>
      <ul class="add-assets-button">
        <li @click="$router.push('/upload-file')">
          <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
          Asset
        </li>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>
      <ul class="asset-list">
        <li v-for="(asset, index) in filteredAssetList" :key="index" :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-checkbox disabled class="select-asset" v-model="asset.IsChecked"></v-checkbox>
              </span>
            </template>
            <span>To edit this selection, click on the top right Back button</span>
          </v-tooltip>

          <div class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>

            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" />
          </div>
          <v-btn fab x-small elevation="0" class="mr-2" @click="fetchAssetContent(asset.assetId, index)"><v-icon
              size="22px" color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn fab x-small @click="editAssets(index, asset)" elevation="0"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>
        </li>
      </ul>
      <v-btn @click="viewMobile = false" width="100%" color="#1eb375" class="apply-asset-btn">Apply</v-btn>

      <!-- <div
        class="channel-blog"
        @click="redrictToBlog()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/blog-icon.png" />
        <h4>Blog</h4>
      </div>
      <div class="channel-linkedin active" style="cursor: pointer">
        <img src="../assets/Images/aipostgenerator/linkedin-ico.png" />
        <h4>LinkedIn</h4>
      </div>
      <div
        class="channel-linkedin"
        @click="redrictToTwitter()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div
        class="channel-linkedin"
        style="cursor: pointer"
        @click="redrictToSummary()"
      >
        <v-icon color="#333" size="20px" class="mr-0">mdi-arrow-left</v-icon>
        <h4 class="ml-2">Back to Assets</h4>
      </div> -->
      <!-- <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/add-new-channel-ico.png" />
        <h4>Add New Channel</h4>
      </div> -->
    </div>
    <div class="post-generate-inner">
      <div class="file-upload-section-header px-0 px-md-8">
        <!-- <div>
          <h4 v-if="contentType == 'paste'">
            Showing results based on your pasted content
          </h4>
          <h4 v-if="contentType == 'txt'">
            Showing results based on your uploaded document
          </h4>
        </div>
        <v-btn color="#eaebed" @click="redrictToSummary()"
          ><v-icon size="22px" color="#000" class="mr-2"
            >mdi-arrow-left-top</v-icon
          >
          Back to Preview/Transcript</v-btn
        > -->
        <h2>Chat with Data</h2>
        <div class="answer-tab">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" v-if="faqList && faqList.length > 0">
              <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                aria-selected="true">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                aria-controls="profile" aria-selected="false">CHAT</a>
            </li>
          </ul>
        </div>
        <div class="mobile-panel-chat">
          <div class="leftpannel-mobile mr-2 mr-md-0">
            <div class="d-flex d-md-none align-items-center">
              <v-btn @click="OpenLeft()" text small><v-icon color="#333" size="25px">mdi-menu</v-icon> Category &amp;
                assets</v-btn>
            </div>
          </div>
          <div class="post-switch">
            <v-select :items="channelList" label="Select Channel" v-model="selectedItem"
              @change="switchChannels(selectedItem)" outlined>
              <template v-slot:item="{ item }">
                <v-icon :style="{ color: item.iconColor }">{{ item.icon }}</v-icon>{{ item.text }}</template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="question-box-hld p-4">

            <div class="single-question" v-for="(item, index) in faqList" :key="index">
              <p>
                {{ item.Q }}
              </p>
              <div class="answer-sec">{{ item.A }}</div>
            </div>
          </div>
        </div>
        <div class="tab-pane active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <VueBotUI :options="botOptions" :messages="messageData" :bot-typing="botTyping" :input-disable="inputDisable"
            :is-open="true" @msg-send="msgSend" @destroy="clearMsg" @init="botStart" />
        </div>
      </div>
    </div>
    <v-dialog v-model="fileViewAssetDialog" max-width="500px">
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <div class="">
            <div>
              <h4>View Asset</h4>
            </div>
            <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          </div>
          <div v-html="formattedViewContent"></div>
        </v-card>
      </template>
      <!-- <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <div>
          <v-btn @click="fileViewAssetDialog = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
        </div>
        
      <div v-html="formattedViewContent"></div>
      
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
// import BotIcon from "../assets/icons/bot.png";
import { VueBotUI } from "../vue-bot-ui";

Vue.use(VueBus);
export default {
  props: {},
  components: {
    // BotIcon,
    VueBotUI,
  },
  data() {
    return {
      fileViewAssetDialog: false,
      viewAssetContent: '',
      categoryList: [],
      viewMobile: true,
      inputDisable: false,
      botTyping: false,
      botOptions: {
        botAvatarImg:
          "https://zoebot.ai/sites/all/themes/custom/zoeweb/images/zoe-logo.svg",
        boardContentBg: "#f4f4f4",
        msgBubbleBgBot: "#fff",
        inputPlaceholder: "Type your message here",
        inputDisableBg: "#fff",
        inputDisablePlaceholder: "Hit the buttons above to respond",
        botTitle: "Powered by Zoe 2.0",
      },
      messageData: [],
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      selectedItem: "Chat with Data",
      radios: null,
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "Twitter",
          icon: "mdi-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        },
        {
          text: "Chat with Data",
          icon: "mdi-message-outline",
          iconColor: "#00a9ef",
          isOpen: true,
        },
      ],
      searchTerm: "",
      productList1: [{ id: 1, name: "Gecko" }],
      linkedInPost: [],
      linkedInMessage: null,
      linkedInimage: null,
      EditPostBox: false,
      LinkedinPostBox: true,
      fullLinkedinImage: false,
      isLinkedInLoading: false,
      tempLinkedInMsg: null,
      tempLinkedInimage: null,
      tempImgChangeLoader: false,
      companyName: null,
      tempImg: null,
      showMorePostLoader: true,
      contentType: null,
      contentName: null,
      selectedProduct: null,
      selectedProduct1: 1,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      EmailId: null,
      userAssetList: [],
      openLeftPanel: false,
      faqList: [],
      assetList: [],
      selectedCategory: null,
      activeIndex: 0,
    };
  },
  computed: {
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    filteredAssetList() {
      var searchTxt = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(searchTxt)
      ).sort((a, b) => b.assetId - a.assetId);
    },
  },
  created() {
    localStorage.removeItem("linkedInPostList");
    this.$bus.on("blogData", (data) => {
      console.log("blogData==in bus==", data);
      this.linkedInMessage = data.message;
      this.linkedInimage = data.image;
    });
    this.$bus.on("linkedinData", (data) => {
      console.log("linkedinData==in bus==", data);
    });
  },
  mounted() {
    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/geckochat",
        query: {},
      });
      location.reload();
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
    }
    this.getFAQ();
    this.getCategories();
    // this.EmailId = localStorage.getItem("emailId");
    // if (this.EmailId) {
    //   this.getProductDetails(this.EmailId);
    // }
    // if (JSON.parse(localStorage.getItem("linkedInPostList"))) {
    //   this.linkedInPost = [];
    //   this.linkedInPost = JSON.parse(localStorage.getItem("linkedInPostList"));
    //   setTimeout(this.regeneratePostLinkedIn(1), 5000);
    //   setTimeout(this.regeneratePostLinkedIn(2), 8000);
    // } else {
    //   if (localStorage.getItem("linkedInmessage")) {
    //     this.linkedInPost = [];

    //     this.linkedInPost.push({
    //       Image: localStorage.getItem("linkedInimage")
    //         ? localStorage.getItem("linkedInimage")
    //         : "",
    //       Message: localStorage.getItem("linkedInmessage")
    //         ? this.convertHashtagsToLinks(
    //           localStorage.getItem("linkedInmessage")
    //         )
    //         : "",
    //       RawMessage: localStorage.getItem("linkedInmessage"),
    //       IsEdit: false,
    //       IsRegenerateSkeletonMessageLoading: false,
    //       IsRegenerateSkeletonImageLoading: false,
    //       IsCopied: false,
    //       icon: "mdi-bookmark-outline", // Initial icon
    //       iconColor: "#333",
    //     });
    //     localStorage.setItem(
    //       "linkedInPostList",
    //       JSON.stringify(this.linkedInPost)
    //     );
    //     setTimeout(this.regeneratePostLinkedIn(1), 5000);
    //     setTimeout(this.regeneratePostLinkedIn(2), 8000);
    //   } else {
    //     //this.generateAllLinkedInPost();

    //     if (
    //       localStorage.getItem("multiAssetIds") &&
    //       localStorage.getItem("tempSelectedProductId")
    //     ) {
    //       var assetIds = JSON.parse(localStorage.getItem("multiAssetIds"));
    //       console.log("assetIds=====", assetIds);

    //       this.generateMultiAssetLinkedInPost(
    //         assetIds,
    //         localStorage.getItem("tempSelectedProductId")
    //       );
    //     }
    //   }
    // }

    // this.companyName = localStorage.getItem("CompanyName");
    // this.contentType = localStorage.getItem("selectedFileType");
    // this.contentName = localStorage.getItem("selectedFileName");
    // // this.selectedProduct = [];
    // // this.selectedProduct.push(localStorage.getItem("selectedProductName"));
    // this.productName = localStorage.getItem("selectedProductName");
  },
  methods: {
    async fetchAssetContent(assetId, index) {
      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetIds: [parseInt(assetId)]
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          this.viewAssetContent = response.data.data.assetContents;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getCategories() {
      axios
        .get(APIs.getCategoriesAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.categories) {
            this.categoryList = response.data.categories;
            this.selectedCategory = response.data.categories[0].categoryId;
            this.getAssetList();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAssetList() {
      axios
        .get(APIs.getAssetAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("response==asset====", response.data);
          if (response.data && response.data.status == 0) {
            this.assetList = response.data.assets;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    getFAQ() {
      var self = this;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));

      var data = { assetIds: tempArr };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.faqAPI,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {
        console.log("faq====", response);

        if (response.data.status == 0) {
          self.faqList = response.data.data.FAQ;
        }
      });

      // axios
      //   .get(APIs.faqAPI)
      //   .then(function (response) {
      //     console.log("faq=====", response.data.data.FAQ);
      //     self.faqList = response.data.data.FAQ;

      //   });
    },
    botStart() {
      // Get token if you want to build a private bot
      // Request first message here

      // Fake typing for the first message
      this.botTyping = true;
      setTimeout(() => {
        this.botTyping = false;
        this.messageData.push({
          agent: "bot",
          type: "text",
          text: "Hello",
        });
      }, 1000);
    },
    msgSend(value) {
      // Push the user's message to board
      this.messageData.push({
        agent: "user",
        type: "text",
        text: value.text,
      });

      this.getResponse(value);
    },
    clearMsg() {
      this.messageData = [];
    },
    getResponse(value) {
      var self = this;
      // Loading
      this.botTyping = true;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));

      var data = { userQuery: value.text, assetIds: tempArr };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.zoeChat,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {

        console.log("chat ====", response)
        if (response.data.status == 0) {
          self.messageData.push({
            agent: "bot",
            type: "text",
            text: response.data.data.reply,
            disableInput: false,
          });
          self.botTyping = false;
        }
      });
    },
    generateMultiAssetLinkedInPost(assetIds, productId) {
      this.isTwitterLoading = true;
      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);

      var msg;
      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      const requestData1 = {
        emailid: this.EmailId,
        productid: productId,
        assetid: assetIds,
        words: "500",
        tone: "happy",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };
      axios
        .post(APIs.createMultiLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          //requestData2 = { text: response1.data.message };
          msg = response1.data.message;
          //  this.isTwitterLoading = false;

          // if (msg) {
          //   this.blogMessage = this.convertHashtagsToLinks(msg);
          //   localStorage.setItem("blogmessage", msg);

          //   const titleRegex = /@@(.*?)@@/;
          //   const titleMatch = this.blogMessage.match(titleRegex);
          //   title = titleMatch ? titleMatch[1].trim() : "";

          //   // Extracting the body
          //   const bodyRegex = /Body:(.*?)Conclusion:/s;
          //   const bodyMatch = this.blogMessage.match(bodyRegex);
          //   body = bodyMatch ? bodyMatch[1].trim() : "";

          //   // Extracting the conclusion
          //   const conclusionRegex = /Conclusion:(.*?)Meta Description:/s;
          //   const conclusionMatch = this.blogMessage.match(conclusionRegex);
          //   conclusion = conclusionMatch ? conclusionMatch[1].trim() : "";

          //   // Extracting the meta description
          //   const metaDescRegex = /Meta Description:(.*?)Meta Keywords:/s;
          //   const metaDescMatch = this.blogMessage.match(metaDescRegex);
          //   metaDescription = metaDescMatch ? metaDescMatch[1].trim() : "";

          //   // Extracting the meta keywords
          //   const metaKeywordsRegex = /Meta Keywords:(.*)/;
          //   const metaKeywordsMatch = this.blogMessage.match(metaKeywordsRegex);
          //   metaKeywords = metaKeywordsMatch ? metaKeywordsMatch[1].trim() : "";

          //   this.blogPost.push({
          //     Image: "",
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     Title: title,
          //     Body: body,
          //     Conclusion: conclusion,
          //     Description: metaDescription,
          //     Keywords: metaKeywords,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   setTimeout(
          //     this.regenerateMultiPostBlog(1, assetIds, productId),
          //     5000
          //   );
          //   setTimeout(
          //     this.regenerateMultiPostBlog(2, assetIds, productId),
          //     8000
          //   );

          // } else {
          //   console.log("failed");
          // }

          //msgTitle = response1.data.title;
          //Make the second API call
          const formData = new FormData();

          formData.append("text", "put the image in a white background");
          formData.append(
            "productid",
            localStorage.getItem("selectedProductId")
          );
          formData.append("emailid", localStorage.getItem("emailId"));

          return axios.post(APIs.createImageAPI, formData);
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          this.isLinkedInLoading = false;

          if (msg) {
            const regex = /@@(.*?)@@/gs;
            const extractedText = msg.match(regex)[0].replace(/@@/g, "").trim();

            this.linkedInMessage = this.convertHashtagsToLinks(extractedText);
            localStorage.setItem("linkedInmessage", extractedText);
            this.linkedInPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(extractedText),
              RawMessage: extractedText,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            setTimeout(
              this.regenerateMultiPostLinkedIn(1, assetIds, productId),
              5000
            );
            setTimeout(
              this.regenerateMultiPostLinkedIn(2, assetIds, productId),
              8000
            );
          } else {
            console.log("failed");
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    selectNewProduct() {
      this.$router.push({
        name: "SummaryGenerated2",
        params: { id: this.selectedProduct },
      });
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: this.tempAssetEditId,
              filename: this.tempFileName,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              this.fileEditNameDialog = false;
              this.getAssetListByProduct(
                localStorage.getItem("selectedProductId")
              );

              // asset.IsEdit = false;
              // this.activeIndex = index;
              // //this.generatedMessage = asset.summarygenerated;
              // this.fileName = asset.filename;
              // this.fileSize = asset.fileSize;
              // console.log("sf=========", asset);

              // localStorage.setItem("selectedFileName", asset.filename);
              // localStorage.setItem("selectedFileType", asset.filetype);
              // this.backEdit(asset);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    switchChannels(type) {
      console.log("type===", type);
      if (type == "Blog") {
        this.redrictToBlog();
      } else if (type == "Twitter") {
        this.redrictToTwitter();
      } else if (type == "LinkedIn") {
        this.redrictToLinkedIn();
      }
    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];

          if (response.data.length > 0) {
            if (this.selectedIndex) {
              this.activeIndex = this.selectedIndex;
            } else {
              this.activeIndex = response.data.length - 2;
            }
            // this.activeIndex = response.data.length - 2;
            this.userAssetList = response.data;
            this.userAssetList.sort((a, b) => b.id - a.id);

            this.userAssetList.map((element) => {
              if (localStorage.getItem("multiAssetIds")) {
                if (
                  localStorage.getItem("multiAssetIds").includes(element.id)
                ) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }
              } else {
                element.IsChecked = false;
              }
            });
            // this.activeIndex = 0;
            //this.generatedMessage = response.data[0].summarygenerated;
            this.fileName = response.data[0].filename;
            this.fileSize = response.data[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType", response.data[0].filetype);
          } else {
            // this.openAssetPopup();
            // this.generatedMessage = null;
          }
        })
        .catch((error) => {
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    editAssets(index, asset) {
      console.log("asset====", asset);
      this.tempFileName = asset.filename;
      this.tempAssetEditId = asset.id;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();

      // this.makeEdit = false;
      // const element = document.getElementById("MakeFocus" + index);
      // if (element) {
      //   setTimeout(() => {
      //     element.focus();
      //     asset.IsEdit = true;
      //   }, 500);
      // }
    },
    changeActiveTab(index, asset) {
      if (asset.IsEdit && asset.filename) {
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: asset.id,
              filename: asset.filename,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              asset.IsEdit = false;
              this.activeIndex = index;
              //this.generatedMessage = asset.summarygenerated;
              this.fileName = asset.filename;
              this.fileSize = asset.fileSize;
              console.log("sf=========", asset);

              localStorage.setItem("selectedFileName", asset.filename);
              localStorage.setItem("selectedFileType", asset.filetype);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.activeIndex = index;
        //this.generatedMessage = asset.summarygenerated;
        this.fileName = asset.filename;
        this.fileSize = asset.fileSize;
        console.log("sf=========", asset);
        this.linkedInPost = [];

        this.generateAllLinkedInPost(asset.summarygenerated);

        localStorage.setItem("selectedFileName", asset.filename);
        localStorage.setItem("selectedFileType", asset.filetype);
      }
    },
    openAssetPopup() {
      //open asset popup
      this.$router.push({
        path: "/assets",
        query: { param1: "a" },
      });
    },
    createProduct() {
      this.$router.push({
        path: "/assets",
        query: { param1: "pd" },
      });
    },
    getProductDetails(emailId) {
      const apiUrl = APIs.getProductsAPI;
      const email = emailId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
          },
        })
        .then((response) => {
          this.productList = [];

          console.log("product list======", response.data);

          if (response.data.length > 0) {
            if (response.data.length === 1) {
              this.selectedProduct = response.data[0].id;
            } else {
              this.selectedProduct = response.data[response.data.length - 1].id;
            }

            if (localStorage.getItem("selectedProductName")) {
              const product = response.data.find(
                (item) =>
                  item.name === localStorage.getItem("selectedProductName")
              );
              this.selectedProduct = product.id;
              this.selectedIndex = localStorage.getItem("selectedAssetIndex");
              this.getAssetListByProduct(product.id);
            } else {
              this.getAssetListByProduct(this.selectedProduct);
            }

            // response.data.map((element, index) => {
            //   console.log("product index=====", index);

            //   if (index == 0 && response.data.length == 1) {
            //     this.selectedProduct = element.id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   } else {
            //     this.selectedProduct =
            //       response.data[response.data.length - 1].id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   }
            // });

            this.productList = response.data;
            console.log("ss productList======", this.productList);
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle any errors that occurred during the API call
        });
    },
    changeBookmark(index) {
      // Toggle the icon on each click
      this.linkedInPost[index].icon =
        this.linkedInPost[index].icon === "mdi-bookmark-outline"
          ? "mdi-bookmark"
          : "mdi-bookmark-outline";
      this.linkedInPost[index].iconColor =
        this.linkedInPost[index].iconColor === "#333" ? "#1eb375" : "#333"; // Toggle color
    },
    copyTextMessage(text, index) {
      this.linkedInPost[index].IsCopied = true;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard");

          setTimeout((this.linkedInPost[index].IsCopied = false), 3000);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
    downloadImage(url) {
      const imageName = "myImage.jpg";

      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.download = imageName;

      // Trigger the download
      link.click();
    },
    redrictToSummary() {
      this.$router.push("/assets-v2");
    },
    async regeneratePostText(index) {
      console.log("index===", index);
      this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = true;
      //var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };
      axios
        .post(APIs.createLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          //requestData2 = { text: response1.data.message };
          // msg = response1.data.message;
          // this.linkedInPost[index].Image = response2.data.image.error
          //         ? ""
          //         : response2.data.image.data[0].url;
          this.linkedInPost[index].RawMessage = response1.data.message;
          this.linkedInPost[index].Message = this.convertHashtagsToLinks(
            response1.data.message
          );
          this.linkedInPost[index].IsEdit = false;
          this.linkedInPost[index].IsRegenerateSkeletonMessageLoading = false;
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    async regeneratePostImage(index, msg) {
      this.linkedInPost[index].IsRegenerateSkeletonImageLoading = true;
      const formData = new FormData();

      console.log("msg===", msg);

      formData.append("text", "put the image in a white background");
      formData.append("productid", localStorage.getItem("selectedProductId"));
      formData.append("emailid", localStorage.getItem("emailId"));
      try {
        const response = await axios.post(APIs.createImageAPI, formData);

        // const response = await axios.post(
        //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );

        console.log("sentt------", response.data.message);
        if (response.data.code == 1) {
          this.linkedInPost[index].IsRegenerateSkeletonImageLoading = false;
          this.linkedInPost[index].Image = response.data.image.error
            ? ""
            : response.data.image.data[0].url;
        } else {
          this.linkedInPost[index].IsRegenerateSkeletonImageLoading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    // async regenerateBtnClickPostLinkedIn(index) {
    //   console.log("index===", index);
    //   this.linkedInPost[index].IsRegenerateSkeletonImageLoading = true;
    //   var msg;
    //   const header = {
    //     "Content-Type": "application/json",
    //   };

    //   const requestData1 = {
    //     text: localStorage.getItem("generatedSummary"),
    //     company: this.companyName ? this.companyName : "",
    //   };
    //   var requestData2 = {
    //     /* Request payload for API 2 */
    //   };
    //   axios
    //     .post(
    //       "https://catapultems.webspiders.com/energyapi/linkedinapiv3.php",
    //       requestData1,
    //       header
    //     )
    //     .then((response1) => {
    //       // Handle the response of the first API call
    //       console.log("Response from API 1:", response1.data);
    //       requestData2 = { text: response1.data.message };
    //       msg = response1.data.message;
    //       // Make the second API call
    //       return axios.post(
    //         "https://catapultems.webspiders.com/energyapi/imgcreate.php",
    //         requestData2,
    //         header
    //       );
    //     })
    //     .then((response2) => {
    //       // Handle the response of the second API call
    //       console.log("Response from API 2:", response2.data);
    //       if (response2.data.code == 1) {
    //         console.log("response====", response2.data);

    //         if (response2.data.code == 1 && msg) {
    //           if (msg) {
    //             this.linkedInPost[index].Image = response2.data.image.error
    //               ? ""
    //               : response2.data.image.data[0].url;
    //             this.linkedInPost[index].Message =
    //               this.convertHashtagsToLinks(msg);
    //             this.linkedInPost[index].IsEdit = false;
    //             this.linkedInPost[
    //               index
    //             ].IsRegenerateSkeletonImageLoading = false;
    //           }
    //         } else {
    //           this.linkedInPost[index].IsRegenerateSkeletonImageLoading = false;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       // Handle any errors that occurred during the API calls
    //       console.error("Error:", error);
    //     });
    // },
    redrictToTwitter() {
      this.$router.push("/content-v2/twitter");
    },
    redrictToLinkedIn() {
      this.$router.push("/content-v2/linkedin");
    },
    async regenerateMultiPostLinkedIn(index, assetIds, productId) {
      console.log("index===", index);

      var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        emailid: this.EmailId,
        productid: productId,
        assetid: assetIds,
        words: "500",
        tone: "happy",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };

      axios
        .post(APIs.createMultiLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          // requestData2 = { text: response1.data.message };
          msg = response1.data.message;

          if (msg) {
            const regex = /@@(.*?)@@/gs;
            const extractedText = msg.match(regex)[0].replace(/@@/g, "").trim();

            this.linkedInMessage = this.convertHashtagsToLinks(extractedText);
            localStorage.setItem("linkedInmessage", extractedText);
            this.linkedInPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(extractedText),
              RawMessage: extractedText,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            if (index == 2) {
              localStorage.setItem(
                "linkedInPostList",
                JSON.stringify(this.linkedInPost)
              );
              this.showMorePostLoader = false;
            }
          }

          // Make the second API call
          // const formData = new FormData();

          // formData.append("text", "put the image in a white background");
          // formData.append(
          //   "productid",
          //   localStorage.getItem("selectedProductId")
          // );
          // formData.append("emailid", localStorage.getItem("emailId"));
          // return axios.post(
          //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
          //   formData
          // );
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          // if (response2.data.code == 1 && msg) {
          //   if (msg) {
          //     this.linkedInMessage = this.convertHashtagsToLinks(msg);
          //     localStorage.setItem("linkedInmessage", msg);
          //   }

          //   this.linkedInPost.push({
          //     Image: response2.data.image.error
          //       ? ""
          //       : response2.data.image.data[0].url,
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   if (index == 2) {
          //     localStorage.setItem(
          //       "linkedInPostList",
          //       JSON.stringify(this.linkedInPost)
          //     );
          //     this.showMorePostLoader = false;
          //   }
          // } else {
          //   console.log("failed");
          // }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    async regeneratePostLinkedIn(index) {
      console.log("index===", index);

      var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };

      axios
        .post(APIs.createLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          // requestData2 = { text: response1.data.message };
          msg = response1.data.message;

          if (msg) {
            const regex = /@@(.*?)@@/gs;
            const extractedText = msg.match(regex)[0].replace(/@@/g, "").trim();

            this.linkedInMessage = this.convertHashtagsToLinks(extractedText);
            localStorage.setItem("linkedInmessage", extractedText);
            this.linkedInPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(extractedText),
              RawMessage: extractedText,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            if (index == 2) {
              localStorage.setItem(
                "linkedInPostList",
                JSON.stringify(this.linkedInPost)
              );
              this.showMorePostLoader = false;
            }
          }

          // Make the second API call
          // const formData = new FormData();

          // formData.append("text", "put the image in a white background");
          // formData.append(
          //   "productid",
          //   localStorage.getItem("selectedProductId")
          // );
          // formData.append("emailid", localStorage.getItem("emailId"));
          // return axios.post(
          //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
          //   formData
          // );
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          // if (response2.data.code == 1 && msg) {
          //   if (msg) {
          //     this.linkedInMessage = this.convertHashtagsToLinks(msg);
          //     localStorage.setItem("linkedInmessage", msg);
          //   }

          //   this.linkedInPost.push({
          //     Image: response2.data.image.error
          //       ? ""
          //       : response2.data.image.data[0].url,
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   if (index == 2) {
          //     localStorage.setItem(
          //       "linkedInPostList",
          //       JSON.stringify(this.linkedInPost)
          //     );
          //     this.showMorePostLoader = false;
          //   }
          // } else {
          //   console.log("failed");
          // }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    async refreshLinkedInImage(msg) {
      this.tempImgChangeLoader = true;
      if (msg) {
        try {
          const formData = new FormData();

          formData.append("text", "put the image in a white background");
          formData.append(
            "productid",
            localStorage.getItem("selectedProductId")
          );
          formData.append("emailid", localStorage.getItem("emailId"));

          const response = await axios.post(APIs.createImageAPI, formData);

          console.log("sentt------", response.data.message);
          if (response.data.code == 1) {
            this.tempImgChangeLoader = false;
            this.tempLinkedInimage = response.data.image.error
              ? ""
              : response.data.image.data[0].url;
          } else {
            this.tempImgChangeLoader = false;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    saveEditedPost(index) {
      // this.linkedInMessage = this.tempLinkedInMsg;
      // this.linkedInimage = this.tempLinkedInimage;

      this.linkedInPost[index].Image = this.tempLinkedInimage;
      this.linkedInPost[index].Message = this.convertHashtagsToLinks(
        this.tempLinkedInMsg
      );
      this.linkedInPost[index].RawMessage = this.tempLinkedInMsg;

      this.linkedInPost[index].IsEdit = false;

      // localStorage.setItem("linkedInmessage", this.linkedInMessage);
      // localStorage.setItem("linkedInimage", this.linkedInimage);
      localStorage.setItem(
        "linkedInPostList",
        JSON.stringify(this.linkedInPost)
      );

      this.EditPostBox = false;
      this.LinkedinPostBox = true;
    },
    generateRandomText() {
      const texts = [
        "AI working. Grab a coffee!",
        "Magic happening. Thanks for patience.",
        "AI magic. Content coming soon.",
        "In progress. Won't be long.",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    async generateAllLinkedInPost() {
      this.isLinkedInLoading = true;
      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);

      var msg;
      const header = {
        "Content-Type": "application/json",
      };

      const requestData1 = {
        text: localStorage.getItem("generatedSummary"),
        company: this.companyName ? this.companyName : "",
      };
      // var requestData2 = {
      //   /* Request payload for API 2 */
      // };

      axios
        .post(APIs.createLinkedInAPI, requestData1, header)
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          //requestData2 = { text: response1.data.message };
          msg = response1.data.message;
          this.isLinkedInLoading = false;

          if (msg) {
            // const regex = /@@(.*?)@@/gs;
            // const extractedText = msg.match(regex)[0].replace(/@@/g, "").trim();

            this.linkedInMessage = this.convertHashtagsToLinks(msg);
            localStorage.setItem("linkedInmessage", msg);
            this.linkedInPost.push({
              Image: "",
              Message: this.convertHashtagsToLinks(msg),
              RawMessage: msg,
              IsEdit: false,
              IsRegenerateSkeletonMessageLoading: false,
              IsRegenerateSkeletonImageLoading: false,
              IsCopied: false,
              icon: "mdi-bookmark-outline", // Initial icon
              iconColor: "#333",
            });
            setTimeout(this.regeneratePostLinkedIn(1), 5000);
            setTimeout(this.regeneratePostLinkedIn(2), 8000);
          }

          // const formData = new FormData();

          // formData.append("text", "put the image in a white background");
          // formData.append(
          //   "productid",
          //   localStorage.getItem("selectedProductId")
          // );
          // formData.append("emailid", localStorage.getItem("emailId"));
          // // Make the second API call
          // return axios.post(
          //   "https://catapultems.webspiders.com/energyapi/imgcreatev3.php",
          //   formData
          // );
        })
        .then((response2) => {
          // Handle the response of the second API call
          console.log("Response from API 2:", response2.data);
          // this.isLinkedInLoading = false;

          // if (response2.data.code == 1 && msg) {
          //   if (msg) {
          //     this.linkedInMessage = this.convertHashtagsToLinks(msg);
          //     localStorage.setItem("linkedInmessage", msg);
          //   }

          //   this.linkedInPost.push({
          //     Image: response2.data.image.error
          //       ? ""
          //       : response2.data.image.data[0].url,
          //     Message: this.convertHashtagsToLinks(msg),
          //     RawMessage: msg,
          //     IsEdit: false,
          //     IsRegenerateSkeletonMessageLoading: false,
          //     IsRegenerateSkeletonImageLoading: false,
          //     IsCopied: false,
          //     icon: "mdi-bookmark-outline", // Initial icon
          //     iconColor: "#333",
          //   });
          //   setTimeout(this.regeneratePostLinkedIn(1), 5000);
          //   setTimeout(this.regeneratePostLinkedIn(2), 8000);
          // } else {
          //   console.log("failed");
          // }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    redrictToBlog() {
      this.$router.push("/content-v2/blog");
    },
    redrictToDashboard() {
      this.$router.push("/dashboard");
    },
    convertHashtagsToLinks(paragraph) {
      const hashtagRegex = /#(\w+)/g;
      const hyperlinkTemplate = '<a href="javascript:void(0)">#$1</a>';
      console.log(
        "generated txt===",
        paragraph.replace(hashtagRegex, hyperlinkTemplate)
      );

      return paragraph.replace(hashtagRegex, hyperlinkTemplate);
    },
    OpenEditArea(message, imgUrl) {
      console.log("click===", message);
      console.log("click==2=", imgUrl);

      this.tempLinkedInMsg = message;
      this.tempLinkedInimage = imgUrl;
      // this.EditPostBox = true;
      // this.LinkedinPostBox = false;
    },
    BackOldPost() {
      this.EditPostBox = false;
      this.LinkedinPostBox = true;
    },
    openBigLinkedinImage(url) {
      this.tempImg = url;
      this.fullLinkedinImage = true;
    },
    viewRightPanel() {
      this.openLeftPanel = true;
    },
  },
  watch: {
    selectedProduct() {
      console.log("watch callled========");

      localStorage.setItem("selectedProductId", this.selectedProduct);
      localStorage.setItem("tempSelectedProductId", this.selectedProduct);
      // const product = this.productList.find(
      //   (item) => item.id === this.selectedProduct
      // );
      // if (product) {
      //   localStorage.setItem("selectedProductName", product.name);
      // }
      console.log("in watch callled=====ss product===", this.selectedProduct);

      this.getAssetListByProduct(this.selectedProduct);
    },
  },
};
</script>
<style>
.post-switch .v-text-field__details {
  display: none;
}

.post-switch .v-input__slot {
  margin: 0;
}
</style>
