//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//Gecko API
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


export default {
    
    APIs: {
        postSummaryAPI: "https://geckoai.webspiders.com/energyapi/summeryapiv3.php",
        createAssetAPI:"https://geckoai.webspiders.com/energyapi/createassetv2.php",
        createProductAPI:"https://geckoai.webspiders.com/energyapi/createproduct.php",
        createProductV2API:"https://geckoai.webspiders.com/energyapi/createproductv2.php",
        editFileNameAPI:"https://geckoai.webspiders.com/energyapi/filenameedit.php",
        // getAssetAPI:"https://geckoai.webspiders.com/energyapi/getassetsv2.php",
        getProductsAPI:"https://geckoai.webspiders.com/energyapi/getproducts.php",
        createBlogAPI:"https://geckoai.webspiders.com/energyapi/blogapiv5.php",
        createImageAPI:"https://geckoai.webspiders.com/energyapi/imgcreatev3.php",
        createLinkedInAPI:"https://geckoai.webspiders.com/energyapi/linkedinapiv3.php",
        createSummaryTurboAPI:"https://geckoai.webspiders.com/energyapi/summaryapiturbov2.php",
        createTwitterAPI:"https://geckoai.webspiders.com/energyapi/twiterapiv3.php",
        createMultiBlogAPI:"https://geckoai.webspiders.com/energyapi/multiblogapi.php",
        createMultiLinkedInAPI:"https://geckoai.webspiders.com/energyapi/multilinkedinapi.php",
        createMultiTwitterAPI:"https://geckoai.webspiders.com/energyapi/multitwiterapi.php",
        getTextAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-plain-text",
        generateAllPost: "https://geckoai.webspiders.com/energyapi/genallpostsv2.php",
        getAssetByUserId:"https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/get-assets",
        uploadAssetAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/upload-asset",
        generateContentAPI:"https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-posts-from-assets",
        generateURLContentAPI:"https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-posts-from-url",
        zoeChat: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-answer-from-assets",
        uploadChatAsset: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/upload-doc-for-qna",
        faqAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-faq-from-assets",
        getAuthToken: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/get-auth-token",
        getAssetIdByUrl: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/create-url-asset",
        getAssetIdByFileUpload: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/create-file-asset",
        getAssetIdByPastedContent: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/create-text-asset",
        getCategoriesAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/get-categories",
        getAssetAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/get-assets",
        viewAssetAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-asset-content-multi",
        renameAssetAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/rename-asset",
        fetchPastGeneratedPostByAssetId: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-user-posts",
        createBookMarkAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/add-to-bookmark",
        removeBookMarkAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/remove-from-bookmark",
        getAllBookMarkedPostAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-bookmarked-posts",
        regeneratePostAPI:"https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/regenerate-post",
        createCategoryAPI: "https://geckoainode-dev-dot-geckoai2023.ue.r.appspot.com/create-category"
    }
};