<template>
  <div class="post-generate-wrap">
    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>
      <!-- <div class="post-switch">
        <h3 class="mt-0 mt-md-4 mb-3">Select Channel</h3>
        <v-select :items="channelList" label="" v-model="selectedItem" @change="switchChannels(selectedItem)" outlined>
          <template v-slot:item="{ item }">
            <i v-if="item.text == 'X (Twitter)'" class="fa-brands fa-x-twitter"></i>
            <v-icon v-else :style="{ color: item.iconColor }">{{
              item.icon
            }}</v-icon>{{ item.text }}
          </template>
        </v-select>

      </div>

      <hr /> -->

      <!-- <h3 class="mt-0 mt-md-3 mb-0">Category</h3>
      <div class="mt-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedCategory" :items="categoryList" label="Select" outlined item-text="categoryName"
            item-value="categoryId" color="#dde1e8"></v-select>
        </div>
      </div> -->
      <ul class="add-assets-button">
        <li @click="$router.push('/upload-file')">
          <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
          Asset
        </li>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>




      <v-treeview  open-all selectable :items="treeAsset" item-text='assetName' item-key='assetId'></v-treeview>

      <!-- <ul class="asset-list">
        <li v-for="(asset, index) in filteredAssetList" :key="index"
          :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-checkbox disabled class="select-asset" v-model="asset.IsChecked"></v-checkbox>
              </span>
            </template>
            <span>To edit this selection, click on the top right Back button</span>
          </v-tooltip>


          <div @click="fileViewAssetDialog = true" class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>
            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" />
          </div>
          <v-btn fab x-small elevation="0" class="mr-2" @click="fetchAssetContent(asset.assetId, index)"><v-icon
              size="22px" color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn fab x-small @click="editAssets(index, asset)" elevation="0"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>
        </li>
      </ul> -->

      <!-- <div class="text-right asset-read-more p-3"><a href="#">Read More</a></div> -->
      <v-btn @click="viewMobile = false" width="100%" color="#1eb375" class="apply-asset-btn">Apply</v-btn>

    </div>
    <div class="post-generate-inner">
      <div class="file-upload-section-header" v-if="blogPost && blogPost.length > 0">
        <div class="post-switch mr-2">
          <h3 class="">Generate Content For</h3>
          <v-select :items="channelList" label="" v-model="selectedItem" @change="switchChannels(selectedItem)" outlined
            height="40">
            <template v-slot:item="{ item }">
              <!-- <font-awesome-icon :icon="item.icon" :style="{ color: item.iconColor }" /> -->
              <i v-if="item.text == 'X (Twitter)'" class="fa-brands fa-x-twitter"></i>

              <v-icon v-else :style="{ color: item.iconColor }">{{
                item.icon
              }}</v-icon>{{ item.text }}
            </template>
          </v-select>
        </div>

        <div class="">
          <label v-if="selectAssetName">Selected Assets:</label>
          <div class="asset-name-header" v-if="selectAssetName">
            <p class="shrink-asset-name" style="width: 600px !important">{{ selectAssetName }}</p>
          </div>
        </div>
        <!-- <div class="post-gen-input-field">
          <input type="text" name="" id="" value=""
            placeholder="Ignore GeckoAI system defaults, and specify your content requirement" class="child" />
          <v-btn rounded large disabled color="#25b478">Generate</v-btn>
        </div> -->

      </div>

      <div class="linkedin-tab-post" v-if="blogPost && blogPost.length > 0">
        <div class="post-filter-switch">
          <div class="post-filter">
            <v-select :items="tones" label="Tone of Voice" outlined v-model="selectedTone"></v-select>
            <v-select :items="blogLength" label="Post Length (Words)" outlined v-model="selectedLength"></v-select>
            <!-- <v-select @change="onChangeRegenerateBlogs()" :items="languageList" label="Language" outlined
              v-model="selectedLanguage"></v-select> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-select outlined v-model="selectedStrategy" :items="strategyList" item-value="value" item-text="key"
                  :menu-props="{ maxHeight: '400' }" label="Select a Strategy" v-bind="attrs" v-on="on"
                  class="select-strategy-drop"></v-select>

              </template>
              <span>{{ selectedStrategy }}</span><br />
            </v-tooltip>

            <div class="d-flex justify-content-start align-items-center no-posts" style="width: 24%">
              <p class="mb-0 mr-3">No. of Posts</p>

              <select v-model="selectedNoOfPost">
                <option v-for="(post, index) in noOfPost" :key="index">
                  {{ post }}
                </option>
              </select>

            </div>
            <!-- <v-btn color="#1eb375" rounded class="apply-btn-width" @click="onChangeRegenerateBlogs()">Apply</v-btn> -->
            <div class="loading-more-post" @click="generateMoreBlogs()">
              Generate
              <div class="loading-dots ml-2">
                <v-icon color="#fff" size="24px" class="bounce-right">mdi-arrow-right</v-icon>
              </div>
            </div>
          </div>
          <div class="leftpannel-mobile">
            <div class="d-flex d-md-none align-items-center">
              <v-btn @click="OpenLeft()" text small><v-icon color="#333" size="25px">mdi-menu</v-icon> Category &amp;
                assets</v-btn>
            </div>
          </div>

        </div>
        <div class="post-list">
          <div v-for="(post, index) in blogPost" :key="post.Message" class="blog-wrapper-area">
            <div class="blog-post-hld mt-4" v-if="post.IsEdit == false">
              <v-btn text small fab @click="createBookMark(post.postId, index)" class="blog-bookmark"><v-icon size="30px"
                  :color="post.iconColor">{{
                    post.icon
                  }}</v-icon></v-btn>

              <div class="blog-post-inner-wrap">
                <div class="d-flex justify-content-start align-items-center">
                  <div class="blog-select">
                    <!-- <v-checkbox></v-checkbox> -->
                    <h4>Blog</h4>
                  </div>
                  <div class="blog-select" v-if="post.postStrategy">
                    <!-- <v-checkbox></v-checkbox> -->
                    <h3>{{ post.postStrategy }}</h3>
                  </div>
                  <!-- <div class="blog-select" v-if="post.postLanguage">
                    <h3>{{ post.postLanguage }}</h3>

                  </div> -->
                </div>


                <div class="blog-title mt-4">
                  <h3 v-if="post.Title && !post.IsRegenerateSkeletonMessageLoading
                    ">
                    {{ post.Title }}
                  </h3>

                  <h4 v-if="post.subTitle && !post.IsRegenerateSkeletonMessageLoading" class="">
                    {{ post.subTitle }}
                  </h4>
                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" 
                    type="article"></v-skeleton-loader>
                </div>
                <!-- <div @click="OpenBlogFullImage(post.Image)" class="blog-img" v-if="post.Image">
                  <img v-if="!post.IsRegenerateSkeletonImageLoading" :src="post.Image"
                    style="max-width: 100%; max-height: 100%" />
                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonImageLoading" v-bind="attrs"
                    type="image"></v-skeleton-loader>
                  <div class="hover-expand">
                    <v-btn fab text class="expand-btn"><v-icon color="#fff"
                        size="25px">mdi-arrow-expand-all</v-icon></v-btn>
                    <p>Click here to expand</p>
                  </div>
                </div> -->
                <div class="blog-desc">
                  <p v-if="post.postIntro && !post.IsRegenerateSkeletonMessageLoading">{{ post.postIntro }}</p>
                  <p v-if="post.postBody && !post.IsRegenerateSkeletonMessageLoading
                    " v-html="post.postBody"></p>
                  <p v-if="post.Conclusion &&
                    !post.IsRegenerateSkeletonMessageLoading
                    " v-html="post.Conclusion"></p>

                  <p v-if="!post.postBody &&
                    !post.Conclusion &&
                    !post.IsRegenerateSkeletonMessageLoading
                    " v-html="post.Message"></p>

                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" 
                    type="list-item-three-line"></v-skeleton-loader>
                  <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" 
                    type="list-item-three-line"></v-skeleton-loader>
                </div>
                <div v-if="post.postHashtags && post.IsMetaExpanded" :class="{ 'expanded': post.IsMetaExpanded }"
                  class="seo-tags expanding-div">

                  <div class="meta-title-desc" v-if="post.postSEOMetaTitle && post.postSEOMetaDesc">
                    <p v-if="post.postSEOMetaTitle && !post.IsRegenerateSkeletonMessageLoading"><span>Meta Title:</span>
                      {{ post.postSEOMetaTitle }}
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text x-small fab><v-icon size="20px" color="#333" v-bind="attrs"
                              v-on="on">mdi-content-copy</v-icon></v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </p>
                    <p v-if="post.postSEOMetaDesc && !post.IsRegenerateSkeletonMessageLoading">
                      <span>Meta Description:</span> {{ post.postSEOMetaDesc }}
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text x-small fab><v-icon size="20px" color="#333" v-bind="attrs"
                              v-on="on">mdi-content-copy</v-icon></v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </p>
                    <p v-if="post.postHashtags && !post.IsRegenerateSkeletonMessageLoading">
                      <span>Tags:</span> <span v-html="post.postHashtags"></span>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text x-small fab><v-icon size="20px" color="#333" v-bind="attrs"
                              v-on="on">mdi-content-copy</v-icon></v-btn>
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                    </p>
                    <v-skeleton-loader v-if="post.IsRegenerateSkeletonMessageLoading" 
                      type="list-item-three-line"></v-skeleton-loader>
                  </div>
                </div>
              </div>
              <div class="post-schedule-button">
                <div class="post-btn-wrap">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text @click="regeneratePost(post.postId, index)" v-bind="attrs" v-on="on"><img
                          src="../assets/Images/aipostgenerator/regenerate-ico-dark.png"
                          class="mr-2" />Re-Generate</v-btn>
                    </template>
                    <span>Click to Regenerate Text</span>
                  </v-tooltip>

                  <v-btn @click="
                    OpenEditArea(post.RawMessage, post.Image);
                  post.IsEdit = true;
                  " text><img src="../assets/Images/aipostgenerator/edit_blog_ico.png" class="mr-2" />Edit</v-btn>
                </div>
                <div class="d-none">
                  <v-btn disabled dark color="#000" class="mr-2 save-draft" @click="redrictToDashboard()">Save
                    Draft</v-btn>
                  <!-- <v-btn color="#1eb375" class="common-submit-blue-btn"
                    >Schedule</v-btn
                  > -->
                </div>


                <div class="post-bottom-right">
                  <div class="copy-post">

                    <p style="color: #333">Copy</p>
                    <v-btn fab text x-small color="#eee" @click="copyTextMessage(post.Title, post.RawMessage, index)"
                      class="mr-3"><v-icon color="#333" size="20px">mdi-content-copy</v-icon></v-btn>
                    <p style="color: #333">Meta Info</p>
                    <v-btn fab text x-small color="#eee" @click="toggleMetaSection(post.IsMetaExpanded, index)"><v-icon
                        color="#333" size="28px">{{
                          post.IsMetaExpanded ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon></v-btn>
                    <p class="copied-msg" v-if="post.IsCopied">Text Copied!</p>

                  </div>
                  <div class="rate-this-image">
                    <p style="color: #333">Rate</p>
                    <v-btn fab text x-small class="mr-2 like-this"><v-icon color="#333">mdi-thumb-up</v-icon></v-btn>
                    <v-btn fab text x-small class="mr-4"><v-icon color="#333">mdi-thumb-down</v-icon></v-btn>
                  </div>
                </div>

              </div>
            </div>
            <div v-if="post.IsEdit" class="blog-post-hld edit-blog-post-hld">
              <div class="blog-post-inner-wrap">
                <div class="blog-img d-none" v-if="tempBlogImage">
                  <img :src="tempBlogImage" style="max-width: 100%" />

                  <div class="edit_image_linkedin">
                    <v-icon size="25px" color="#fff" @click="refreshBlogImage()"
                      v-if="!tempImgChangeLoader">mdi-refresh</v-icon>
                    <v-progress-circular v-if="tempImgChangeLoader" indeterminate color="#fff"
                      size="25"></v-progress-circular>
                  </div>
                </div>
                <div class="blog-desc">
                  <!-- <p v-if="blogMessage" v-html="blogMessage"></p> -->
                  <v-textarea name="input-7-1" class="edit-text-area" v-model="tempBlogMessage">
                  </v-textarea>
                </div>
              </div>
              <div class="post-schedule-button d-flex justify-content-end">
                <div class="">
                  <v-btn dark @click="post.IsEdit = false" color="#343d4c"
                    class="mr-2 save-draft common-submit-blue-btn discard-post-save" elevation="0">Discard</v-btn>
                  <v-btn dark color="#2ab277" class="mr-2 common-submit-blue-btn"
                    @click="saveEditedPost(index)">Save</v-btn>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!-- <div class="blog-blank-screen" v-else>
        <img src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
        <h3 class="mt-3">
          <span>{{ randomText }}</span>
        </h3>

      </div> -->
    </div>
    <v-dialog v-model="FullBlogImage" max-width="500px">
      <v-card class="card-image-hld blog-full-image">
        <div class="blog-img" style="border: none">
          <img :src="blogImage" style="max-width: 100%" />
          <div class="button-set-hld">

            <v-btn text class="button-set-single" :href="blogImage" target="_blank"><v-icon color="#fff" size="25px"
                class="regen mr-2">mdi-tray-arrow-down</v-icon>
              Download</v-btn>
          </div>
        </div>
        <v-btn @click="FullBlogImage = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileEditNameDialog" max-width="500px">
      <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <h4 class="edit_asset_name">Edit Asset Name</h4>
        <v-text-field v-model="tempFileName" id="temp-file-edit" placeholder="Enter your file name" height="45"
          outlined></v-text-field>

        <span class="validation-msg" v-if="assetErrorFlg">File Name cannot be blank</span>
        <div class="d-flex justify-content-center align-items-center mt-2">
          <v-btn elevation="0" large dense color="#dfdfdf"
            class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
            @click="cancelFileNameChange()">Cancel</v-btn>
          <v-btn large elevation="0" @click="saveFileNameChange()" color="#1eb375"
            class="common-submit-blue-btn mr-0 mb-md-0">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileViewAssetDialog" width="auto">
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <div class="" style="width: 100%">
            <div class="view-asset-head">
              <h4>View Asset</h4>
              <v-btn fab text x-small class="close-btn" @click="fileViewAssetDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
            <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          </div>
          <div v-html="formattedViewContent"></div>
        </v-card>
      </template>
      <!-- <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
        <div>
          <v-btn @click="fileViewAssetDialog = false" x-small color="rgba(0,0,0,0.2)"><v-icon size="22px"
            color="#fff">mdi-close</v-icon></v-btn>
        </div>
        
      <div v-html="formattedViewContent"></div>
      
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

var vm;
Vue.use(VueBus);
export default {
  props: {},
  data() {
    return {
      selectAssetName:'',
      treeAsset: [
        {
          id: 1,
          assetName: 'Default :',
          children: [
          ],
        }],
      bookmarkedPostList: [],
      fileViewAssetDialog: false,
      viewAssetContent: '',
      viewMobile: true,
      tones: ["Formal", "Humor", "Compassion"],
      blogLength: [
        { text: "Short (200)", value: "200" },
        { text: "Medium (400)", value: "400" },
        { text: "Long (600)", value: "600" },
      ],
      selectedTone: "Formal",
      selectedLength: { text: "Medium", value: "400" },
      strategyList: [
        {
          key: 'How to guides',
          value: 'HOW_TO_GUIDES'
        },
        {
          key: 'Tips and tricks',
          value: 'TIPS_AND_TRICKS'
        },
        {
          key: 'Best practices',
          value: 'BEST_PRACTICES'
        },
        {
          key: 'Case studies',
          value: 'CASE_STUDIES'
        },
        {
          key: 'Beginners guide',
          value: 'BEGINNERS_GUIDE'
        },
        {
          key: 'Expert interviews',
          value: 'EXPERT_INTERVIEWS'
        },
        {
          key: 'Trend analysis',
          value: 'TREND_ANALYSIS'
        },
        {
          key: 'Product service reviews',
          value: 'PRODUCT_SERVICE_REVIEWS'
        },
        {
          key: 'Qna sessions',
          value: 'QNA_SESSIONS'
        },
        {
          key: 'Curated content',
          value: 'CURATED_CONTENT'
        },
        {
          key: 'Opinions and thought leadership',
          value: 'OPINIONS_AND_THOUGHT_LEADERSHIP'
        },
        {
          key: 'Industry news updates',
          value: 'INDUSTRY_NEWS_UPDATES'
        },
        {
          key: 'Comparison guides',
          value: 'COMPARISON_GUIDES'
        },
        {
          key: 'FAQ',
          value: 'FAQ'
        }],
      languageList: [
        "English",
        "Mandarin",
        "Spanish",
        "French",
        "Japanese",
        "Hindi",
        "Arabic",
        "German",
        "Russian",
      ],
      noOfPost: ["1", "3", "5"],
      selectedNoOfPost: "1",
      selectedLanguage: "English",
      selectedStrategy: "BEGINNERS_GUIDE",
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      productList: [],
      selectedProduct: null,
      selectedCategory: null,
      linkedInPost: [],
      blogPost: [],
      blogMessage: null,
      blogImage: null,
      isBlogLoading: false,
      EditBlogPost: false,
      BlogPostSec: true,
      FullBlogImage: false,
      tempBlogMessage: null,
      tempBlogImage: null,
      blogPostBox: true,
      tempImgChangeLoader: false,
      companyName: null,
      showMorePostLoader: false,
      contentType: null,
      contentName: null,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      userAssetList: [],
      activeIndex: 0,
      fileName: null,
      fileSize: null,
      searchTerm: "",
      selectedItem: "Blog",
      showSave: false,
      isClicked: false,
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "X (Twitter)",
          icon: "x-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        }
      ],
      EmailId: null,
      categoryList: [],
      assetList: [],
      isExpanded: true,

    };
  },
  computed: {
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    filteredAssetList() {
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(this.searchTerm ? this.searchTerm.toLowerCase() : '')
      ).sort((a, b) => b.assetId - a.assetId);
    },
    formattedPostBody(body) {
      return body.replace(/\n/g, '<br/>');
    }
  },
  created() {
    vm = this;
  },

  mounted() {
    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
      this.blogPost = [];
      vm.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
    }
    if(localStorage.getItem("latestAssetName")){
      this.selectAssetName = localStorage.getItem("latestAssetName");
    }

    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/content-v2/blog",
        query: {},
      });
      location.reload();
    }
    this.getCategories();
  },
  watch: {
    filteredAssetList: {
      handler(filteredAssets) {
        this.updateTreeAsset(filteredAssets);
      },
      immediate: true, // Call the handler immediately on component mount
    },
  },

  methods: {
    updateTreeAsset(filteredAssets) {
      this.treeAsset[0].children = filteredAssets;
    },
    toggleMetaSection(flg, index) {
      if (flg) {
        this.blogPost[index].IsMetaExpanded = false;
      } else {
        this.blogPost[index].IsMetaExpanded = true;
      }

    },
    async regeneratePost(postId, index) {
      console.log("index===", postId);
      this.blogPost[index].IsRegenerateSkeletonMessageLoading = true;
      this.blogPost[index].IsMetaExpanded = false;
      //var msg;
      const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      const requestData1 = {
        "postId": postId
      };


      console.log("requestData1===", requestData1);
      axios
        .post(APIs.regeneratePostAPI, requestData1, { headers: header })
        .then((response1) => {
          // Handle the response of the first API call
          console.log("Response from API 1:", response1.data);
          if (response1.data.status == 0) {
            this.blogPost[index].Message = this.convertHashtagsToLinks(response1.data.data.postBody);
            this.blogPost[index].RawMessage = response1.data.data.postBody;
            this.blogPost[index].postStrategy = response1.data.data.postStrategy;
            this.blogPost[index].postLanguage = response1.data.data.input.language;
            this.blogPost[index].IsRegenerateSkeletonMessageLoading = false;
            this.blogPost[index].postBody = this.convertHashtagsToLinks(response1.data.data.postBody);
            this.blogPost[index].postHashtags = this.formattedHashtags(response1.data.data.postHashTags);
            this.blogPost[index].postTone = response1.data.data.postTone;
            this.blogPost[index].Title = response1.data.data.postTitle;
            this.blogPost[index].subTitle = response1.data.data.postSubHeadline;
            this.blogPost[index].Body = response1.data.data.postBody;
            this.blogPost[index].Conclusion = response1.data.data.postConclusion;
            this.blogPost[index].postIntro = response1.data.data.postIntro;
            this.blogPost[index].postSEOMetaDesc = response1.data.data.postSEOMetaDesc;
            this.blogPost[index].postSEOMetaTitle = response1.data.data.postSEOMetaTitle;
            this.blogPost[index].IsMetaExpanded = true;

          }


        })
        .catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error("Error:", error);
        });
    },
    getAllBookmarkedPost() {
      axios
        .get(APIs.getAllBookMarkedPostAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("bookmark posts======", response);
          if (response.data.status == 0) {
            for (const obj of response.data.data.message) {
              this.bookmarkedPostList.push(obj.postId);
            }
          }

        })
        .catch((error) => {
          console.error(error);
        });
    },
    createBookMark(postId, index) {
      console.log("postId====", postId);

      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      axios
        .post(APIs.createBookMarkAPI, {
          "key": {
            "postId": postId
          },
          "data": {
          }
        }, { headers: header1 })
        .then((response1) => {
          console.log("boookmark", response1);
          if (response1.data.status == 0) {
            this.blogPost[index].icon =
              this.blogPost[index].icon === "mdi-bookmark-outline"
                ? "mdi-bookmark"
                : "mdi-bookmark-outline";
            this.blogPost[index].iconColor =
              this.blogPost[index].iconColor === "#333" ? "#1eb375" : "#333";

          }
        });
    },
    removeBookMark(postId, index) {
      console.log("postId====", postId);
      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      axios
        .post(APIs.removeBookMarkAPI, {
          "key": {
            "postId": postId
          },
          "data": {
          }
        }, { headers: header1 })
        .then((response1) => {
          console.log("boookmark", response1);
          if (response1.data.status == 0) {
            this.blogPost[index].icon =
              this.blogPost[index].icon === "mdi-bookmark-outline"
                ? "mdi-bookmark"
                : "mdi-bookmark-outline";
            this.blogPost[index].iconColor =
              this.blogPost[index].iconColor === "#333" ? "#1eb375" : "#333";
          }
        });
    },
    generateMoreBlogs() {
      //this.blogPost = [];
      this.isTwitterLoading = true;
      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.blogPost.push({
          TargetAudience: '',
          postBody: '',
          postHashtags: '',
          postStrategy: '',
          postTone: '',
          Image: "",
          Message: "",
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsMetaExpanded: false,
          IsCopied: false,
          Title: '',
          subTitle: '',
          Body: '',
          Conclusion: '',
          Description: "",
          Keywords: "",
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postId: '1',
          postIntro: '',
          postSEOMetaTitle: '',
          postSEOMetaDesc: '',
          postLanguage: ''
        });
      }

      this.blogPost.reverse();

      //[...this.blogPost].reverse()

      //this.generateRandomText();

      //setInterval(this.generateRandomText, 6000);


      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(localStorage.getItem("latestAssetId")));
      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "blog",
        countOfPosts: "1",
        contentStrategy: tempStrategy
      };
      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(localStorage.getItem("latestAssetId"));
          }
        });
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },
    fetchPreviousGeneratedPostByAssetId(assetId) {
      // this.generateRandomText();

      // setInterval(this.generateRandomText, 6000);
      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.blogPost.push({
          TargetAudience: '',
          postBody: '',
          postHashtags: '',
          postStrategy: '',
          postTone: '',
          Image: "",
          Message: "",
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsMetaExpanded: false,
          IsCopied: false,
          Title: '',
          subTitle: '',
          Body: '',
          Conclusion: '',
          Description: "",
          Keywords: "",
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postId: '1',
          postIntro: '',
          postSEOMetaTitle: '',
          postSEOMetaDesc: '',
          postLanguage: ''
        });
      }

      this.blogPost.reverse();
      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));

      const requestData1 = {
        "channel": "blog",
        assetIds: tempArr,
      };
      axios
        .post(APIs.fetchPastGeneratedPostByAssetId, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0 && response1.data.data.message && response1.data.data.message.length > 0) {
            this.blogPost = [];
            for (const obj of response1.data.data.message) {
              if (this.selectedLanguage == 'English') {
                this.blogPost.push({
                  TargetAudience: obj.TargetAudience,
                  postBody: this.convertHashtagsToLinks(obj.postBody),
                  postHashtags: this.formattedHashtags(obj.postHashTags),
                  postStrategy: obj.postStrategy,
                  postTone: obj.postTone,
                  Image: "",
                  Message: "",
                  RawMessage: obj.postBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsMetaExpanded: true,
                  IsRegenerateSkeletonImageLoading: false,
                  IsCopied: false,
                  Title: obj.postTitle,
                  subTitle: obj.postSubHeadline,
                  Body: obj.postBody,
                  Conclusion: obj.postConclusion,
                  Description: "",
                  Keywords: "",
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postId: obj.postId,
                  postIntro: obj.postIntro,
                  postSEOMetaTitle: obj.postSEOMetaTitle,
                  postSEOMetaDesc: obj.postSEOMetaDesc,
                  postLanguage: obj.input.language
                });
              } else {
                this.blogPost.push({
                  TargetAudience: obj.TargetAudience,
                  postBody: this.convertHashtagsToLinks(obj.translatedBody ? obj.translatedBody : obj.postBody),
                  postHashtags: this.formattedHashtags(obj.postHashTags),
                  postStrategy: obj.postStrategy,
                  postTone: obj.postTone,
                  Image: "",
                  Message: "",
                  RawMessage: obj.translatedBody ? obj.translatedBody : obj.postBody,
                  IsEdit: false,
                  IsRegenerateSkeletonMessageLoading: false,
                  IsRegenerateSkeletonImageLoading: false,
                  IsMetaExpanded: true,
                  IsCopied: false,
                  Title: obj.postTitle,
                  subTitle: obj.postSubHeadline,
                  Body: obj.postBody,
                  Conclusion: obj.postConclusion,
                  Description: "",
                  Keywords: "",
                  icon: "mdi-bookmark-outline", // Initial icon
                  iconColor: "#333",
                  postId: obj.postId,
                  postIntro: obj.postIntro,
                  postSEOMetaTitle: obj.postSEOMetaTitle,
                  postSEOMetaDesc: obj.postSEOMetaDesc,
                  postLanguage: obj.input.language
                });
              }
            }
            vm.getAllBookmarkedPost();
          } else {
            vm.generateAllBlogsV2(localStorage.getItem("latestAssetId"));
          }
        });
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    async fetchAssetContent(assetId, index) {
      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetIds: [parseInt(assetId)]
        };

        const response = await axios.post(apiUrl, data, { headers });
        if (response.data.status == 0) {
          this.viewAssetContent = response.data.data.assetContents;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    formattedHashtags(hashtags) {
      console.log("hash====", hashtags);
      if (Array.isArray(hashtags)) {
        var result = hashtags.map(tag => `<a href="#">${tag}</a>`).join(" ");
        return result;
      } else {
        return hashtags
      }
    },
    getCategories() {
      axios
        .get(APIs.getCategoriesAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.categories && response.data.categories.length > 0) {
            this.categoryList = response.data.categories;
            this.selectedCategory = response.data.categories[0].categoryId;
          }
          this.getAssetList();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAssetList() {
      axios
        .get(APIs.getAssetAPI, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          if (response.data && response.data.status == 0) {
            this.assetList = response.data.assets;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onChangeRegenerateBlogs() {
      if (localStorage.getItem("latestAssetId")) {
        this.blogPost = [];
        vm.generateAllBlogsV2(localStorage.getItem("latestAssetId"));
      }
    },
    redrictToChat() {
      this.$router.push("/geckochat");
    },
    generateAllBlogsV2(assetId) {
      this.isTwitterLoading = true;
      // this.generateRandomText();

      // setInterval(this.generateRandomText, 6000);

      for (let i = 0; i < this.selectedNoOfPost; i++) {
        this.blogPost.push({
          TargetAudience: '',
          postBody: '',
          postHashtags: '',
          postStrategy: '',
          postTone: '',
          Image: "",
          Message: "",
          RawMessage: '',
          IsEdit: false,
          IsRegenerateSkeletonMessageLoading: true,
          IsRegenerateSkeletonImageLoading: false,
          IsMetaExpanded: false,
          IsCopied: false,
          Title: '',
          subTitle: '',
          Body: '',
          Conclusion: '',
          Description: "",
          Keywords: "",
          icon: "mdi-bookmark-outline", // Initial icon
          iconColor: "#333",
          postId: '1',
          postIntro: '',
          postSEOMetaTitle: '',
          postSEOMetaDesc: '',
          postLanguage: ''
        });
      }


      this.blogPost.reverse();


      const header1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      var tempArr = [];
      tempArr.push(parseInt(assetId));
      var tempStrategy = [];
      tempStrategy.push(this.selectedStrategy);

      const requestData1 = {
        assetIds: tempArr,
        words: this.selectedLength.value ? this.selectedLength.value : this.selectedLength,
        tone: this.selectedTone,
        language: this.selectedLanguage,
        typeofpost: "text",
        userInput: "",
        date_time: "07/14/2023",
        targetAudience: "B2B professionals",
        cta: "Book a Meeting",
        channel: "blog",
        countOfPosts: this.selectedNoOfPost,
        contentStrategy: tempStrategy
      };
      axios
        .post(APIs.generateContentAPI, requestData1, { headers: header1 })
        .then((response1) => {
          if (response1.data.status == 0) {
            this.fetchPreviousGeneratedPostByAssetId(assetId);
          }
        });
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .put(
            APIs.renameAssetAPI,
            {
              "key": {
                "assetId": this.tempAssetEditId
              },
              "data": {
                "assetName": this.tempFileName
              }
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.status == 0) {
              this.fileEditNameDialog = false;
              this.getAssetList();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    switchChannels(type) {
      if (type == "LinkedIn") {
        this.redrictToLinkedIn();
      } else if (type == "X (Twitter)") {
        this.redrictToTwitter();
      } else if (type == "Chat with Data") {
        this.redrictToChat();
      }
    },
    editAssets(index, asset) {
      this.tempFileName = asset.assetName;
      this.tempAssetEditId = asset.assetId;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();
    },
    changeBookmark(index) {
      // Toggle the icon on each click
      this.blogPost[index].icon =
        this.icon === "mdi-bookmark-outline"
          ? "mdi-bookmark"
          : "mdi-bookmark-outline";
      this.blogPost[index].iconColor =
        this.iconColor === "#333" ? "#1eb375" : "#333"; // Toggle color
    },
    OpenEditArea(message, imgUrl) {
      this.tempBlogMessage = message;
      this.tempBlogImage = imgUrl;
      this.EditBlogPost = true;
      this.BlogPostSec = false;
    },
    generateRandomText() {
      const texts = [
        "AI working. Grab a coffee!",
        "Magic happening. Thanks for patience.",
        "AI magic. Content coming soon.",
        "In progress. Won't be long.",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    copyTextMessage(title, text, index) {
      this.blogPost[index].IsCopied = true;
      var copyTxt = text;

      navigator.clipboard
        .writeText(copyTxt)
        .then(() => {
          console.log("Text copied to clipboard");

          setTimeout((this.blogPost[index].IsCopied = false), 3000);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
    redrictToTwitter() {
      this.$router.push("/content-v2/twitter");
    },
    async refreshBlogImage() {
      this.tempImgChangeLoader = true;
      if (localStorage.getItem("generatedSummary")) {
        try {
          const response = await axios.post(
            APIs.createMultiBlogAPI,
            {
              text: localStorage.getItem("generatedSummary"),
              company: this.companyName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data.code == 1) {
            this.tempImgChangeLoader = false;
            this.tempBlogImage = response.data.image.error
              ? ""
              : response.data.image.data[0].url;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    saveEditedPost(index) {
      this.blogPost[index].Image = this.tempBlogImage;
      this.blogPost[index].Message = this.convertHashtagsToLinks(
        this.tempBlogMessage
      );
      this.blogPost[index].RawMessage = this.tempBlogMessage;
      this.blogPost[index].IsEdit = false;
      localStorage.setItem("blogPostList", JSON.stringify(this.blogPost));
    },

    redrictToLinkedIn() {
      this.$router.push("/content-v2/linkedin");
    },
    redrictToDashboard() {
      this.$router.push("/dashboard");
    },
    convertHashtagsToLinks(paragraph) {
      const hashtagRegex = /#(\w+)/g;
      const hyperlinkTemplate = '<a href="javascript:void(0)">#$1</a>';
      var result;
      if (hashtagRegex.test(paragraph)) {
        result = paragraph.replace(hashtagRegex, hyperlinkTemplate)
      } else {
        result = paragraph;
      }
      return result;
    },
    OpenBlogFullImage(url) {
      this.blogImage = url;
      this.FullBlogImage = true;
    },
  },
};
</script>
<style>
.blog-img {
  position: relative;
}
</style>