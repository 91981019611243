<template>
  <div class="container">
    <div class="blog-blank-screen" v-if="randomText">
      <img src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
      <h3 class="mt-3">
        <span>{{ randomText }}</span>
      </h3>
    </div>
    <div class="create-new-project" v-else>
      <div class="new-project-card-hld">
        <div class="create-project-header justify-content-center">
          <div style="text-align: center">
            <h3>Create New Project</h3>
          </div>
        </div>

        <div class="open-project-tabs" v-if="!toggleDesignUploads">
          <div class="open-project-single-tabs">
            <img src="../assets/Images/aipostgenerator/scanwebsite-url.png" />
            <p>Enter URL</p>
            <v-chip class="upcoming-badge" color="#1eb375">Upcoming</v-chip>
          </div>
          <div
            class="open-project-single-tabs"
            @click="
              toggleDesignUploads = true;
              tabs = 1;
            "
          >
            <img src="../assets/Images/aipostgenerator/upload_a_doc.png" />
            <p>Upload a document<br /></p>
          </div>
          <div
            class="open-project-single-tabs"
            @click="
              toggleDesignUploads = true;
              tabs = 2;
            "
          >
            <img src="../assets/Images/aipostgenerator/paste_content.png" />
            <p>Paste your content</p>
          </div>
        </div>
        <div class="scan_upload" v-if="toggleDesignUploads">
          <v-tabs
            v-model="tabs"
            centered
            next-icon="mdi-arrow-right"
            prev-icon="mdi-arrow-left"
            show-arrows
          >
            <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-link</v-icon> Enter URL </v-tab>
            <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-youtube</v-icon> Youtube Link </v-tab>
            <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-outline</v-icon> Upload Document </v-tab>
            <v-tab> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-multiple-outline</v-icon> Paste Your Content</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card flat>
                <div class="scan_url-hld">
                  <label>Ingest Content from Website URL</label>
                  <input
                    type="url"
                    name=""
                    id=""
                    value=""
                    placeholder="Enter URL Here"
                  />
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      disabled
                      color="#1eb375"
                      class="common-submit-blue-btn"
                      @click="sendForSummaryGeneration('url')"
                      >Proceed</v-btn>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#333"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <div class="scan_url-hld">
                  <label>Ingest Youtube Video Transcript</label>
                  <input
                    type="url"
                    name=""
                    id=""
                    value=""
                    placeholder="Enter URL Here"
                    class="youtube-placeholder"
                  />
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      disabled
                      color="#1eb375"
                      class="common-submit-blue-btn"
                      @click="sendForSummaryGeneration('url')"
                      >Proceed</v-btn
                    >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#333"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <div class="upload_document_area">
                  <label>Upload Document to Ingest</label>
                  <div
                    class="upload_document_space"
                    @dragover="handleDragOver"
                    @dragleave="handleDragLeave"
                    @drop="handleDrop"
                    @click="openFileExplorer"
                  >
                    <img
                      src="../assets/Images/aipostgenerator/upload_document.png"
                    />

                    <h3 v-if="!isDragging">
                      Drag and drop your file here! or <a href="javascript:void(0)">browse</a>
                    </h3>
                    <p v-else>Drop files here</p>

                    <h4>
                      Supported file types (PDF, DocX, Doc, Txt,Xlsx) <br />
                      Max file size 20 MB <br />
                    </h4>
                    <input
                      ref="fileInput"
                      type="file"
                      style="display: none"
                      @change="handleFileSelection"
                      id="fileInput1"
                      accept=".txt,.pdf,.doc,.docx"
                    />
                  </div>

                  <P v-if="tempfiles && tempfiles.length > 0">
                    <!-- <img
                      src="../assets/Images/aipostgenerator/textIcon.svg"
                      height="25px"
                      width="25px"
                      class="mr-2"
                    /> -->

                    <span class="d-inline-block">{{
                      tempfiles[0].name
                    }}</span></P
                  >
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      v-if="tempfiles && tempfiles.length > 0"
                      :disabled="
                        tempfiles && tempfiles.length > 0 ? false : true
                      "
                      color="#1eb375"
                      class="common-submit-blue-btn"
                      @click="uploadFileAsset('file')"
                      >Proceed</v-btn
                    >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#333"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI is busy<br />with other request,
                        please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <div class="upload_document_area">
                  <div class="post-content-txt-area">
                    <label>Paste Text Content (up to 5000 words)</label>
                    <textarea
                      cols="12"
                      rows="12"
                      placeholder="Paste your content here..."
                      v-model="contentPasted"
                    ></textarea>
                  </div>
                  <div
                    class="butn-tooltip-group d-flex justify-center align-items-center"
                  >
                    <v-btn
                      v-if="contentPasted"
                      :disabled="contentPasted ? false : true"
                      color="#1eb375"
                      class="common-submit-blue-btn"
                      @click="sendForSummaryGeneration('paste')"
                      >Proceed</v-btn
                    >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex justify-content-start align-items-center"
                          style="visibility: hidden"
                          ><v-icon size="25px" color="#333"
                            >mdi-refresh</v-icon
                          ></span
                        >
                      </template>
                      <span
                        >Look's like our AI system is busy<br />with other
                        request, please<br />click here to retry</span
                      >
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
// import {eventBus} from '@/main'
Vue.use(VueBus);
Vue.use(VueToast);
var vm = this;
export default {
  components: {},
  props: {
    msg: String,
    // msg:{
    //     required:true,
    //     type: "string"
    // }
  },
  data() {
    return {
      selectedAssetIds: [],
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      selectedTab: "Transcript",
      searchTerm: "",
      tempFileContent: null,
      toggleDesignUploads: true,
      tabs: 1,
      userAssetList: [],
      generatedMessage: null,
      isBlogLoading: false,
      isLinkedInLoading: false,
      fileName: null,
      fileSize: null,
      generateBtnClicked: false,
      companyName: null,
      isTwitterLoading: false,
      EmailId: null,
      activeIndex: 0,
      OpenProductcreateDialog: false,
      productMood: "Formal",
      productDescription: "",
      productName: "",
      selectedFile: null,
      productList: [],
      selectedProduct: null,
      createProductLoader: false,
      productNameValidation: false,
      productDescriptionValidation: false,
      productFileValidation: false,
      showOverAllLoader: false,
      newAssetDialog: false,
      isDragging: false,
      tempfiles: [],
      contentPasted: null,
      makeEdit: true,
      PostOptionOpen: false,
      fileContent: null,
      randomText: "",
    };
  },
  watch: {
    selectedProduct() {
      console.log("watch productList callled========", this.productList);
      console.log(
        "watch selectedProduct callled========",
        this.selectedProduct
      );

      localStorage.setItem("selectedProductId", this.selectedProduct);
      localStorage.setItem("tempSelectedProductId", this.selectedProduct);
      const product = this.productList.find(
        (item) => item.id === this.selectedProduct
      );
      this.showOverAllLoader = true;
      if (product) {
        localStorage.setItem("selectedProductName", product.name);
        this.getAssetListByProduct(this.selectedProduct);
      }
    },
  },
  computed: {
    filteredAssetList() {
      return this.userAssetList.filter((asset) =>
        asset.filename.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    generateRandomText() {
      const texts = [
        "Uploding our file!",
        "Magic happening parsing your file data. Thanks for patience.",
        "AI magic. Content coming soon.",
        "In progress. Won't be long.",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    uploadFileAsset(type) {
      if (type == "file") {
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
        this.generateRandomText();

        setInterval(this.generateRandomText, 6000);
        const formData = new FormData();
        formData.append("asset", file);
        formData.append("userId", "1");
        formData.append("categoryId", "1");

        // Make the API request using axios or any other HTTP library
        // Example using axios:
        axios
          .post(APIs.uploadAssetAPI, formData)
          .then((response) => {
            console.log("ndg sir api response====", response.data);

            
            if(response.data.status == 0){
              localStorage.setItem("latestAssetId", response.data.data.assetId);
              this.$router.push("/content-v2/blog");
              
            }

            // Handle the API response as needed
            // if (response.data.status == 0) {
            //   vm.postData(response.data.text, "file", file.name);
            //   //   if (response.data.text.length > 7900) {
            //   //     vm.postDataUsingTurbo(
            //   //       response.data.text,
            //   //       "file",
            //   //       file.name
            //   //     );
            //   //   } else {
            //   //     vm.postData(response.data.text, "file", file.name);
            //   //   }
            // }
          })
          .catch((error) => {
            console.error(error);
            // Handle any errors
          });
      }
    },
    selectAssets() {
      var checkedIds = this.userAssetList
        .filter((obj) => obj.IsChecked === true)
        .map((obj) => obj.id);

      console.log("checked assets id====", checkedIds);
      localStorage.setItem("multiAssetIds", JSON.stringify(checkedIds));
      this.selectedAssetIds = checkedIds;
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    saveFileNameChange() {
      if (this.tempFileName && this.tempFileName.length > 0) {
        this.assetErrorFlg = false;
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: this.tempAssetEditId,
              filename: this.tempFileName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              this.fileEditNameDialog = false;
              this.getAssetListByProduct(
                localStorage.getItem("selectedProductId")
              );

              // asset.IsEdit = false;
              // this.activeIndex = index;
              // //this.generatedMessage = asset.summarygenerated;
              // this.fileName = asset.filename;
              // this.fileSize = asset.fileSize;
              // console.log("sf=========", asset);

              // localStorage.setItem("selectedFileName", asset.filename);
              // localStorage.setItem("selectedFileType", asset.filetype);
              // this.backEdit(asset);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.assetErrorFlg = true;
      }
    },
    goToBlog(data, data2) {
      localStorage.removeItem("blogPostList");
      localStorage.removeItem("blogmessage");
      localStorage.removeItem("blogtitle");
      localStorage.removeItem("blogimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/blog");
    },
    goToLinkedIn(data, data2) {
      localStorage.removeItem("linkedInPostList");
      localStorage.removeItem("linkedInmessage");
      localStorage.removeItem("linkedInimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/linkedin");
    },
    goToTwitter(data, data2) {
      localStorage.removeItem("twitterPostList");
      localStorage.removeItem("twittermessage");
      localStorage.removeItem("twitterimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/twitter");
    },
    OpenPostOption() {
      this.PostOptionOpen = true;
    },

    editAssets(index, asset) {
      console.log("asset====", asset);
      console.log("asset====", asset);
      console.log("asset====", asset);
      this.tempFileName = asset.filename;
      this.tempAssetEditId = asset.id;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();

      // this.makeEdit = false;
      // const element = document.getElementById("MakeFocus" + index);
      // if (element) {
      //   setTimeout(() => {
      //     element.focus();
      //     asset.IsEdit = true;
      //   }, 500);
      // }
    },

    async postData(data, type, fileName) {
      this.showOverAllLoader = true;
      vm.showLoaderPage = true;
      vm.newAssetDialog = false;
      vm.storeDataForRetry = data;

      this.intervalId = setInterval(this.incrementCounter, 1000);
      var payload;
      if (vm.showRetryBtn) {
        vm.retryBtnLoader = true;
        payload = {
          text: vm.storeDataForRetry,
          company: localStorage.getItem("emailId").includes("gecko")
            ? "gecko"
            : "energyrecovery.com",
        };
      } else {
        payload = {
          text: data,
          company: localStorage.getItem("emailId").includes("gecko")
            ? "gecko"
            : "energyrecovery.com",
        };
      }

      try {
        const response = await axios.post(APIs.postSummaryAPI, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("summary reeived------", response.data);

        if (response.data.code == 1 && response.data.message) {
          if (localStorage.getItem("emailId")) {
            var payload2 = {
              emailid: localStorage.getItem("emailId"),
              filename: fileName ? fileName : response.data.filename,
              summarygenerated: response.data.message,
              fileSize: localStorage.getItem("uploadedFileSize")
                ? localStorage.getItem("uploadedFileSize")
                : 0,
              filetype: type == "paste" ? "paste" : "txt",
              productid: localStorage.getItem("selectedProductId"),
              transcript: data,
            };
            const response2 = await axios.post(APIs.createAssetAPI, payload2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response2.data.code == 1) {
              console.log("response2=====", response2);
            }
          }
          this.contentPasted = null;
          this.tempfiles = [];
          this.newAssetDialog = false;
          this.showOverAllLoader = true;

          this.getAssetListByProduct(localStorage.getItem("selectedProductId"));
        } else {
          vm.showRetryBtn = true;
        }
      } catch (error) {
        vm.showRetryBtn = true;
        console.error(error);
      }
    },
    async postDataUsingTurbo(data, type, fileName) {
      this.showOverAllLoader = true;
      vm.showLoaderPage = true;
      vm.newAssetDialog = false;
      vm.storeDataForRetry = data;

      this.intervalId = setInterval(this.incrementCounter, 1000);
      var payload;
      if (vm.showRetryBtn) {
        vm.retryBtnLoader = true;
        payload = {
          text: vm.storeDataForRetry,
          company: localStorage.getItem("emailId").includes("gecko")
            ? "gecko"
            : "energyrecovery.com",
        };
      } else {
        payload = {
          text: data,
          company: localStorage.getItem("emailId").includes("gecko")
            ? "gecko"
            : "energyrecovery.com",
        };
      }

      try {
        const response = await axios.post(APIs.createSummaryTurboAPI, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("summary reeived------", response.data);

        if (response.data.code == 1 && response.data.message) {
          if (localStorage.getItem("emailId")) {
            var payload2 = {
              emailid: localStorage.getItem("emailId"),
              filename: fileName ? fileName : response.data.filename,
              summarygenerated: response.data.message,
              fileSize: localStorage.getItem("uploadedFileSize")
                ? localStorage.getItem("uploadedFileSize")
                : 0,
              filetype: type == "paste" ? "paste" : "txt",
              productid: localStorage.getItem("selectedProductId"),
              transcript: data,
            };
            const response2 = await axios.post(APIs.createAssetAPI, payload2, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response2.data.code == 1) {
              console.log("response2=====", response2);
            }
          }
          this.contentPasted = null;
          this.tempfiles = [];
          this.newAssetDialog = false;
          this.showOverAllLoader = true;

          this.getAssetListByProduct(localStorage.getItem("selectedProductId"));
        } else {
          vm.showRetryBtn = true;
        }
      } catch (error) {
        vm.showRetryBtn = true;
        console.error(error);
      }
    },
    handleFileSelection(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.tempfiles.push(file);
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const allowedExtensions = ["txt", "pdf", "doc", "docx"];
      const fileList = event.dataTransfer.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          const file = fileList[i];
          this.tempfiles.push(file);
        }
      }
      console.log("files to upload====", this.tempfiles);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    sendForSummaryGeneration(type) {
      if (type == "file") {
        this.tempFileContent = null;
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
        console.log("file====", file);
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));

        if (file) {
          if (file.type == "text/plain") {
            const reader = new FileReader();

            reader.onload = function (e) {
              const content = e.target.result;
              console.log("this content====", content); // Output the content of the file
              console.log("chracters count=====", content.length);
              vm.postData(content, "file", file.name);
              //   if (content.length > 7900) {
              //     vm.postDataUsingTurbo(content, "file", file.name);
              //   } else {
              //     vm.postData(content, "file", file.name);
              //   }
            };

            reader.readAsText(file);
          } else {
            this.showOverAllLoader = true;
            vm.showLoaderPage = true;
            vm.newAssetDialog = false;

            const formData = new FormData();
            formData.append("asset", file);

            // Make the API request using axios or any other HTTP library
            // Example using axios:
            axios
              .post(APIs.getTextAPI, formData)
              .then((response) => {
                console.log("ndg sir api response====", response.data);
                // Handle the API response as needed
                if (response.data.status == 0) {
                  vm.postData(response.data.text, "file", file.name);
                  //   if (response.data.text.length > 7900) {
                  //     vm.postDataUsingTurbo(
                  //       response.data.text,
                  //       "file",
                  //       file.name
                  //     );
                  //   } else {
                  //     vm.postData(response.data.text, "file", file.name);
                  //   }
                }
              })
              .catch((error) => {
                console.error(error);
                // Handle any errors
              });
          }
        }
      } else if (type == "paste") {
        console.log("chracters count======", vm.contentPasted.length);
        vm.postData(vm.contentPasted, "paste");
        // if (vm.contentPasted.length > 7900) {
        //   vm.postDataUsingTurbo(vm.contentPasted, "paste");
        // } else {
        //   vm.postData(vm.contentPasted, "paste");
        // }
      }
    },
    closeAssetPopup() {
      this.toggleDesignUploads = false;
      this.tempfiles = [];
      this.contentPasted = null;
      this.newAssetDialog = false;
    },
    openAssetPopup() {
      this.newAssetDialog = true;
    },
    validateProductName() {
      if (this.productName.length > 0) {
        this.productNameValidation = false;
      } else {
        this.productNameValidation = true;
      }
    },
    validateProductDescription() {
      if (this.productDescription.length > 0) {
        this.productDescriptionValidation = false;
      } else {
        this.productDescriptionValidation = true;
      }
    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];
          this.showOverAllLoader = false;
          if (response.data.length > 0) {
            //       this.activeIndex = response.data.length - 2;

            response.data.map((element) => {
              element.IsEditEnabled = false;

              if (localStorage.getItem("multiAssetIds")) {
                if (
                  localStorage.getItem("multiAssetIds").includes(element.id)
                ) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }
              } else {
                element.IsChecked = false;
              }

              this.userAssetList.push(element);
            });

            // console.log("modifiedData list======", modifiedData);

            // this.userAssetList = modifiedData;

            this.userAssetList.sort((a, b) => b.id - a.id);
            this.activeIndex = 0;
            this.fileContent = this.userAssetList[0].transcript;
            console.log(
              "msg set 1======",
              this.userAssetList[0].summarygenerated
            );

            var checkedIds = this.userAssetList
              .filter((obj) => obj.IsChecked === true)
              .map((obj) => obj.id);

            console.log("checked assets id====", checkedIds);
            localStorage.setItem("multiAssetIds", JSON.stringify(checkedIds));
            this.selectedAssetIds = checkedIds;

            this.generatedMessage = this.userAssetList[0].summarygenerated;
            this.fileName = this.userAssetList[0].filename;
            this.fileSize = this.userAssetList[0].fileSize;

            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem(
              "selectedFileType",
              this.userAssetList[0].filetype
            );
          } else {
            this.openAssetPopup();
            this.generatedMessage = null;
          }
        })
        .catch((error) => {
          this.showOverAllLoader = false;
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    getProductDetails(emailId) {
      const apiUrl = APIs.getProductsAPI;
      const email = emailId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
          },
        })
        .then((response) => {
          this.productList = [];
          console.log("product list======", response.data);

          if (response.data.length > 0) {
            if (response.data.length === 1) {
              this.selectedProduct = response.data[0].id;
            } else {
              this.selectedProduct = response.data[response.data.length - 1].id;
            }

            this.getAssetListByProduct(this.selectedProduct);

            // response.data.map((element, index) => {
            //   console.log("product index=====", index);

            //   if (index == 0 && response.data.length == 1) {
            //     this.selectedProduct = element.id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   } else {
            //     this.selectedProduct =
            //       response.data[response.data.length - 1].id;
            //     this.getAssetListByProduct(this.selectedProduct);
            //   }
            // });

            this.productList = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle any errors that occurred during the API call
        });
    },
    closePopup() {
      this.productName = null;
      this.productDescription = null;
      this.selectedFile = null;
      this.productMood = "Formal";
      this.OpenProductcreateDialog = false;
    },
    handleFileUpload(event) {
      console.log("---", event);

      const file = event.target.files[0];
      const maxSize = 4 * 1024 * 1024;

      // Check file type
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg")
      ) {
        alert("Please select a PNG or JPEG image file.");
        return;
      } else if (file.size > maxSize) {
        alert("File size exceeds the maximum limit of 4 MB.");
        return;
      } else {
        this.productFileValidation = false;
        console.log("====file=====", file);

        this.selectedFile = file;
      }
    },
    async saveProduct() {
      if (!this.productName) {
        this.productNameValidation = true;
      } else if (!this.productDescription) {
        this.productDescriptionValidation = true;
      }
      // else if (!this.selectedFile) {
      //   this.productFileValidation = true;
      // }
      else {
        this.productNameValidation = false;
        this.productDescriptionValidation = false;
        this.productFileValidation = false;
        this.createProductLoader = true;
        console.log("file===", this.selectedFile);

        const formData = new FormData();
        formData.append("name", this.productName);
        formData.append("description", this.productDescription);
        formData.append("mood", this.productMood);
        formData.append("image", this.selectedFile);
        formData.append("emailid", localStorage.getItem("emailId"));

        try {
          const response = await axios.post(APIs.createProductAPI, formData);
          console.log("product create=====", response.data);
          if (response.data.code == 1) {
            Vue.$toast.success("Product Created Succesfully..", {
              position: "top",
            });
            this.productName = null;
            this.productDescription = null;
            this.selectedFile = null;
            this.productMood = "Formal";
            this.getProductDetails(vm.EmailId);
            this.createProductLoader = false;
            this.OpenProductcreateDialog = false;
          }

          // Handle the response data here
        } catch (error) {
          console.error(error);
          // Handle any errors that occurred during the request
        }
      }
    },
    createProduct() {
      this.OpenProductcreateDialog = true;
    },
    changeActiveTab(index, asset) {
      if (asset.IsEdit && asset.filename) {
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: asset.id,
              filename: asset.filename,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              asset.IsEdit = false;
              this.activeIndex = index;
              console.log("msg set 2======", asset.summarygenerated);
              this.generatedMessage = asset.summarygenerated;
              this.fileName = asset.filename;
              this.fileSize = asset.fileSize;
              console.log("sf=========", asset);

              localStorage.setItem("selectedFileName", asset.filename);
              localStorage.setItem("selectedFileType", asset.filetype);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.activeIndex = index;
        console.log("msg set 3======", asset.summarygenerated);
        this.generatedMessage = asset.summarygenerated;
        this.fileName = asset.filename;
        this.fileSize = asset.fileSize;
        console.log("sf=========", asset);

        localStorage.setItem("selectedFileName", asset.filename);
        localStorage.setItem("selectedFileType", asset.filetype);
      }
    },
    // getAssetListByEmail(emailId) {
    //   const apiUrl =
    //     "https://catapultems.webspiders.com/energyapi/getassets.php";
    //   const email = emailId;

    //   axios
    //     .get(apiUrl, {
    //       params: {
    //         email: email,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("asset list", response.data);
    //       this.userAssetList = response.data;
    //       this.userAssetList.sort((a, b) => b.id - a.id);
    //       this.activeIndex = response.data.length - 2;
    //       this.generatedMessage =
    //         response.data[response.data.length - 1].summarygenerated;

    //       // Handle the API response data here
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       // Handle any errors that occurred during the API call
    //     });
    // },
    resetUploadedFile() {
      console.log("call");
      vm.fileName = null;
      vm.fileSize = null;
      localStorage.setItem("cancelFile", true);
      vm.$router.push("/asset");
    },
    async createPost(type, data, data2) {
      this.generateBtnClicked = true;

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);

      if (type == "Blog") {
        this.isBlogLoading = true;

        var msgBlog;
        var blogTitle;
        const header = {
          "Content-Type": "application/json",
        };

        const requestDataBlog1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestDataBlog2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createBlogAPI, requestDataBlog1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestDataBlog2 = { text: response1.data.message };
            msgBlog = response1.data.message;
            blogTitle = response1.data.title;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));
            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isBlogLoading = false;
              this.generateBtnClicked = false;

              console.log("response====", response2.data);
              var obj2 = {
                message: msgBlog,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };

              localStorage.setItem("blogmessage", msgBlog);
              localStorage.setItem("blogtitle", blogTitle);
              localStorage.setItem(
                "blogimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );
              localStorage.removeItem("linkedInmessage");
              localStorage.removeItem("linkedInimage");
              localStorage.removeItem("twittermessage");
              localStorage.removeItem("twitterimage");
              // setTimeout(() => {
              vm.$bus.emit("blogData", obj2);

              vm.$router.push("/content/blog");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      } else if (type == "Twitter") {
        this.isTwitterLoading = true;
        var msgTwitter;
        const header = {
          "Content-Type": "application/json",
        };

        const requestDataTwitter1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestDataTwitter2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createTwitterAPI, requestDataTwitter1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestDataTwitter2 = { text: response1.data.message };
            msgTwitter = response1.data.message;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));

            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isTwitterLoading = false;
              this.generateBtnClicked = false;

              console.log("response====", response2.data);
              var obj2 = {
                message: msgTwitter,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };
              localStorage.setItem("twittermessage", msgTwitter);
              localStorage.setItem(
                "twitterimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );
              localStorage.removeItem("blogmessage");
              localStorage.removeItem("blogtitle");
              localStorage.removeItem("blogimage");
              localStorage.removeItem("linkedInmessage");
              localStorage.removeItem("linkedInimage");
              // setTimeout(() => {
              vm.$bus.emit("twitterData", obj2);

              vm.$router.push("/content/twitter");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      } else {
        this.isLinkedInLoading = true;
        var msg;
        const header = {
          "Content-Type": "application/json",
        };

        const requestData1 = {
          text: summeryText,
          company: vm.companyName ? vm.companyName : "",
        };
        // var requestData2 = {
        //   /* Request payload for API 2 */
        // };

        axios
          .post(APIs.createLinkedInAPI, requestData1, header)
          .then((response1) => {
            // Handle the response of the first API call
            console.log("Response from API 1:", response1.data);
            //requestData2 = { text: response1.data.message };
            msg = response1.data.message;
            // Make the second API call
            const formData = new FormData();

            formData.append("text", "put the image in a white background");
            formData.append(
              "productid",
              localStorage.getItem("selectedProductId")
            );
            formData.append("emailid", localStorage.getItem("emailId"));

            return axios.post(APIs.createImageAPI, formData);
          })
          .then((response2) => {
            // Handle the response of the second API call
            console.log("Response from API 2:", response2.data);
            if (response2.data.code == 1) {
              this.isBlogLoading = false;
              this.isLinkedInLoading = false;
              console.log("response====", response2.data);
              var obj1 = {
                message: msg,
                image: response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url,
              };
              localStorage.setItem("linkedInmessage", msg);
              localStorage.setItem(
                "linkedInimage",
                response2.data.image.error
                  ? ""
                  : response2.data.image.data[0].url
              );

              localStorage.removeItem("linkedInPostList");
              localStorage.removeItem("blogmessage");
              localStorage.removeItem("blogtitle");
              localStorage.removeItem("blogimage");

              this.$bus.emit("linkedinData", obj1);
              vm.$router.push("/content/linkedin");
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the API calls
            console.error("Error:", error);
          });
      }
    },
  },

  created() {
    vm = this;
    //

    // this.$bus.once("generate-summary", (text) => console.log("text====", text));
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      var dropdownItems = document.getElementsByClassName("dropdown-item");

      for (var i = 0; i < dropdownItems.length; i++) {
        dropdownItems[i].addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      }
    });
    console.log("refresh====", this.$route.query.param1);

    // vm.companyName = localStorage.getItem("CompanyName");
    // vm.EmailId = localStorage.getItem("emailId");

    // if (vm.EmailId) {
    //   this.showOverAllLoader = true;
    //   this.getProductDetails(vm.EmailId);
    //   //this.getAssetListByEmail(vm.EmailId);
    // }

    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/create-product",
        query: {},
      });
      location.reload();
    }

    // if (this.$route.query.param1 == "r") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   location.reload();
    // }
    // if (this.$route.query.param1 == "p") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   location.reload();
    // }
    // if (this.$route.query.param1 == "a") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   vm.openAssetPopup();
    //   // location.reload();
    // }
    // if (this.$route.query.param1 == "pd") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   vm.createProduct();
    //   // location.reload();
    // }
  },
};
</script>
<style>
.product-image-class {
  width: 100%;
  height: 125px;
  border: 2px dashed #e3e3e3;
  border-radius: 6px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.product-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 99;
}

p.flie-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>