<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      class="app-header"
      v-if="clientName && Islogged"
    >
      <div class="header-new">
        <div class="brand-logo" @click="$router.push('/upload-file')">
          <img
            src="./assets/Images/aipostgenerator/gecko-ai.svg"
            style="width: 190px; height: 68px"
          />
        </div>

        <div class="right-login-details">
          <div class="top-header-right-options">
            <!-- <div class="word-loader">
              <p>Remaining words: <b>1840</b></p>
              <v-progress-linear value="50" rounded height="8px" color="#1eb375"></v-progress-linear>
            </div> -->
            <!-- <div class="upgrade-button d-none d-md-block mr-2 mr-md-4 ml-5">
              <v-btn rounded color="#ffedc2" elevation="0" class="upgrade-btn">Upgrade <img src="./assets/Images/aipostgenerator/crown.svg" class="ml-2"/></v-btn>
            </div> -->
            <!-- <div class="upgrade-button-mobile d-block d-md-none mr-2 mr-md-2 ml-5">
              <v-btn rounded fab color="#ffedc2" elevation="0" class="upgrade-btn" small><img src="./assets/Images/aipostgenerator/crown.svg"/></v-btn>
            </div>
            <div class="invitegecko-button mr-2 mr-md-2">
              <v-btn rounded color="#1eb375" elevation="0" class="invite-gecko-btn"><img src="./assets/Images/aipostgenerator/invite.svg" class="mr-2"/> Invite</v-btn>
            </div> -->
          </div>
          

          <v-menu offset-y z-index="99999">
            <template v-slot:activator="{ on, attrs }">
              
              <div
                class="d-inline-flex justify-content-end align-items-center user-corner"
                v-if="clientName && Islogged"
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
              >
                <div class="user-image" v-if="clientName && Islogged">
                    <img v-if="clientPhoto && clientPhoto != 'null'" :src="clientPhoto" />
                    <img v-else src="./assets/Images/aipostgenerator/avtar.png" />
                </div>
                <p>{{ clientName }}</p>
                <span
                  ><v-icon size="25px" color="#333"
                    >mdi-chevron-down</v-icon
                  ></span
                >
            </div>
              <!-- <p v-else style="cursor: pointer" @click="redrictToLogin()">
                Login
              </p> -->
            </template>
            <v-list v-if="clientName && Islogged">
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                dense
                style="cursor: pointer"
              >
                <v-icon
                  color="#585963"
                  size="20px"
                  class="mr-2"
                  v-if="item.title == 'Upload File'"
                  @click="redrictToFileUpload()"
                  >mdi-tray-arrow-up</v-icon
                >
                <v-icon
                  color="#585963"
                  size="20px"
                  class="mr-2"
                  v-else-if="item.title == 'View Assets'"
                  @click="redrictToSummary()"
                  >mdi-briefcase-eye-outline</v-icon
                >

                <v-icon v-else
                  color="#585963"
                  size="20px"
                  class="mr-2"
                  @click="logoutCall()"
                  >mdi-logout</v-icon
                >
                <v-list-item-title
                  v-if="item.title == 'View Assets'"
                  @click="redrictToSummary()"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-title
                  v-else-if="item.title == 'Upload File'"
                  @click="redrictToFileUpload()"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-title v-else @click="logoutCall()">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- <LoadingPage v-if="showLoadingPage"></LoadingPage> -->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import {auth, signOut} from "@/firebase/firebaseConfig";
// import LoadingPage from "./views/LoadingPage.vue";
// import { bus } from "@/main";
export default {
  components: {},
  name: "App",
  data() {
    return {
      clientName: null,
      clientPhoto: null,
      Islogged: false,
      items: [{ title: "Upload File" }, { title: "Logout" }],
    };
  },
  created() {},
  methods: {
    redrictToFileUpload(){
      this.$router.push("/upload-file");
    },
    redrictToSummary() {
      if (localStorage.getItem("clientName") == "Gecko") {
        this.$router.push("/assets-v2");
      } else {
        this.$router.push("/assets");
      }
    },
    redrictToLogin() {
      this.$router.push("/");
    },
    async logoutCall() {
      if (localStorage.getItem("clientName") == "Gecko") {
        this.clientName = null;
        this.Islogged = false;
        localStorage.clear();
        localStorage.removeItem("emailId");
        localStorage.removeItem("clientName");
        localStorage.setItem("isLoggedIn", false);
        this.$router.push("/v2");
      } else {

        await signOut(auth).then(()=>{
          console.log('Auth Signed Out');
          this.clientName = null;
          this.Islogged = false;

          localStorage.removeItem("emailId");
          localStorage.removeItem("clientName");
          localStorage.setItem("isLoggedIn", false);

          localStorage.clear();
          this.$router.push("/");

        })
        
      }
    },
  },
  mounted() {
    if (localStorage.getItem("clientName")) {
      this.clientName = localStorage.getItem("clientName");
    }

    if (localStorage.getItem("isLoggedIn")) {
      this.Islogged = localStorage.getItem("isLoggedIn");
    }

    if (localStorage.getItem("clientPhoto")) {
      this.clientPhoto = localStorage.getItem("clientPhoto");
    }
  },
};
</script>
<style lang="scss">
@import "./assets/aipostgenerator.css";
</style>
