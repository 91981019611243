<template>
  <div class="container">
    <div class="scan_upload upload-doc-area" v-if="!randomText">
      <v-tabs v-model="tabs" centered next-icon="mdi-arrow-right" prev-icon="mdi-arrow-left" show-arrows>
        <!-- <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-link</v-icon> Enter URL </v-tab> -->
        <!-- <v-tab class="mr-2" disabled> <v-icon color="#333" size="22px" class="mr-2">mdi-youtube</v-icon> Youtube Link
        </v-tab> -->
        <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-outline</v-icon> Upload
          Document </v-tab>
        <v-tab> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-multiple-outline</v-icon> Paste Your
          Content</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <!-- <v-tab-item>
          <v-card flat>
            <div class="scan_url-hld">

              <label>Ingest Content from Website URL <span v-if="showErrorMsg && !inputText" style="color:red">*(Please
                  enter a Website URL)</span></label>
              <input type="url" name="" id="inputValue" value="" placeholder="Enter URL Here" v-model="inputText"
                @keypress.enter="dataUrlGenerate()" v-bind:class="{ 'invalid-url': showErrorMsg && !inputText }" />
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn color="#1eb375" class="common-submit-blue-btn" @click="dataUrlGenerate()">Proceed</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI is busy<br />with other request,
                    please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item> -->
        <!-- <v-tab-item>
          <v-card flat>
            <div class="scan_url-hld">
              <label>Ingest Youtube Video Transcript</label>
              <input type="url" name="" id="" value="" placeholder="Enter URL Here" class="youtube-placeholder" />
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn disabled color="#1eb375" class="common-submit-blue-btn"
                  @click="sendForSummaryGeneration('url')">Proceed</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI is busy<br />with other request,
                    please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item> -->
        <v-tab-item>
          <v-card flat>
            <div class="create-new-project">
              <div class="new-project-card-hld file-card-hld">
                <h3>Upload Document to Ingest</h3>

                <div class="open-project-tabs" v-if="!toggleDesignUploads">
                  <div class="open-project-single-tabs" @click="
                    toggleDesignUploads = true;
                  tabs = 1;
                  ">
                    <img src="../assets/Images/aipostgenerator/upload_a_doc.png" />
                    <p>Upload a document<br /></p>
                  </div>
                </div>
                <div class="scan_upload pt-0">
                  <div class="upload_document_area">
                    <div class="upload_document_space" @dragover="handleDragOver" @dragleave="handleDragLeave"
                      @drop="handleDrop" @click="openFileExplorer">
                      <img src="../assets/Images/aipostgenerator/upload_document.png" />

                      <h3 v-if="!isDragging">
                        <a href="javascript:void(0)">Browse</a> file to upload
                      </h3>
                      <p v-else>Drop files here</p>

                      <h4>
                        Accepted file format(s): .txt,.pdf,.doc,.docx <br />
                        Max file size: 20 MB <br />
                      </h4>
                      <input ref="fileInput" type="file" style="display: none" @change="handleFileSelection"
                        id="fileInput1" accept=".txt,.pdf,.doc,.docx" />
                    </div>

                    <P v-if="tempfiles && tempfiles.length > 0">
                      <span class="d-inline-block">{{ tempfiles[0].name }}</span>
                    </P>
                    <div class="butn-tooltip-group d-flex justify-center align-items-center">
                      <v-btn v-if="tempfiles && tempfiles.length > 0"
                        :disabled="tempfiles && tempfiles.length > 0 ? false : true" color="#1eb375"
                        class="common-submit-blue-btn" @click="uploadFileAsset('file')">Generate Content</v-btn>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                            style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                        </template>
                        <span>Look's like our AI is busy<br />with other request, please<br />click
                          here to retry</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            
            <div class="upload_document_area">
              <div class="post-content-txt-area">
                

                <label>Paste Text Content (up to 5000 words)</label>
                <textarea v-bind:class="{ 'invalid-url': wordCount > 5000 }" cols="12" rows="12"
                  placeholder="Paste your content here..." v-model="contentPasted" @input="updateWordCount"></textarea>
                <p>Word Count: {{ wordCount }} / {{ maxWords }}</p>
              </div>
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn v-if="contentPasted" :disabled="contentPasted && wordCount <= 5000 ? false : true" color="#1eb375"
                  class="common-submit-blue-btn" @click="createAssetByPastedContent()">Proceed</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI system is busy<br />with other
                    request, please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div :class="randomText ? 'blog-blank-screen' : ''" v-if="randomText">
      <img v-if="randomText" src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
      <h3 class="mt-3">
        <span>{{ randomText }}</span>
      </h3>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
// import {eventBus} from '@/main'
Vue.use(VueBus);
Vue.use(VueToast);
var vm = this;
export default {
  components: {},
  props: {
    msg: String,
    // msg:{
    //     required:true,
    //     type: "string"
    // }
  },
  data() {
    return {
      maxWords: 5000,
      wordCount: 0,
      showErrorMsg: false,
      selectedAssetIds: [],
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      selectedTab: "Transcript",
      searchTerm: "",
      tempFileContent: null,
      toggleDesignUploads: true,
      tabs: 0,
      userAssetList: [],
      generatedMessage: null,
      isBlogLoading: false,
      isLinkedInLoading: false,
      fileName: null,
      fileSize: null,
      generateBtnClicked: false,
      companyName: null,
      isTwitterLoading: false,
      EmailId: null,
      activeIndex: 0,
      OpenProductcreateDialog: false,
      productMood: "Formal",
      productDescription: "",
      productName: "",
      selectedFile: null,
      productList: [],
      createProductLoader: false,
      productNameValidation: false,
      productDescriptionValidation: false,
      productFileValidation: false,
      showOverAllLoader: false,
      newAssetDialog: false,
      isDragging: false,
      tempfiles: [],
      contentPasted: null,
      makeEdit: true,
      PostOptionOpen: false,
      fileContent: null,
      inputText: "",
      isValidURL: true,
      loading: false,
      randomText: "",
    };
  },
  watch: {
  },
  computed: {
    filteredAssetList() {
      return this.userAssetList.filter((asset) =>
        asset.filename.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    async createCategory(){
      try {
        const apiUrl = APIs.createCategoryAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          "categoryName": ["Default"],
        };

        const response = await axios.post(apiUrl, data, { headers });
        console.log("response=====",response);

        // if (response.data.status == 0) {
        //   this.viewAssetContent = response.data.data.assetContents;
        //   this.fileViewAssetDialog = true;
        // }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    updateWordCount() {
      const words = this.contentPasted.trim().split(/\s+/);
      this.wordCount = words.length;

      // Truncate text to 5000 words
      // if (this.wordCount > this.maxWords) {
      //   const truncatedWords = words.slice(0, this.maxWords);
      //   this.contentPasted = truncatedWords.join(' ');
      // }
    },
    makeProperUrl(inputUrl) {
      // Add "http://" if not already present
      if (!/^https?:\/\//i.test(inputUrl)) {
        inputUrl = "http://" + inputUrl;
      }

      // Check if domain name is missing, and add ".com" by default
      const urlParts = inputUrl.split('/');
      const domain = urlParts[2];
      if (!domain) {
        inputUrl += "e2m.live.com";
      }

      // Replace spaces with "%20"
      inputUrl = inputUrl.replace(/\s/g, '%20');

      return inputUrl;
    },
    createAssetByPastedContent() {
      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);
      this.loading = true;
      axios
        .post(
          APIs.getAssetIdByPastedContent,
          {
            context: this.contentPasted,
            categoryId: "1",
            hasQnA: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          if (response.data.status == 0) {
            this.loading = false;
            localStorage.setItem("latestAssetName", response.data.data.assetName);
            localStorage.setItem("latestAssetId", response.data.data.assetId);
            this.$router.push("/content-v2/twitter")
          }
        })
        .catch((error) => {
          // Handle the error
          this.loading = false;
          console.error(error);
        });
    },
    generateRandomText() {
      const texts = [
        "Unleashing AI Creativity",
        "Transforming Data into Posts",
        "Polishing Your Brand Voice",
        "Infusing Intelligence into Copy",
        "Preparing the Content Canvas",
        "Transforming Insights into Posts"
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },

    dataUrlGenerate() {
      if (this.inputText) {
        this.loading = true;
        this.showErrorMsg = false;
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        this.isValidURL = urlRegex.test(this.inputText);

        this.generateRandomText();

        setInterval(this.generateRandomText, 6000);


        if (urlRegex.test(this.inputText)) {
          axios
            .post(
              APIs.getAssetIdByUrl,
              {
                url: this.inputText,
                categoryId: "1",
                hasQnA: true,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.status == 0) {
                this.loading = false;
                localStorage.setItem("latestAssetId", response.data.data.assetId);
                localStorage.setItem("validURL", this.inputText);
                this.$router.push("/content-v2/twitter")
              }
            })
            .catch((error) => {
              // Handle the error
              this.loading = false;
              console.error(error);
            });
          // Save the valid URL in local storage
        } else {
          axios
            .post(
              APIs.getAssetIdByUrl,
              {
                url: this.makeProperUrl(this.inputText),
                categoryId: "1",
                hasQnA: true,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.status == 0) {
                this.loading = false;
                localStorage.setItem("latestAssetId", response.data.data.assetId);
                localStorage.setItem("validURL", this.inputText);
                this.$router.push("/content-v2/blog")
              }
            })
            .catch((error) => {
              // Handle the error
              this.loading = false;
              console.error(error);
            });
        }
      } else {
        this.showErrorMsg = true;
      }
    },
    uploadFileAsset(type) {
      if (type == "file") {
        console.log("axios====")
        this.generateRandomText();

        setInterval(this.generateRandomText, 6000);
        this.loading = true;
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
      
        


        const formData = new FormData();
        formData.append("asset", file);
        formData.append("categoryId", "1");
        formData.append("hasQnA", true);

        const accessToken = localStorage.getItem("accessToken"); // Replace with your actual Bearer token

        // Set up the headers with the Bearer token
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          // Add any other headers you may need here
        };

        // Make the API request using axios or any other HTTP library
        // Example using axios:
        

        axios
          .post(APIs.getAssetIdByFileUpload, formData, { headers })
          .then((response) => {
            console.log("ndg sir api response====", response.data);
            this.loading = false;
            if (response.data.status == 0) {
              localStorage.setItem("latestAssetName", response.data.data.assetName);
              localStorage.setItem("latestAssetId", response.data.data.assetId);
              this.$router.push("/content-v2/twitter")
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
            // Handle any errors
          });
      }
    },
    selectAssets() {
      var checkedIds = this.userAssetList
        .filter((obj) => obj.IsChecked === true)
        .map((obj) => obj.id);

      console.log("checked assets id====", checkedIds);
      localStorage.setItem("multiAssetIds", JSON.stringify(checkedIds));
      this.selectedAssetIds = checkedIds;
    },
    cancelFileNameChange() {
      this.tempFileName = null;
      this.tempAssetEditId = null;
      this.fileEditNameDialog = false;
    },
    goToBlog(data, data2) {
      localStorage.removeItem("blogPostList");
      localStorage.removeItem("blogmessage");
      localStorage.removeItem("blogtitle");
      localStorage.removeItem("blogimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/blog");
    },
    goToLinkedIn(data, data2) {
      localStorage.removeItem("linkedInPostList");
      localStorage.removeItem("linkedInmessage");
      localStorage.removeItem("linkedInimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/linkedin");
    },
    goToTwitter(data, data2) {
      localStorage.removeItem("twitterPostList");
      localStorage.removeItem("twittermessage");
      localStorage.removeItem("twitterimage");

      var summeryText = data2 ? data2 : data;
      localStorage.setItem("generatedSummary", summeryText);
      vm.$router.push("/content-v2/twitter");
    },
    OpenPostOption() {
      this.PostOptionOpen = true;
    },

    editAssets(index, asset) {
      console.log("asset====", asset);
      console.log("asset====", asset);
      console.log("asset====", asset);
      this.tempFileName = asset.filename;
      this.tempAssetEditId = asset.id;
      this.fileEditNameDialog = true;
      const element = document.getElementById("temp-file-edit");
      element.focus();

      // this.makeEdit = false;
      // const element = document.getElementById("MakeFocus" + index);
      // if (element) {
      //   setTimeout(() => {
      //     element.focus();
      //     asset.IsEdit = true;
      //   }, 500);
      // }
    },
    handleFileSelection(e) {
      const files = e.target.files;
      var maxFileSize = 20 * 1024 * 1024;
      if (files.length > 0) {
        var fileSize = files[0].size;
        if (fileSize > maxFileSize) {
          alert("File size exceeds the maximum limit of 20 MB.");
          event.preventDefault(); // Prevent form submission
        } else {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.tempfiles.push(file);
          }
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const allowedExtensions = ["txt", "pdf", "doc", "docx"];
      const fileList = event.dataTransfer.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          const file = fileList[i];
          this.tempfiles.push(file);
        }
      }
      console.log("files to upload====", this.tempfiles);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    closeAssetPopup() {
      this.toggleDesignUploads = false;
      this.tempfiles = [];
      this.contentPasted = null;
      this.newAssetDialog = false;
    },
    openAssetPopup() {
      this.newAssetDialog = true;
    },
    validateProductName() {
      if (this.productName.length > 0) {
        this.productNameValidation = false;
      } else {
        this.productNameValidation = true;
      }
    },
    validateProductDescription() {
      if (this.productDescription.length > 0) {
        this.productDescriptionValidation = false;
      } else {
        this.productDescriptionValidation = true;
      }
    },
    closePopup() {
      this.productName = null;
      this.productDescription = null;
      this.selectedFile = null;
      this.productMood = "Formal";
      this.OpenProductcreateDialog = false;
    },
    handleFileUpload(event) {
      console.log("---", event);

      const file = event.target.files[0];
      const maxSize = 4 * 1024 * 1024;

      // Check file type
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg")
      ) {
        alert("Please select a PNG or JPEG image file.");
        return;
      } else if (file.size > maxSize) {
        alert("File size exceeds the maximum limit of 4 MB.");
        return;
      } else {
        this.productFileValidation = false;
        console.log("====file=====", file);

        this.selectedFile = file;
      }
    },
    createProduct() {
      this.OpenProductcreateDialog = true;
    },
    changeActiveTab(index, asset) {
      if (asset.IsEdit && asset.filename) {
        axios
          .post(
            APIs.editFileNameAPI,
            {
              id: asset.id,
              filename: asset.filename,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the response data
            console.log(response.data);
            if (response.data.code == 1) {
              asset.IsEdit = false;
              this.activeIndex = index;
              console.log("msg set 2======", asset.summarygenerated);
              this.generatedMessage = asset.summarygenerated;
              this.fileName = asset.filename;
              this.fileSize = asset.fileSize;
              console.log("sf=========", asset);

              localStorage.setItem("selectedFileName", asset.filename);
              localStorage.setItem("selectedFileType", asset.filetype);
            }
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      } else {
        this.activeIndex = index;
        console.log("msg set 3======", asset.summarygenerated);
        this.generatedMessage = asset.summarygenerated;
        this.fileName = asset.filename;
        this.fileSize = asset.fileSize;
        console.log("sf=========", asset);

        localStorage.setItem("selectedFileName", asset.filename);
        localStorage.setItem("selectedFileType", asset.filetype);
      }
    },
    resetUploadedFile() {
      console.log("call");
      vm.fileName = null;
      vm.fileSize = null;
      localStorage.setItem("cancelFile", true);
      vm.$router.push("/asset");
    },
  },

  created() {
    vm = this;
  },

  mounted() {
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/upload-file",
        query: {},
      });
      location.reload();
    }
    document.addEventListener("DOMContentLoaded", function () {
      var dropdownItems = document.getElementsByClassName("dropdown-item");

      for (var i = 0; i < dropdownItems.length; i++) {
        dropdownItems[i].addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      }
    });
    console.log("refresh====", this.$route.query.param1);
    this.createCategory();

    // vm.companyName = localStorage.getItem("CompanyName");
    // vm.EmailId = localStorage.getItem("emailId");

    // if (vm.EmailId) {
    //   this.showOverAllLoader = true;
    //   this.getProductDetails(vm.EmailId);
    //   //this.getAssetListByEmail(vm.EmailId);
    // }



    // if (this.$route.query.param1 == "r") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   location.reload();
    // }
    // if (this.$route.query.param1 == "p") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   location.reload();
    // }
    // if (this.$route.query.param1 == "a") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   vm.openAssetPopup();
    //   // location.reload();
    // }
    // if (this.$route.query.param1 == "pd") {
    //   this.$router.push({
    //     path: "/assets-v2",
    //     query: {},
    //   });
    //   vm.createProduct();
    //   // location.reload();
    // }
  },
};
</script>
<style>
.product-image-class {
  width: 100%;
  height: 125px;
  border: 2px dashed #e3e3e3;
  border-radius: 6px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.product-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 99;
}

p.flie-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>