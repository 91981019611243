<template>
  <div class="myproject-wrap">
    <h2 v-if="!showLoaderPage && !showSummary">Your Marketing AI Co-pilot</h2>
    <div class="project-blank-img-hld" v-if="!showLoaderPage && !showSummary">
      <img src="../assets/Images/aipostgenerator/my-project-blackimage.png" />
    </div>
    <h4 v-if="!showLoaderPage && !showSummary">
      You're all set to start creating projects! Get started from scratch or
      choose from our template list.
    </h4>
    <div
      class="butn-tooltip-group d-flex justify-content-center align-items-center"
    >
      <v-btn
        v-if="!showLoaderPage && !showSummary"
        @click="OpenProjectCreate1()"
        depressed
        color="#1eb375"
        class="project-begin"
        >Begin Now</v-btn
      >
      <!-- <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center" style=""><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
        </template>
        <span>Look's like our AI is busy<br/>with other request, please<br/>click here to retry</span>
      </v-tooltip> -->
    </div>
    <v-dialog
      v-model="OpenMyProjectDialog"
      transition="dialog-bottom-transition"
      max-width="790"
    >
      <template>
        <v-card>
          <div class="create-new-project">
            <div class="new-project-card-hld">
              <div class="create-project-header">
                <div>
                  <h3 v-if="ShowCompanyDetails">Create new account</h3>
                  <h3 v-else>Upload Asset / Paste text to Ingest</h3>

                  <p class="mb-0" v-if="ShowCompanyDetails">
                    We collect this information to improve your experience and
                    provide you with personalized content.
                  </p>
                </div>

                <v-btn @click="closePopup()" fab small elevation="0"
                  ><v-icon size="24px" color="#5a646e">mdi-close</v-icon></v-btn
                >
              </div>
              <div v-if="ShowCompanyDetails" class="text-center">
                <!-- <v-form @submit.prevent> -->

                <div class="about_company_form">
                  <v-text-field
                    label="Company Name"
                    v-model="companyName"
                  ></v-text-field>

                  <span class="validation-msg" v-if="showCompanyNameValidation"
                    >You must enter a Company Name.</span
                  >

                  <v-textarea
                    v-model="aboutCompany"
                    auto-grow
                    label="About the Company"
                    rows="3"
                    row-height="20"
                  ></v-textarea>
                  <span
                    class="validation-msg"
                    v-if="showCompanyDetailsValidation"
                    >You must enter about the Company.</span
                  >

                  <!-- <div
                    class="mt-4 d-flex justify-content-start align-items-center"
                  >
                    <v-text-field
                      label="Email Address"
                      v-model="emailId"
                      class="mr-3"
                    ></v-text-field>
                    <v-text-field
                      label="Password"
                      v-model="password"
                    ></v-text-field>
                  </div> -->
                  <!-- <div class="company-mood">

                    <div class="tabs">
                      <label class="tab">
                        <input type="radio" name="tab-input" class="tab-input">
                        <div class="tab-box">Formal</div>
                      </label>
                      <label class="tab">
                        <input type="radio" name="tab-input" class="tab-input">
                        <div class="tab-box">Bold</div>
                      </label>
                      <label class="tab">
                        <input type="radio" name="tab-input" class="tab-input">
                        <div class="tab-box">Cheeky</div>
                      </label>
                      <label class="tab">
                        <input type="radio" name="tab-input" class="tab-input">
                        <div class="tab-box">Happy</div>
                      </label>
                    </div>
                  </div> -->
                </div>

                <div
                  class="butn-tooltip-group d-flex justify-center align-items-center flex-row flex-md-row"
                >
                  <v-btn
                    color="#1eb375"
                    class="common-submit-blue-btn mr-2 mr-md-2 mb-md-0 mb-0"
                    @click="saveCompanyInfo()"
                    >Save & Continue
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="common-submit-blue-btn skip-btn"
                    @click="fileUploadFormInitiate()"
                    >Skip & Continue
                    <v-icon size="30px" color="#fff"
                      >mdi-arrow-right-thin</v-icon
                    ></v-btn
                  >
                  <!-- </v-form> -->
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-content-start align-items-center"
                        style="visibility: hidden"
                        ><v-icon size="25px" color="#333"
                          >mdi-refresh</v-icon
                        ></span
                      >
                    </template>
                    <span
                      >Look's like our AI is busy<br />with other request,
                      please<br />click here to retry</span
                    >
                  </v-tooltip>
                </div>
              </div>
              <div
                class="open-project-tabs"
                v-if="!toggleDesignUploads && !ShowCompanyDetails"
              >
                <div class="open-project-single-tabs">
                  <img
                    src="../assets/Images/aipostgenerator/scanwebsite-url.png"
                  />
                  <p>Scan a website URL</p>
                  <v-chip class="upcoming-badge" color="#1eb375"
                    >Upcoming</v-chip
                  >
                </div>
                <div
                  class="open-project-single-tabs"
                  @click="
                    toggleDesignUploads = true;
                    tabs = 1;
                  "
                >
                  <img
                    src="../assets/Images/aipostgenerator/upload_a_doc.png"
                  />
                  <p>Upload a document<br />(.txt)</p>
                </div>
                <div
                  class="open-project-single-tabs"
                  @click="
                    toggleDesignUploads = true;
                    tabs = 2;
                  "
                >
                  <img
                    src="../assets/Images/aipostgenerator/paste_content.png"
                  />
                  <p>Paste your content</p>
                </div>
              </div>
              <div class="scan_upload" v-if="toggleDesignUploads">
                <v-tabs
                  v-model="tabs"
                  centered
                  next-icon="mdi-arrow-right"
                  prev-icon="mdi-arrow-left"
                  show-arrows
                >
                  <v-tab class="mr-2" disabled> Enter URL </v-tab>

                  <v-tab class="mr-2"> Upload Document </v-tab>
                  <v-tab> Paste Your Content</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                  <v-tab-item>
                    <v-card flat>
                      <div class="scan_url-hld">
                        <input
                          type="url"
                          name=""
                          id=""
                          value=""
                          placeholder="Enter URL Here"
                        />
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn
                            disabled
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('url')"
                            >Upload Asset</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <div class="upload_document_area">
                        <!-- <div class="upload_document_space">
                          <img
                            src="../assets/Images/aipostgenerator/upload_document.png"
                          />
                          <h3>
                            Drag and drop your file here! or
                            <a href="">browse</a>
                          </h3>
                          <p>Accepted file formats: .txt Max file size 5 MB</p>
                        </div> -->

                        <div
                          class="upload_document_space"
                          @dragover="handleDragOver"
                          @dragleave="handleDragLeave"
                          @drop="handleDrop"
                          @click="openFileExplorer"
                        >
                          <img
                            src="../assets/Images/aipostgenerator/upload_document.png"
                          />

                          <h3 v-if="!isDragging">
                            Drag and drop files here or click to
                            <a href="javascript:void(0)">browse</a>
                          </h3>
                          <p v-else>Drop files here</p>

                          <h4>Accepted file format(s): .txt <br/> Max file size: 5 MB <br/> Coming Soon: .pdf .doc .docx</h4>
                          <input
                            ref="fileInput"
                            type="file"
                            style="display: none"
                            @change="handleFileSelection"
                            accept=".txt"
                            id="fileInput1"
                          />
                        </div>

                        <P v-if="tempfiles && tempfiles.length > 0"
                          ><img
                            src="../assets/Images/aipostgenerator/textIcon.svg"
                            height="25px"
                            width="25px"
                            class="mr-2"
                          /><span class="d-inline-block">{{
                            tempfiles[0].name
                          }}</span></P
                        >
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn v-if="tempfiles && tempfiles.length > 0"
                            :disabled="
                              tempfiles && tempfiles.length > 0 ? false : true
                            "
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('file')"
                            >Upload Asset</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <div class="upload_document_area">
                        <div class="post-content-txt-area">
                          <textarea
                            cols="12"
                            rows="12"
                            placeholder="Paste your content here..."
                            v-model="contentPasted"
                          ></textarea>
                        </div>
                        <div
                          class="butn-tooltip-group d-flex justify-center align-items-center"
                        >
                          <v-btn v-if="contentPasted"
                            :disabled="contentPasted ? false : true"
                            color="#1eb375"
                            class="common-submit-blue-btn"
                            @click="sendForSummaryGeneration('paste')"
                            >Upload Asset</v-btn
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="d-flex justify-content-start align-items-center"
                                style="visibility: hidden"
                                ><v-icon size="25px" color="#333"
                                  >mdi-refresh</v-icon
                                ></span
                              >
                            </template>
                            <span
                              >Look's like our AI system is busy<br />with other
                              request, please<br />click here to retry</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>

    <div class="loading-section-hld" v-if="showLoaderPage">
      <!-- <img src="../assets/Images/aipostgenerator/upload_a_doc.png" /> -->
      <!-- <v-progress-linear
        rounded
        color="#1eb375"
        height="10"
        :value=""
        class="mt-5"
      ></v-progress-linear> -->
      <v-progress-circular
        :rotate="360"
        :size="120"
        :width="10"
        :value="progressVal"
        color="#1eb375"
      >
        <h4>{{ progressVal }} %</h4>
      </v-progress-circular>
      <!-- <h3 class="slider-value"></h3> -->
      <h3 v-if="progressVal >= 0 && progressVal < 30">Making the dough...</h3>
      <h3 v-if="progressVal >= 30 && progressVal < 70">
        Preparing the sauce and toppings...
      </h3>
      <h3 v-if="progressVal >= 70 && progressVal < 100">
        Baking in progress...
      </h3>

      <v-tooltip right v-if="showRetryBtn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="postData()"
            v-bind="attrs"
            v-on="on"
            color="#1eb375"
            class="common-submit-blue-btn"
            :loading="retryBtnLoader"
            >Retry <v-icon size="30px" color="#fff">mdi-refresh</v-icon></v-btn
          >
        </template>
        <span
          >Look's like our AI is busy<br />with other request, please<br />click
          here to retry</span
        >
      </v-tooltip>
    </div>
    <SummaryGenerated :msg="generatedSummary" v-if="generatedSummary" />

    <v-dialog
      v-model="OpenProductcreateDialog"
      transition="dialog-bottom-transition"
      max-width="790"
    >
      <template>
        <v-card>
          <div class="create-new-project">
            <div class="new-project-card-hld">
              <div class="create-project-header">
                <div>
                  <h3>Create new Product / Service Category</h3>

                  <p class="mb-0">
                    We collect this information to improve your experience and
                    provide you with personalized content.
                  </p>
                </div>

                <v-btn @click="closeProductPopup()" fab small elevation="0"
                  ><v-icon size="24px" color="#5a646e">mdi-close</v-icon></v-btn
                >
              </div>
              <div class="text-center">
                <!-- <v-form @submit.prevent> -->

                <div class="about_company_form">
                  <v-text-field
                    label="Product Name*"
                    v-model="productName"
                    @change="validateProductName()"
                  ></v-text-field>

                  <span class="validation-msg" v-if="productNameValidation"
                    >You must enter a product name.</span
                  >

                  <v-textarea
                    v-model="productDescription"
                    auto-grow
                    label="About the Product*"
                    rows="3"
                    row-height="20"
                    @change="validateProductDescription()"
                  ></v-textarea>

                  <span
                    class="validation-msg"
                    v-if="productDescriptionValidation"
                    >You must enter a product description.</span
                  >
                  
                  <div class="product-image-class flex-column d-none">
                    <div class="d-flex justify-content-between align-items-center flex-md-row flex-column">
                      <v-icon class="mr-2">mdi-tray-arrow-up</v-icon>
                      <h4>Upload Product Image*</h4>
                    </div>
                    <p>Accepted file format(s): .png,.jpeg <br/> Max file size: 4 MB</p>

                    <input
                      type="file"
                      class="product-upload"
                      @change="handleFileUpload"
                      ref="fileInput1"
                    />
                  </div>
                  <P
                    v-if="selectedFile && selectedFile.name"
                    class="file-list"
                    style="
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    <img
                      src="../assets/Images/aipostgenerator/ico-img.png"
                      height="25px"
                      width="25px"
                      class="mr-2"
                    /><span class="d-inline-block">{{
                      selectedFile.name
                    }}</span></P
                  >
                  <span class="validation-msg" v-if="productFileValidation"
                    >You must upload a product image.</span
                  >

                  
                  <div class="company-mood d-none">
                    <h4 style="text-align: left" class="mb-3">
                      Choose the tone and style of your content
                    </h4>

                    <div class="tabs">
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Formal"
                          v-model="productMood"
                        />
                        <div class="tab-box">Formal</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Bold"
                          v-model="productMood"
                        />
                        <div class="tab-box">Bold</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Cheeky"
                          v-model="productMood"
                        />
                        <div class="tab-box">Cheeky</div>
                      </label>
                      <label class="tab">
                        <input
                          type="radio"
                          name="tab-input"
                          class="tab-input"
                          value="Happy"
                          v-model="productMood"
                        />
                        <div class="tab-box">Happy</div>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  class="butn-tooltip-group d-flex justify-center align-items-center flex-row flex-md-row"
                >
                  <v-btn large elevation="0"
                    dense
                    color="#dfdfdf"
                    class="common-submit-blue-btn product-cancel mr-2 mr-md-2 mb-md-0 mb-0"
                    @click="closeProductPopup()"
                    >Cancel
                  </v-btn>
                  <v-btn large
                    :loading="createProductLoader"
                    color="#1eb375"
                    class="common-submit-blue-btn mr-0 mb-md-0"
                    @click="saveProduct()"
                    >Save
                  </v-btn>
                  <!-- </v-form> -->
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-content-start align-items-center"
                        style="visibility: hidden"
                        ><v-icon size="25px" color="#333"
                          >mdi-refresh</v-icon
                        ></span
                      >
                    </template>
                    <span
                      >Look's like our AI is busy<br />with other request,
                      please<br />click here to retry</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
var vm;
//import { bus } from "@/main";
import Vue from "vue";
import VueBus from "vue-bus";
import SummaryGenerated from "@/views/SummaryGenerated.vue";
import APIs from "@/components/APIInit";

Vue.use(VueBus);
import axios from "@/axios.js";
export default {
  props: {},
  components: {
    SummaryGenerated,
  },
  data() {
    return {

      createProductLoader: false,
      productMood: "Formal",
      selectedFile: null,
      productFileValidation: false,
      productDescriptionValidation: false,
      productDescription: "",
      productNameValidation: false,
      productName: "",
      OpenProductcreateDialog: false,
      OpenMyProjectDialog: false,
      ShowCompanyDetails: false,
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      toggleDesignUploads: false,
      isDragging: false,
      tempfiles: [],
      tempFileContent: null,
      companyName: null,
      aboutCompany: null,
      showLoaderPage: false,
      progressVal: 0,
      intervalId: null,
      generatedSummary: null,
      generatedFileName: null,
      showSummary: false,
      contentPasted: null,
      showCompanyNameValidation: false,
      showCompanyDetailsValidation: false,
      showRetryBtn: false,
      storeDataForRetry: null,
      retryBtnLoader: false,
      emailId: null,
      password: null,
    };
  },
  computed: {},
  created() {
    vm = this;
  },
  mounted() {

    if (localStorage.getItem("cancelFile") == "true") {
      vm.OpenMyProjectDialog = true;
      localStorage.setItem("cancelFile", false);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId); // Clear the interval when the component is destroyed
  },
  watch: {
    companyName() {
      if (this.companyName) {
        this.showCompanyNameValidation = false;
      } else {
        if (this.companyName != "") {
          this.showCompanyNameValidation = true;
        }
      }
    },
    aboutCompany() {
      console.log("wt====", this.aboutCompany);

      if (this.aboutCompany) {
        this.showCompanyDetailsValidation = false;
      } else {
        if (this.aboutCompany != "") {
          this.showCompanyDetailsValidation = true;
        }
      }
    },
  },
  methods: {
    async saveProduct() {
      if (!this.productName) {
        this.productNameValidation = true;
      } else if (!this.productDescription) {
        this.productDescriptionValidation = true;
      } 
      // else if (!this.selectedFile) {
      //   this.productFileValidation = true;
      // } 
      else {
        this.productNameValidation = false;
        this.productDescriptionValidation = false;
        this.productFileValidation = false;
        this.createProductLoader = true;
        console.log("file===", this.selectedFile);

        const formData = new FormData();
        formData.append("name", this.productName);
        formData.append("description", this.productDescription);
        formData.append("mood", this.productMood);
        formData.append("image", this.selectedFile);
        formData.append("emailid", localStorage.getItem("emailId"));


        try {
          const response = await axios.post(
            APIs.createProductV2API,
            formData
          );
          console.log("product create=====", response.data);
          if (response.data.code == 1) {
            Vue.$toast.success("Product Created Succesfully..", {
              position: "top",
            });
            this.productName = null;
            this.productDescription = null;
            this.selectedFile = null;
            this.productMood = "Formal";
            // this.getProductDetails(vm.EmailId);
            this.createProductLoader = false;
            this.OpenProductcreateDialog = false;
            // vm.$router.push("/asset");
            
            this.$router.push({
              path: "/assets",
              query: { param1: "p" },
            });

          }

          // Handle the response data here
        } catch (error) {
          console.error(error);
          // Handle any errors that occurred during the request
        }
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const maxSize = 4 * 1024 * 1024;

      // Check file type
      if (
        !file.type.includes("image/png") &&
        !file.type.includes("image/jpeg")
      ) {
        alert("Please select a PNG or JPEG image file.");
        return;
      } else if (file.size > maxSize) {
        alert("File size exceeds the maximum limit of 4 MB.");
        return;
      } else {
        this.productFileValidation = false;
        console.log("====file=====", file.name);

        this.selectedFile = file;
      }
    },
    validateProductDescription() {
      if (this.productDescription.length > 0) {
        this.productDescriptionValidation = false;
      } else {
        this.productDescriptionValidation = true;
      }
    },
    validateProductName() {
      if (this.productName.length > 0) {
        this.productNameValidation = false;
      } else {
        this.productNameValidation = true;
      }
    },
    closeProductPopup() {
      this.productName = null;
      this.productDescription = null;
      this.selectedFile = null;
      this.productMood = "Formal";
      this.OpenProductcreateDialog = false;
    },
    closePopup() {
      vm.OpenMyProjectDialog = false;
      vm.tempfiles = [];
      vm.contentPasted = null;
    },
    saveCompanyInfo() {
      if (!this.companyName) {
        this.showCompanyNameValidation = true;
      } else {
        this.showCompanyNameValidation = false;
      }
      if (!this.aboutCompany) {
        this.showCompanyDetailsValidation = true;
      } else {
        this.showCompanyDetailsValidation = false;
      }

      if (this.companyName && this.aboutCompany) {
        localStorage.setItem("CompanyName", this.companyName);
        localStorage.setItem("CompanyAbout", this.aboutCompany);
        this.showCompanyNameValidation = false;
        this.showCompanyDetailsValidation = false;
        vm.ShowCompanyDetails = false;
      }
    },
    fileUploadFormInitiate() {
      vm.ShowCompanyDetails = false;
      vm.toggleDesignUploads = false;
    },
    incrementCounter() {
      if (this.progressVal >= 99) {
        clearInterval(this.intervalId); // Stop the interval when counter reaches 100
        return;
      }
      if (this.progressVal >= 90) {
        this.progressVal += 9;
      } else {
        this.progressVal += 10;
      }
    },
    async postData(data, type,fileName) {
      vm.showLoaderPage = true;
      vm.OpenMyProjectDialog = false;
      vm.storeDataForRetry = data;

      // setTimeout(() => {
      //   this.updateProgress();
      // }, 1000);
      this.intervalId = setInterval(this.incrementCounter, 1000);
      var payload;
      if (vm.showRetryBtn) {
        vm.retryBtnLoader = true;
        payload = { text: vm.storeDataForRetry, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      } else {
        payload = { text: data, company: localStorage.getItem("emailId").includes("gecko") ? "gecko" :"energyrecovery.com" };
      }

      try {
        const response = await axios.post(
          APIs.postSummaryAPI,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("summary reeived------", response.data);

        if (response.data.code == 1 && response.data.message) {
          if (localStorage.getItem("emailId")) {
            var payload2 = {
              emailid: localStorage.getItem("emailId"),
              filename: fileName ? fileName : response.data.filename,
              summarygenerated: response.data.message,
              fileSize: localStorage.getItem("uploadedFileSize") ? localStorage.getItem("uploadedFileSize") : 0,
              filetype: type == "paste" ? "paste" : "txt",
              productid: localStorage.getItem("selectedProductId"),
              transcript: data
            };
            const response2 = await axios.post(
              APIs.createAssetAPI,
              payload2,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response2.data.code == 1) {
              console.log("response2=====", response2);
            }
          }

          console.log("response====", response.data);
          //bus.$emit("generatedSummary", response.data.message)
          vm.generatedSummary = response.data.message;
          vm.generatedFileName = response.data.filename;
          localStorage.setItem("generatedSummary", vm.generatedSummary);
          localStorage.setItem("generatedFileName", vm.generatedFileName);
          
          vm.showRetryBtn = false;
          vm.retryBtnLoader = false;
          vm.showLoaderPage = false;
          vm.showSummary = true;
          //vm.$bus.on('generate-summary', response.data.message);
          vm.$bus.emit("generate-summary", { text: response.data.message });
          // vm.$router.push("/summary");

          // vm.$router.push({ path: 'SummaryGenerated', query: { message: response.data.message }})
        } else {
          console.log("else------");

          vm.showRetryBtn = true;
        }
        // Handle the response data as needed
      } catch (error) {
        vm.showRetryBtn = true;
        console.error(error);
        // Handle the error
      }
    },
    OpenProjectCreate1() {
      this.OpenProductcreateDialog = true;


      // vm.toggleDesignUploads = false;
      // vm.OpenMyProjectDialog = true;
      // vm.ShowCompanyDetails = false;
      // vm.tempfiles = [];
      // this.showCompanyNameValidation = false;
      // this.showCompanyDetailsValidation = false;

      // console.log(localStorage.getItem("CompanyName"));

      // if (localStorage.getItem("CompanyName") == "null") {
      //   vm.companyName = "";
      //   this.showCompanyNameValidation = false;
      // } else {
      //   vm.companyName = localStorage.getItem("CompanyName");
      // }

      // if (localStorage.getItem("CompanyAbout") == "null") {
      //   vm.aboutCompany = "";

      //   this.showCompanyDetailsValidation = false;
      // } else {
      //   vm.aboutCompany = localStorage.getItem("CompanyAbout");
      // }
    },
    sendForSummaryGeneration(type) {
      if (type == "file") {
        this.tempFileContent = null;
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
        console.log("file====", file);
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));

        if (file) {
          const reader = new FileReader();

          reader.onload = function (e) {
            const content = e.target.result;
            console.log("this content====", content); // Output the content of the file
            vm.postData(content, "file",file.name);
          };

          reader.readAsText(file);
        }
      } else if (type == "paste") {
        vm.postData(vm.contentPasted, "paste");
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const allowedExtensions = ["txt"];
      const fileList = event.dataTransfer.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          const file = fileList[i];
          this.tempfiles.push(file);
        }
      }
      console.log("files to upload====", this.tempfiles);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    handleFileSelection(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.tempfiles.push(file);
      }

      //this.uploadFiles(files);
    },
  },
};
</script>

<style>
.upload_document_space h4{
  font-weight: 300;
    font-size: 14px;
    font-family: "Poppins",sans-serif !important;
    color: #7b7a7a;
}

</style>
