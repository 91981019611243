<template>
  <div class="loading_page-wrap">
    <div class="loading-section-hld">
      <img src="../assets/Images/aipostgenerator/upload_a_doc.png" />
      <v-progress-linear
        rounded
        color="#1eb375"
        height="10"
        value="32"
        class="mt-5"
      ></v-progress-linear>
      <h3 class="slider-value">32</h3>
      <h3>Making the dough...</h3>
    </div>
  </div>
</template>
<script>
//var vm;
//import { bus } from "@/main";
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    // vm = this;
  },
  mounted() {
    setTimeout(() => {
      this.$router.push("/summary");
    }, 2000);
  },
  methods: {},
  watch: {},
};
</script>